// typeColors.ts

export const typeColors: { [key: number]: string[] } = {
    7: ["#26de81", "Insecte"], // Insecte
    16: ["#ffeaa7", "Dragon"], // Dragon
    13: ["#fed330", "Electrik"], // Electrik
    18: ["#FF0069", "Fée"], // Fée
    2: ["#30336b", "Combat"], // Combat
    10: ["#f0932b", "Feu"], // Feu
    3: ["#81ecec", "Vol"], // Vol
    12: ["#00b894", "Plante"], // Plante
    5: ["#EFB549", "Sol"], // Sol
    8: ["#a55eea", "Spectre"], // Spectre
    15: ["#74b9ff", "Glace"], // Glace
    1: ["#95afc0", "Normal"], // Normal
    4: ["#6c5ce7", "Poison"], // Poison
    14: ["#a29bfe", "Psy"], // Psy
    6: ["#2d3436", "Roche"], // Roche
    11: ["#0190FF", "Eau"], // Eau
    17: ["#2d3436", "Ténèbres"], // Ténèbres
    9: ["#2d3436", "Acier"], // Acier
    10002: ["#2d3436", "Obscur"], // Obscur
    10001: ["#2d3436", "???"], // ???
};
