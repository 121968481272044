import {
    CloseOutlined,
    DatabaseFilled,
    UnlockFilled
} from '@ant-design/icons';
import { Badge, Modal, Tag, message, notification } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import pokeballImage from '../../assets/images/icnBalls/poke-ball.png';
import { GatchaService } from '../../services/GatchaService';
import { TaskService } from "../../services/TaskService";
import { UserPokemonService, calculTransmuteADN, getStats } from "../../services/UserPokemonService";
import { actionCreators } from "../../state";
import { DNAIcon, FemaleIcon, HeartIcon, MaleIcon, ShinyIcon } from "../SVGIcons";
import CircleButton from "./CircleButton";
import FlatModalButton from "./FlatModalButton";
import './modal.css';
import { BreedingService } from '../../services/BreedingService';
import StarPiece from '../../assets/images/icnRanks/starpiece.png';

interface SubMenuBreedingProps {
    userPokemonWithStats: any;
    updateUser: (userProfile: any) => void;
    userProfile: any;
    honorBallImg: string;
    button: any
}

let lastPokemonGacha: any = null
let lastPokemonRarityGacha: any = null
let lastPokemonColorRarityGacha: any = null

const SubMenuBreeding: React.FC<SubMenuBreedingProps> = ({
    updateUser,
    userProfile,
    honorBallImg,
    button
}) => {

    const dispatch = useDispatch()
    const { setPokemonPrincipal } = bindActionCreators(actionCreators, dispatch);

    const taskService = new TaskService();
    const gatchaService = new GatchaService();
    const userPokemonService = new UserPokemonService();
    const breedingService = new BreedingService();

    const [openModal, setOpenModal] = useState(false);
    const [selectedBall, setSelectedBall] = useState({ itemId: 0, type: '', name: '', number: 0 });
    const [loading, setLoading] = useState(false);
    const [gatchaOpened, setGatchaOpened] = useState(false);
    const [transmuteLoading, setTransmuteLoading] = useState(false);
    const [partenaireLoading, setPartenaireLoading] = useState(false);
    const [imgPkmn, setImgPkmn] = useState('');
    const [imgItem, setImgItem] = useState(pokeballImage);

    const messageTopMargin = '36px';
    const [api,] = notification.useNotification();

    const determineGender = (gender: number) => {
        switch (gender) {
            case 0:
                return <FemaleIcon style={{ color: '#fdfee5' }} />;
            case 1:
                return <MaleIcon style={{ color: '#fdfee5' }} />;
            default:
                return '';
        }
    }

    const getGatcha = (user: any, category: any, numberItem: any, itemId: any) => {
        // Find Honor Ball in userprofile inventory and get the _id of one of them
        const honorBallId = userProfile.inventory.find((item: any) => item.item_ID === 12)._id;
        breedingService.openHonorBall(honorBallId).then((response) => {
            getInfosFromCookie();
            if (!response.success) {
                handleCancel();
                api.open({
                    message: "Achat échoué",
                    description: (
                        <div style={{ display: 'inline-block' }}>
                            <p style={{ display: 'inline-block' }}>{response.message}</p>
                        </div>),
                    duration: 1.5,
                    style: {
                        width: 400,
                        marginLeft: 335 - 400,
                    }
                });
                return;
            }

            lastPokemonGacha = response.pokemon;
            lastPokemonRarityGacha = response.pokemon.rarity;

            // Update number of ball in selectedBall state
            setSelectedBall({ itemId, type: category, name: selectedBall.name, number: selectedBall.number - 1 });

            switch (lastPokemonRarityGacha) {
                case 'NORMAL':
                    lastPokemonColorRarityGacha = '#3498db'
                    break;
                case 'RARE':
                    lastPokemonColorRarityGacha = '#f1c40f'
                    break;
                case 'EPIQUE':
                    lastPokemonColorRarityGacha = '#9b59b6'
                    break;
                case 'LEGENDAIRE':
                    lastPokemonColorRarityGacha = '#e67e22'
                    break;
                case 'DIVIN':
                    lastPokemonColorRarityGacha = '#e74c3c'
                    break;
                default:
                    lastPokemonColorRarityGacha = '#3498db'
                    break;
            }

            const buffer = Buffer.from(lastPokemonGacha.img_front.data);
            const base64String = buffer.toString('base64');
            setImgPkmn(`data:image/png;base64,${base64String}`)
            setGatchaOpened(true);
        });
    }

    const handleBallClick = (itemId: number, type: string, name: string, number: number) => {
        // Assuming we need to show the modal before the actual Gatcha service call
        setSelectedBall({ itemId, type, name, number });
        setOpenModal(true);
    };

    const handleOpen = () => {
        setLoading(true);
        setTimeout(() => {
            getGatcha(userProfile, selectedBall.type, 1, selectedBall.itemId);
            setOpenModal(true);
        }, 1000)
    }
    const handleOk = () => {
        setLoading(true);
        setPartenaireLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOpenModal(false);
            setGatchaOpened(false);
            setPartenaireLoading(false);
        }, 1000)
    }

    const handleCase = () => {
        if (gatchaOpened) {
            handleOk();
            setGatchaToPrincipal();
        } else {
            handleOpen();
        }
    }

    const handleCancel = () => {
        setOpenModal(false)
        setGatchaOpened(false);
        setLoading(false);
    }

    const handleTransmutation = () => {
        setLoading(true);
        setTransmuteLoading(true);
        transmutePokemon();
        setTimeout(() => {
            getInfosFromCookie();
            setLoading(false);
            setOpenModal(false);
            setGatchaOpened(false);
            setTransmuteLoading(false);
        }, 1000);
    };

    const transmutePokemon = () => {
        userPokemonService.transmutePokemon().then((response) => {
            if (!response.success) {
                return;
            }

            const content = (
                <div style={{ textAlign: 'center' }}>
                    {response.message} <b style={{ marginRight: '5px' }}>+{response.adnAmount}</b>
                    <DNAIcon />
                </div>
            );

            message.open({
                content: content,
                duration: 2,
                key: 1, // Utilisez la clé unique
                style: {
                    marginTop: messageTopMargin, // Ajustez la position verticale
                },
            });
        });
    }

    const setGatchaToPrincipal = () => {
        gatchaService.setGatchaPrincipal(userProfile).then((response) => {
            if (!response.success) {
                return;
            }
            const userPokemonWithStats = getStats(lastPokemonGacha, userProfile);
            setPokemonPrincipal(userPokemonWithStats);
        });
    }

    const getInfosFromCookie = () => {
        taskService.getDiscordUserFromCookie().then(response => {
            updateUser(response);
        });
    }

    // Count the number of item with item_ID = 12 (Honor Ball) in userProfile.inventory
    const numberHonorBall = userProfile.inventory.filter((item: any) => item.item_ID === 12).length;

    const hyperballBalise = <img src={honorBallImg} alt='honorball' style={{ width: '50px' }} />;

    // Style pour le conteneur des CircleButtons
    const circleButtonContainerStyle: React.CSSProperties = {
        position: 'relative',
        margin: '10px',
        display: 'inline-block', // Pour que le Badge se positionne correctement par rapport au CircleButton
    };

    const cardContainerStyle: React.CSSProperties = {
        position: 'relative', // Position relative pour le conteneur global
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Roboto, sans-serif',
        background: '#3c5c8a',
        borderRadius: '20px 20px 20px 20px',
        borderColor: '#black',
        borderStyle: 'solid',
        borderWidth: '2px',
        margin: '5px 10px 10px 10px',
        width: '100%',
        zIndex: 2, // Z-index supérieur pour le conteneur
    };

    const badgeStyle: React.CSSProperties = {
        backgroundColor: '#fffee5',
        color: 'black',
        fontWeight: 'bold',
        fontSize: '0.9rem',
        boxShadow: '0 0 0 2px #3c5c8a', // Ajoute un effet de halo pour le badge qui correspond à la couleur du fond
        // Ces valeurs peuvent être ajustées pour positionner le badge comme vous le souhaitez
        transform: 'translate(30%, -30%)', // Ajuste la position du badge par rapport au coin supérieur droit
    };

    const StarImage = () => (
        <img
            src={StarPiece}
            alt="star"
            style={{ width: '24px', height: '24px' }}
        />
    );

    const Stars = (count: number) => {
        // If count === 0, return star grayed out
        if (count === 0) {
            return <><img
                src={StarPiece}
                alt="star"
                style={{ width: '24px', height: '24px', filter: 'grayscale(100%)' }}
            /></>;
        } else {
            return <><StarImage key={count} /> <b>x{count}</b></>;
        }
    };

    return (
        <>
            <div style={cardContainerStyle}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    padding: '0 5px',
                }}>
                    {/* Ball à gauche */}
                    <div style={circleButtonContainerStyle}>
                        <Badge count={numberHonorBall} overflowCount={999} style={badgeStyle}>
                            <CircleButton
                                icon={hyperballBalise}
                                color="#4c75b0"
                                iconColor='#fffde9'
                                borderColor='#5b8dd4'
                                onClick={() => {
                                    if (numberHonorBall > 0) {
                                        handleBallClick(12, 'HONORBALL', 'Honor Ball', numberHonorBall);
                                        setImgItem(honorBallImg);
                                    }
                                }
                                }
                            />
                        </Badge>
                    </div>

                    {/* Espacement pour centrer le bouton */}
                    <div />

                    {/* Bouton au centre */}
                    <div style={{ ...circleButtonContainerStyle, flex: 1, justifyContent: 'center' }}>
                        {button}
                    </div>

                    {/* Espacement pour équilibrer la disposition */}
                    <div style={{ visibility: 'hidden' }}>
                        <Badge count={numberHonorBall} overflowCount={999} style={badgeStyle}>
                            <CircleButton
                                icon={hyperballBalise}
                                style={{ visibility: 'hidden' }} // Rendre invisible pour l'équilibre mais occuper l'espace
                                onClick={() => { }}
                            />
                        </Badge>
                    </div>
                </div>

            </div>
            <Modal
                title={`Ouvrir ${selectedBall.name}`}
                open={openModal}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <FlatModalButton
                        key="back"
                        label={gatchaOpened ? 'Stocker' : 'Annuler'}
                        icon={gatchaOpened ? <DatabaseFilled /> : <CloseOutlined />}
                        onClick={handleCancel}
                        loading={loading && (!gatchaOpened)}
                        disabled={transmuteLoading || partenaireLoading}
                        iconPosition="left"
                        hideLabelOnMobile={gatchaOpened ? true : false}
                        style={{ marginBottom: '10px' }}
                    />,
                    gatchaOpened && (
                        <FlatModalButton
                            key="transmute"
                            label={`+${calculTransmuteADN(lastPokemonGacha)}`}
                            icon={<DNAIcon />}
                            onClick={handleTransmutation}
                            loading={loading && transmuteLoading}
                            disabled={partenaireLoading}
                            color={'#fd9779'}
                            iconPosition="left"
                            style={{ marginBottom: '10px' }}
                        />
                    ),
                    <FlatModalButton
                        key="submit"
                        label={gatchaOpened ? 'Partenaire' : 'Ouvrir'}
                        icon={gatchaOpened ? <HeartIcon /> : <UnlockFilled />}
                        onClick={handleCase}
                        loading={loading && (partenaireLoading || !gatchaOpened)}
                        disabled={transmuteLoading}
                        iconPosition="left"
                        hideLabelOnMobile={gatchaOpened ? true : false}
                        style={{ marginBottom: '10px' }}
                    />
                ]}
            >
                <div className="lootbox-card">
                    <p className="lootbox-content">
                        {gatchaOpened && <img src={imgPkmn} style={{ width: '10rem' }} alt="new Pokemon" />}
                        {!gatchaOpened && !loading && <img src={imgItem} style={{ width: '5rem' }} alt="object to use" />}
                        {!gatchaOpened && loading && <img className="ball-opening" src={imgItem} style={{ width: '5rem' }} alt="object to use" />}
                        {gatchaOpened && <>
                            <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                                <p style={{ textAlign: "center", fontWeight: 700 }}>
                                    {lastPokemonGacha.is_shiny && <ShinyIcon style={{ color: '#fde500', paddingRight: '0.5em' }} />}{lastPokemonGacha.pokemon_name} {determineGender(lastPokemonGacha.gender)}
                                </p>
                                <Tag style={{ maxHeight: '22px' }} color={lastPokemonColorRarityGacha}>
                                    {lastPokemonRarityGacha}
                                </Tag>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom:'5px' }}>
                                {Stars(lastPokemonGacha.rank)}
                            </div>
                        </>}
                    </p>
                </div>
            </Modal>
        </>
    );
};

export default SubMenuBreeding;
