import { QuestionCircleOutlined } from '@ant-design/icons';
import { Card, Col, Popconfirm, Row } from 'antd';
import React from 'react';
import { typeColors } from '../../utils/typeColors';
import TypeIcon from './../TypeIcon';
import { ShoeIcon, SwordSimpleIcon, ThunderIcon } from '../SVGIcons';
import './PokemonMoveCard.css';

// PokemonMoveCard Props
interface PokemonMoveCardProps {
  move: any;
  user: any;
  checkNumberEcaille: any;
  changeUserPokemonMove: any;
  numberMove: number;
}

const PokemonMoveCard: React.FC<PokemonMoveCardProps> = ({ move, user, checkNumberEcaille, changeUserPokemonMove, numberMove }) => {

  const heartScaleStyle: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    right: 0,
    visibility: !checkNumberEcaille(user).enougthEcaille || numberMove < 4 ? `hidden` : `visible`,
  };



  return (
    <Col span={12} className="container-spell">
      <Popconfirm
        disabled={!checkNumberEcaille(user).enougthEcaille || numberMove < 4}
        title="Utiliser une Écaille Cœur ?"
        icon={<img src={require('../../assets/images/heart-scale.png')} alt='ecaillecoeur' />}
        onConfirm={() => changeUserPokemonMove(move.move_ID)}
        okText="Oui"
        cancelText="Non"
      >
        <Card size="small" bordered={false} hoverable={true} className="spell-card">
          <div className="spell-name">
            <TypeIcon type={typeColors[move.type][1]} size={14} />
            <span className="move-name">{move.move_name}</span>
            <img
              alt="heart-scale"
              src={require('../../assets/images/heart-scale.png')}
              style={heartScaleStyle}
            />
          </div>
          <Row>
            <Col span={12} className="spell-stat">
              { move.damage_class === 'physical' ? <SwordSimpleIcon /> : <ThunderIcon /> }
              <span>{move.power}</span>
            </Col>
            <Col span={12} className="spell-stat">
              <ShoeIcon />
              <span>{move.poids}</span>
            </Col>
          </Row>
        </Card>
      </Popconfirm>
    </Col>
  );
};

export default PokemonMoveCard;
