import { Badge, Col, Divider, Modal, Row, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import StarPiece from '../assets/images/icnRanks/starpiece.png';
import UserPokemon from '../interface/UserPokemon';
import { UserPokemonService, getStats } from '../services/UserPokemonService';
import { actionCreators } from '../state';
import StatsPanel from './StatsPanel';
import FlatModalButton from './newUI/FlatModalButton';

type PokemonRankUpPopupProps = {
  userProfile: any;
  selectedPokemon: UserPokemon;
  onClose: () => void;
  onRankUp: (pokemon: UserPokemon) => void;
};

const PokemonRankUpPopup: React.FC<PokemonRankUpPopupProps> = ({
  selectedPokemon,
  onClose,
  onRankUp,
  userProfile,
}) => {
  const [sacrificedDuplicates, setSacrificedDuplicates] = useState(0);
  const requiredDuplicates = selectedPokemon.rank + 1;
  const isRequirementMet = sacrificedDuplicates >= requiredDuplicates;

  const userPokemonService = new UserPokemonService();
  const dispatch = useDispatch()
  const { setPokemonPrincipal } = bindActionCreators(actionCreators, dispatch);

  const handleSacrifice = () => {
    if (isRequirementMet) {
      rankUpPokemon();
      onRankUp(selectedPokemon);
    }
    onClose();
  };

  const buffer = Buffer.from(selectedPokemon.img_front.data);
  const base64String = buffer.toString('base64');

  function Proper(text: String) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  const StarImage = () => (
    <img
      src={StarPiece}
      alt="star"
      style={{ width: '24px', height: '24px', marginLeft: '7px', marginRight: '-12px' }}
    />
  );

  const upgradedUserPokemon = getStats({ ...selectedPokemon, rank: selectedPokemon.rank + 1 }, userProfile)

  const renderRarityTag = () => {
    let color = '#3498db';
    switch (selectedPokemon.rarity) {
      case 'NORMAL':
        color = '#3498db'
        break;
      case 'RARE':
        color = '#f1c40f'
        break;
      case 'EPIQUE':
        color = '#9b59b6'
        break;
      case 'LEGENDAIRE':
        color = '#e67e22'
        break;
      case 'DIVIN':
        color = '#e74c3c'
        break;
      default:
        color = '#262626'
        break;
    }
    return (
      <Tag color={color} style={{ display: 'table', alignItems: 'center', margin: 'auto auto 5px auto' }}>
        {selectedPokemon.rarity ? Proper(selectedPokemon.rarity) : `Inconnu`}
      </Tag>
    )
  }

  const getDoublonPokemon = () => {
    userPokemonService.getDoublonPokemon().then((doublonObject) => {
      if (doublonObject.success) {
        const doublonsPokemon = doublonObject.doublonPokemon;
        setSacrificedDuplicates(doublonsPokemon.length);
      }
    })
  }

  const rankUpPokemon = () => {
    userPokemonService.rankUpPokemon().then((rankUpObject: any) => {
      if (rankUpObject.success) {
        const upgradedPokemon = rankUpObject.pokemon;
        const pokemon = getStats(upgradedPokemon, userProfile);
        setPokemonPrincipal(pokemon);
      }
    })
  }

  useEffect(() => {
    getDoublonPokemon();
  }, [selectedPokemon]);

  const Stars = (count: number) => {
    const stars = [];
    for (let i = 0; i < count; i++) {
      stars.push(<StarImage key={i} />);
    }

    return <>{stars}</>;
  };

  return (
    <Modal
      title={
        <div>
          Augmenter vers
          {Stars(selectedPokemon.rank + 1)}
        </div>
      }
      open={true}
      onOk={handleSacrifice}
      onCancel={onClose}
      okText="Sacrifier"
      cancelText="Annuler"
      okButtonProps={{ disabled: !isRequirementMet }}
      footer={[<FlatModalButton
        key="back"
        label={"Annuler"}
        onClick={onClose}
    />, <FlatModalButton
        key="submit"
        label={"Sacrifier"}
        onClick={handleSacrifice}
        disabled={!isRequirementMet}
      />]}
    >
      <div className="ant-row .ant-form-item.aligin-center fill">
        <span style={{ fontSize: '14px', textAlign: 'left' }}>Vous pouvez sacrifier les doublons de ce Pokémon pour augmenter son rang.</span>
        <div style={{ textAlign: 'center', paddingTop: '15px' }}>
          <Row>
            <Col span={8}>
              <Badge count={sacrificedDuplicates + ' / ' + requiredDuplicates} showZero overflowCount={99} color={sacrificedDuplicates < requiredDuplicates ? '#ff4d4f' : '#52c41a'}>
                <div style={{ padding:'10px', border: '#f8f8f8', borderStyle: 'solid', borderRadius: '10%', borderWidth: '1px', boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.05)' }}>
                  <img
                    alt="myPokemon"
                    src={
                      selectedPokemon._id
                        ? `data:images/png;base64,${base64String}`
                        : 'https://github.com/PokeAPI/sprites/blob/master/sprites/pokemon/202.png?raw=true'
                    }
                    style={
                      selectedPokemon.life === 0 ? { filter: 'grayscale(100%)' } : {}
                    }
                  />
                  <div style={{ padding: '0 20px' }}>
                    <Divider style={{ margin: '0 0 0.4em 0' }} />
                  </div>
                  {renderRarityTag()}
                </div>
              </Badge>
            </Col>
            <Col span={16}>
              <StatsPanel userPokemonWithStats={selectedPokemon} userPokemonWithStatsUpgrade={upgradedUserPokemon} />
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};

export default PokemonRankUpPopup;
