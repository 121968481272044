import { Badge, Card, Input, Modal, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import "../../assets/styles/evolve.css";
import '../../assets/styles/pokedex.css';
import UserPokemon from '../../interface/UserPokemon';
import { EvolveService } from '../../services/EvolveService';
import { State, actionCreators } from '../../state';
import CircleButton from './CircleButton';
import FlatModalButton from './FlatModalButton';
import { set } from 'mongoose';
import { getStats } from '../../services/UserPokemonService';

type EvolvePopupProps = {
    updateUser: (userProfile: any) => void;
    userProfile: any;
    style?: React.CSSProperties;
}

const EvolveButton: React.FC<EvolvePopupProps> = ({ updateUser, userProfile, style }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedEvolveItem, setSelectedEvolveItem] = useState<any | null>(null);
    const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
    const [searchText, setSearchText] = useState('');
    const [inItemSelection, setInItemSelection] = useState(false);
    const [inEvolutionPreview, setInEvolutionPreview] = useState(false);
    const [evolving, setEvolving] = useState(false);
    const [evolutionTransition, setEvolutionTransition] = useState(false);
    const [evolutionSuccess, setEvolutionSuccess] = useState(false);
    const [noEvolution, setNoEvolution] = useState(false);

    const [evolutionObject, setEvolutionObject] = useState<any>(null);
    const [finalEvolution, setFinalEvolution] = useState<any>(null);

    const [pictureEvol, setPictureEvol] = useState<any>(null);

    const evolveService = new EvolveService();

    const dispatch = useDispatch()

    const { setPokemonPrincipal } = bindActionCreators(actionCreators, dispatch);
    const userPokemonWithStats: UserPokemon = useSelector((state: State) => {
        return state.userPokemon
    })

    const handleCardClick = (itemId: number) => {
        setSelectedItemId(itemId);
        setSelectedEvolveItem(userProfile.inventory.find((item: any) => item.item_ID === itemId))
    };

    const handleOk = async () => {
        if (selectedEvolveItem) {
            getEvolveData(userPokemonWithStats, selectedEvolveItem);
        }
    };

    const getAllEvolves = (userPokemon: UserPokemon) => {
        evolveService.getEvolveData(userPokemon.pokemon_ID).then((evolves) => {
            if (evolves.success) {
                const pictureEvolLocal = createPokemonImageSrc(evolves.evolveData.pokemonEvolve[0]);
                setPictureEvol(pictureEvolLocal);
            }
        })
    }

    const getEvolveData = (userPokemon: UserPokemon, selectedItem: any) => {
        evolveService.getEvolveDataByItemId(userPokemon.pokemon_ID, selectedItem.item_ID).then((evolve) => {
            if (evolve.success) {
                setInItemSelection(false);
                setEvolutionObject(evolve);
                setInEvolutionPreview(true);
            } else {
                setNoEvolution(true);
                setInItemSelection(false);
            }
        }
        )
    }

    useEffect(() => {
        getAllEvolves(userPokemonWithStats);
    }, [userPokemonWithStats])

    const handleSetEvolve = async () => {
        if (selectedEvolveItem) {
            setEvolve(userProfile, selectedEvolveItem);
        }
    }

    const setEvolve = (user: any, selectedItem: any) => {
        evolveService.evolvePokemon(user, selectedItem.item_ID).then((evolve) => {
            if (evolve.success) {
                setInItemSelection(false);
                setInEvolutionPreview(true);
                setFinalEvolution(evolve.pokemon);
                updateUser(evolve.user);
            }
        }
        )
    }

    const handleOpenPopup = () => {
        setInItemSelection(true);
        setModalVisible(true);
    };

    const handleCancel = () => {
        if (inEvolutionPreview && !evolutionSuccess) {
            setInEvolutionPreview(false);
            setInItemSelection(true);
        } else {
            if (evolutionSuccess) {
                const pokemon = getStats(finalEvolution, userProfile);
                setPokemonPrincipal(pokemon);
            }
            setModalVisible(false);
            setSelectedEvolveItem(false);
            setSelectedItemId(null);
            setSearchText("");
            setInItemSelection(false);
            setInEvolutionPreview(false);
            setEvolutionObject(null);
            setEvolutionSuccess(false);
            setEvolutionTransition(false);
            setNoEvolution(false);
        }
    };

    const handleEvolve = () => {
        setEvolutionTransition(true);
        setEvolving(true);
        let countDown = 1;
        const interval = setInterval(() => {
            setEvolving((prevState) => !prevState);
            countDown -= 0.1;
            if (countDown <= 0) {
                clearInterval(interval);
                setEvolving(false);
                handleSetEvolve();
                setEvolutionTransition(false);
                setEvolutionSuccess(true);
            }
        }, 200 * countDown);
    };

    const createImageSrc = (item: any) => {
        if (!item.img.data) return
        const buffer = Buffer.from(item.img.data)
        const base64String = buffer.toString('base64')
        const src = `data:images/png;base64,${base64String}`
        return src
    }

    const createPokemonImageSrc = (pokemon: any) => {
        if (!pokemon.img_front_default.data) return
        const buffer = Buffer.from(pokemon.img_front_default.data.data);
        const base64String = buffer.toString('base64');
        const src = `data:images/png;base64,${base64String}`;
        return src;
    }

    const createUserPokemonImageSrc = (pokemon: any) => {
        if (!pokemon.img_front.data) return
        const buffer = Buffer.from(pokemon.img_front.data.data);
        const base64String = buffer.toString('base64');
        const src = `data:images/png;base64,${base64String}`;
        return src;
    }

    const filteredInventory = userProfile.inventory
        .filter((item: any) => {
            return item.const === 'evolution' && item.quantity > 0 && item.target === 'principal' && item.item_name.toLowerCase().includes(searchText.toLowerCase())
        })

    return (
        <>
            <CircleButton icon={pictureEvol && <img src={pictureEvol} className="evol-moi-animation" style={{ height: 'auto', maxWidth: '40px', cursor: 'pointer' }} alt="buttonevol" onClick={handleOpenPopup} />} color={'#02133b'} onClick={handleOpenPopup} style={style} />
            <Modal
                title={inItemSelection ? "Choisissez votre objet d'évolution" : inEvolutionPreview && !evolutionTransition && !evolutionSuccess ? "Aperçu de l'évolution" : evolutionTransition ? "En cours d'évolution" : evolutionSuccess ? "Evolution réussie" : ""}
                open={modalVisible}
                onOk={inEvolutionPreview && !evolutionTransition && !evolutionSuccess ? handleEvolve : evolutionSuccess ? handleCancel : noEvolution ? handleCancel : handleOk}
                onCancel={() => {
                    setModalVisible(false);
                    handleCancel()
                }}
                okText={inItemSelection ? "Voir évolution" : inEvolutionPreview && !evolutionTransition && !evolutionSuccess ? "Utiliser" : noEvolution ? "Super..." : "Wahou !"}
                cancelText={inItemSelection ? "Annuler" : inEvolutionPreview && !evolutionTransition && !evolutionSuccess ? "Retour" : "M'enfou"}
                footer={evolutionTransition ? null : [
                    <FlatModalButton
                        key="back"
                        label={inItemSelection ? "Annuler" : inEvolutionPreview && !evolutionTransition && !evolutionSuccess ? "Retour" : "M'enfou"}
                        onClick={handleCancel}
                    />,
                    <FlatModalButton
                        key="go"
                        disabled={!selectedItemId}
                        label={inItemSelection ? "Voir évolution" : inEvolutionPreview && !evolutionTransition && !evolutionSuccess ? "Utiliser" : noEvolution ? "Super..." : "Wahou !"}
                        onClick={inEvolutionPreview && !evolutionTransition && !evolutionSuccess ? handleEvolve : evolutionSuccess ? handleCancel : noEvolution ? handleCancel : handleOk}
                    />]}
                okButtonProps={{ disabled: !selectedItemId }}
            >
                {inItemSelection && (<>
                    <Input.Search
                        placeholder="Nom de l'objet"
                        onChange={(e) => setSearchText(e.target.value)}
                        style={{ marginBottom: '15px' }}
                        size='large'
                    />

                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px', textAlign: 'center', justifyContent: 'center', maxHeight: '35vh', overflowY: 'auto' }}>

                        {filteredInventory.map((item: any) => (
                            <div
                                key={item.item_name}
                                style={{
                                    marginTop: '10px',
                                    border: selectedItemId === item.item_ID ? '3px solid black' : '2px solid transparent',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleCardClick(item.item_ID)}
                            >
                                <Tooltip placement="top" title={item.description}>
                                    <Badge count={item.quantity} style={{ backgroundColor: '#02133b' }} overflowCount={99}>
                                        <Card style={{ width: 120, backgroundColor: '#e8eddc', borderRadius: '3px' }} size='small'>
                                            <div style={{ textAlign: 'center', fontWeight: '600' }}>{item.item_name}</div>
                                            <img src={createImageSrc(item)} alt={item.item_name} style={{ width: 'auto', height: '30px', objectFit: 'cover', margin: 'auto', display: 'block' }} />
                                        </Card>
                                    </Badge>
                                </Tooltip>
                            </div>
                        ))
                        }
                    </div> </>)}
                {inEvolutionPreview && !evolutionTransition &&
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px', textAlign: 'center', justifyContent: 'center', maxHeight: '35vh', overflowY: 'auto' }}>
                        <div className="lootbox-card">
                            <p className="lootbox-content">
                                <img src={createPokemonImageSrc(evolutionObject.pokemonEvolve)} className='none-img' style={{ width: '10rem' }} alt="preview Pokemon" />
                            </p>
                        </div>
                    </div>
                }
                {inEvolutionPreview && evolutionTransition &&
                    <div className={`lootbox-card ${evolving ? 'evolving' : ''}`}>
                        <p className="lootbox-content">
                            <img
                                src={evolving ? createUserPokemonImageSrc(userPokemonWithStats) : createPokemonImageSrc(evolutionObject.pokemonEvolve)}
                                style={{ width: '10rem' }}
                                className={evolving ? '' : 'none-img'}
                                alt="evolution Pokemon"
                            />
                        </p>
                    </div>

                }
                {inEvolutionPreview && evolutionSuccess &&
                    <div className={`lootbox-card`}>
                        <p className="lootbox-content">
                            <img
                                src={createPokemonImageSrc(evolutionObject.pokemonEvolve)}
                                style={{ width: '10rem' }}
                                alt="new Pokemon"
                            />
                        </p>
                    </div>

                }
                {noEvolution && <div style={{ textAlign: 'center', marginTop: '10px' }}>Aucune évolution possible</div>}
            </Modal>
        </>
    );

};

export default EvolveButton;
