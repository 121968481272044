import { ArrowRightOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { HeartIcon, ShieldIcon, ShieldMagicalIcon, ShoeIcon, SwordSimpleIcon, ThunderIcon } from './SVGIcons';
import DiamondButton from './newUI/DiamondButton';

type StatsPanelProps = {
  userPokemonWithStats: any;
  userPokemonWithStatsUpgrade?: any;
};

const StatsPanel: React.FC<StatsPanelProps> = ({ userPokemonWithStats, userPokemonWithStatsUpgrade }) => {
  const statsConfig = [
    { name: 'HP', icon: <HeartIcon />, key: 'hp', color: 'darkred' },
    { name: 'Attack', icon: <SwordSimpleIcon />, key: 'attack', color: '#ffbe5c' },
    { name: 'Defense', icon: <ShieldIcon />, key: 'defense', color: '#fe907a' },
    { name: 'Special Attack', icon: <ThunderIcon />, key: 'specialAttack', color: 'purple' },
    { name: 'Special Defense', icon: <ShieldMagicalIcon />, key: 'specialDefense', color: '#b16455' },
    { name: 'Speed', icon: <ShoeIcon />, key: 'speed', color: '#5c8df3' }
  ];

  const renderStat = (stat: any) => {
    const oldValue = userPokemonWithStats.stats[stat.key];
    const newValue = userPokemonWithStatsUpgrade ? userPokemonWithStatsUpgrade.stats[stat.key] : null;

    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }} key={stat.key}>
        <Tooltip placement="top" title={stat.name}>
          <DiamondButton
            icon={stat.icon}
            color={stat.color} // Change to desired color
            iconColor='#fffbd3' // Change to desired icon color
            onClick={() => {}}
          />
        </Tooltip>
        <div style={{ display: 'flex', alignItems: 'center', marginTop:'5px' }}>
          <span style={{ color: '#fffbd3', fontWeight: 'bold' }}>{oldValue}</span>
          {newValue && (
            <>
              <ArrowRightOutlined style={{ margin: '0 5px' }} />
              <span style={{ color: '#7DCE82', fontWeight: 'bold' }}>{newValue}</span>
            </>
          )}
        </div>
      </div>
    );
  };

  const column1Stats = statsConfig.slice(0, 3);
  const column2Stats = statsConfig.slice(3);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div>
        {column1Stats.map(stat => renderStat(stat))}
      </div>
      <div>
        {column2Stats.map(stat => renderStat(stat))}
      </div>
    </div>
  );
};

export default StatsPanel;
