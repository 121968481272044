import { Action } from '../actions/index';
import { ActionType } from '../action-types';

const initialState: any = {
    _id: '',
    pokemon1: '',
    pokemon2: '',
    pokemon1Id: '',
    pokemon2Id: '',
    userId: '',
    breedingStart: '',
    breedingEnd: '',
    status: '',
    offspringId: '',
    ballId: ''
}

const breedingReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case ActionType.SET_BREEDING:
            return action.payload;
        case ActionType.RESET_BREEDING:
            return initialState;
        default:
            return state;
    }
};

export default breedingReducer;
