import React, { useState } from 'react';
import StarPiece from '../assets/images/icnRanks/starpiece.png';
import './../assets/styles/pokemonRank.css';
import PokemonRankUpPopup from './PokemonRankUpPopup'; // Importer le composant PokemonRankUpPopup

type PokemonRankProps = {
    userPokemonWithStats: any;
    userProfile: any;
    style?: React.CSSProperties;
};

const PokemonRank: React.FC<PokemonRankProps> = ({
    userPokemonWithStats,
    userProfile,
    style
}) => {

    const starPieceImage = [
        { img: StarPiece, title: '1 Étoile' },
        { img: StarPiece, title: '2 Étoiles' },
        { img: StarPiece, title: '3 Étoiles' },
        { img: StarPiece, title: '4 Étoiles' },
        { img: StarPiece, title: '5 Étoiles' },
        { img: StarPiece, title: '6 Étoiles' },
        { img: StarPiece, title: '7 Étoiles' }
    ];

    const [isPopupVisible, setPopupVisible] = useState(false);

    const handleRankUpClick = () => {
        // Ouvrez la popup PokemonRankUpPopup en définissant isPopupVisible à true
        setPopupVisible(true);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row', marginRight: '1em', ...style }}>
            {Array.from({ length: 7 }, (_, index) => (
                <div key={index} style={{ display: 'flex' }}>
                    {index < userPokemonWithStats.rank ? (
                        <img
                            src={starPieceImage[index].img}
                            alt={`Rank ${starPieceImage[index].title}`}
                            style={{ width: '30px', height: '30px', cursor: 'pointer' }}
                            onClick={() => {
                                handleRankUpClick(); // Ouvrir la popup lorsque le rang suivant est cliqué
                            }}
                        />
                    ) : (
                        index === userPokemonWithStats.rank ?
                            <img
                                src={starPieceImage[index].img}
                                alt={`Rank ${starPieceImage[index].title}`}
                                style={{ width: '30px', height: '30px', filter: 'grayscale(100%)' }}
                                className={index === userPokemonWithStats.rank ? 'blink' : ''}
                                onClick={() => {
                                    handleRankUpClick(); // Ouvrir la popup lorsque le rang suivant est cliqué
                                }}
                            /> :
                            <img
                                src={starPieceImage[index].img}
                                alt={`Rank ${starPieceImage[index].title}`}
                                style={{ width: '30px', height: '30px', filter: 'grayscale(100%)', cursor: 'pointer' }}
                                onClick={() => {
                                    handleRankUpClick(); // Ouvrir la popup lorsque le rang suivant est cliqué
                                }}
                            />
                    )}
                </div>
            ))}

            {/* Afficher la popup PokemonRankUpPopup si isPopupVisible est vrai */}
            {isPopupVisible && (
                <PokemonRankUpPopup
                    selectedPokemon={userPokemonWithStats}
                    onClose={() => setPopupVisible(false)}
                    onRankUp={() => {
                        // Fermez la popup
                        setPopupVisible(false);
                    }}
                    userProfile={userProfile}
                />
            )}
        </div>
    );
};

export default PokemonRank;
