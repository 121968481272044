import { Button, Form, Input, Modal, Select, Table } from 'antd'
import type { ColumnsType, TableProps } from 'antd/es/table'
import React, { useEffect, useState } from 'react'
import { ShopItemService } from '../services/ShopItemService'

const ManageShop: React.FC = () => {
    const shopItemService = new ShopItemService();
    const [listItems, setListItems] = useState([]);
    const [listShopItems, setListShopItems] = useState([]);
    const [data, setData] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState({ item_ID: '', item_name: '', img: '', cost: 0, const: '', description: '', type: '', effect: '', effectTarget: '' });
    const [searchText, setSearchText] = useState('');

    const { Option } = Select;

    const showModal = (item: any) => {
        setSelectedItem(item);
        setModalVisible(true);
    };

    // Ajout de la déclaration de form
    const [form] = Form.useForm();

    const handleOk = async (values: any) => {
        if (selectedItem) {
            shopItemService.addShopItem(
                selectedItem,
                null,
                values.prix,
                "PERMANENT",
                values.buildingType,
                values.buildingLevel
            );
            const listShopItemsGet = await shopItemService.getAllShopItems();
            setListShopItems(listShopItemsGet);
            setModalVisible(false);
        }
    };

    const handleCancel = () => {
        setModalVisible(false);
    };

    interface DataType {
        key: React.Key;
        nom: string;
        prix: number;
        type: string;
        action: any;
        item: {
            item_ID: string; // Remplacez par le type correct
            item_name: string; // Remplacez par le type correct
            img: any; // Remplacez par le type correct
            // Ajoutez d'autres propriétés si nécessaire
        };
    }


    const createImageItem = (img: any, name: string) => {
        if (!img.data) return
        const buffer = Buffer.from(img.data)
        const base64String = buffer.toString('base64')
        const src = `data:images/png;base64,${base64String}`
        return <img src={src} alt={name} style={{ width: '25px', height: '25px', marginLeft: '0.8rem' }} />
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'Nom',
            dataIndex: 'nom',
            key: '',
            sorter: (a, b) => a.nom.localeCompare(b.nom),
            render: (text, record) => {
                return (<div>{text}{createImageItem(record.item.img, record.nom)}</div>)
            },
            width: '20%',
        },
        {
            title: '',
            dataIndex: 'item',
            key: 'item',
            width: '0px'
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: '15%',
            sorter: (a, b) => a.type.localeCompare(b.type),
        },
        {
            title: 'Prix',
            dataIndex: 'prix',
            key: 'prix',
            width: '10%'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '25%',
            render: (_text, record) => {
                return (
                    <div>
                        {listShopItems.find(
                            (item: any) => item.item.item_ID === record.item.item_ID
                        ) ? (
                            <Button
                                type="primary"
                                danger
                                onClick={async () => {
                                    // Find the item in the listShopItems based on record.item.item_ID
                                    const shopItem = listShopItems.find(
                                        (item: any) => item.item.item_ID === record.item.item_ID
                                    );
                                    shopItemService.removeShopItem(shopItem);
                                    const listShopItemsGet = await shopItemService.getAllShopItems();
                                    setListShopItems(listShopItemsGet);
                                }}
                            >
                                Retirer de la boutique
                            </Button>
                        ) : (
                            <Button type="primary" onClick={() => showModal(record.item)}>
                                Ajouter à la boutique
                            </Button>
                        )}
                    </div>
                );
            },
        },
    ]

    const onChange: TableProps<DataType>['onChange'] = (
        _pagination,
        _filters,
        _sorter,
        _extra,
    ) => {

    }

    const defineLists = async () => {
        const listItemsGet = await shopItemService.getAllItems()
        const listShopItemsGet = await shopItemService.getAllShopItems()
        setListItems(listItemsGet)
        setListShopItems(listShopItemsGet)
    }

    const defineData = () => {
        let copyData: any = [];
        listItems.forEach((item: any) => {
            copyData.push({
                key: item.item_ID,
                nom: item.item_name,
                type: item.const,
                item: item,
                prix: item.cost,
                action: undefined,
            });
        });

        // Filtrer les données en fonction de la valeur de recherche
        const filteredData = copyData.filter((item: any) =>
            item.nom.toLowerCase().includes(searchText.toLowerCase())
        );

        setData(filteredData);
    };

    useEffect(() => {
        defineLists();
        defineData();
    }, [searchText])

    return (
        <div>
            <p style={{ fontWeight: 'bold' }}>Gestion du marché noir</p>
            <button onClick={() => defineData()}>Refresh</button>
            <Input
                placeholder="Rechercher par nom"
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                style={{ marginBottom: '16px' }}
            />
            <Table
                columns={columns}
                dataSource={data}
                onChange={onChange}
                scroll={{ y: '100vh' }}
            />
            <Modal
                title="Ajouter à la boutique"
                open={modalVisible}
                onOk={() => form.submit()} // Correction: Utilisez Form au lieu de form ici
                onCancel={handleCancel}
            >
                <Form
                    form={form} // Correction: Utilisez form au lieu de Form ici
                    onFinish={handleOk}
                    initialValues={{ prix: selectedItem.cost, buildingType: 'dojo', buildingLevel: 0 }}
                >
                    <Form.Item label="Prix" name="prix">
                        <Input type="number" min={0} />
                    </Form.Item>
                    <Form.Item label="Building Type" name="buildingType">
                        <Select>
                            <Option value="dojo">Dojo</Option>
                            <Option value="shop">Boutique</Option>
                            <Option value="labo">Laboratoire</Option>
                            <Option value="experimental">Experimental</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Building Level" name="buildingLevel">
                        <Input type="number" min={0} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default ManageShop
