import { HeartFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Card, Modal, Rate } from 'antd';
import React, { useState } from 'react';
import RareCandy from '../../assets/images/rare-candy.png';
import FlatModalButton from './FlatModalButton';
import { useSelector } from 'react-redux';
import { State } from '../../state';
import BoostPanel from './BoostPanel';

type ShopItemProps = {
  shopItem: any;
  onBuy: (shopItem: any, quantity: any) => void;
  canBuy: boolean;
  onClick: () => void;
  userProfile: any;
  style?: React.CSSProperties;
};

const ShopItem: React.FC<ShopItemProps> = ({ shopItem, onBuy, canBuy, onClick, userProfile, style }) => {
  const [showDetail, setShowDetail] = useState(false);

  const userPokemonWithStats = useSelector((state: State) => state.userPokemon);
  const handleDetailClick = () => {
    setShowDetail(true);
  };

  const handleModalClose = () => {
    setShowDetail(false);
  };

  const createImageItem = (item: any) => {
    if (!item) return '';

    let src = '';
    const buffer = Buffer.from(item.item.img.data.data);
    if (typeof item.item.img.data.data === 'string') {
      src = `data:image/png;base64,${buffer}`;
    } else {
      const base64String = buffer.toString('base64');
      src = `data:image/png;base64,${base64String}`;
    }

    return src;
  };

  const showPrice = (item: number) => {
    const formattedPrice = new Intl.NumberFormat().format(item);
    return (
      <Button
        type="primary"
        style={{
          fontSize: '14px',
          fontWeight: 'bold',
          backgroundColor: canBuy ? '#fd9779' : '#dfdfdf', // Couleur de fond du bouton
          borderColor: 'black', // Couleur de la bordure du bouton
          width: 'auto', // Largeur fixe à 80% de la taille de la card
          marginLeft: 'auto', // Centrer
          marginRight: 'auto', // Centrer
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '15px',
          borderStyle: 'solid',
          borderWidth: '2px',
          borderBottomWidth: '4px'
        }}
        onClick={() => onBuy(shopItem, 1)} // Vous pouvez gérer l'achat ici si nécessaire
        disabled={!canBuy}
      >
        <span style={{ marginRight: '0.15em', marginLeft: '0.15em' }}>{formattedPrice}</span>
        <img src={RareCandy} style={{ height: '22px' }} alt="money" />
      </Button>
    );
  };

  return (
    <>
      <Card
        style={{
          width: 100,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center', // Aligner le contenu verticalement
          height: '100%', // Hauteur de la Card
          backgroundColor: '#f8f8f8',
          borderStyle: 'solid',
          borderColor: 'black',
          borderWidth: '2px',
          boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.03),0 1px 6px -1px rgba(0, 0, 0, 0.02),0 2px 4px 0 rgba(0, 0, 0, 0.02)',
          cursor: 'pointer',
          marginRight: 'auto',
          marginLeft: 'auto',
          ...style
        }}
        size="small"
        onClick={onClick}
      >
        <div style={{ textAlign: 'center', fontWeight: '600', color: 'black' }}>{shopItem.item.item_name}</div>
        <div style={{ textAlign: 'center', fontWeight: '600', color: 'black' }}>
          <InfoCircleOutlined onClick={handleDetailClick} style={shopItem.item.description ? { visibility: 'visible' } : { visibility: 'hidden' }} />
        </div>
        <img
          src={createImageItem(shopItem)}
          alt={shopItem.item.item_name}
          style={{ width: 'auto', height: '40px', objectFit: 'cover', margin: 'auto', display: 'block' }}
        />
        <div style={{ width: '100%', padding: '5px 0' }}>
          {showPrice(shopItem.displayPrice)}
        </div>
      </Card>
      <Modal
        title={shopItem.item.item_name}
        open={showDetail}
        onCancel={handleModalClose}
        footer={[
          <FlatModalButton key="close" onClick={handleModalClose} label={'Fermer'} />,
        ]}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={createImageItem(shopItem)}
            alt={shopItem.item.item_name}
            style={{ width: '40px', height: '40px', marginRight: '16px' }}
          />
          <div>
            <div>{shopItem.item.description}</div>
            {shopItem.item.effect && shopItem.item.effect[0] === 'life' ? (
              <div>
                <Rate
                  disabled
                  value={Math.abs(shopItem.item.effect[1])}
                  count={shopItem.item.effect[1] <= 3 ? 3 : shopItem.item.effect[1]}
                  character={<HeartFilled />}
                  style={{ color: shopItem.item.effect[1] > 0 ? `#C80000` : `#5f3d46`, marginLeft: '0.5em' }}
                />
              </div>
            ) : null}
            {shopItem.item.effect && shopItem.item.effect.length > 0 && (shopItem.item.effect[0].includes('ev')|| shopItem.item.effect[0].includes('iv')) ? (
              <div style={{display:'flex', paddingLeft:'5%', marginTop:'1%'}}>
                <BoostPanel item={shopItem.item} userPokemonWithStats={userPokemonWithStats} style={{color:'white'}} userProfile={userProfile}/>
              </div>
            ) : null}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShopItem;
