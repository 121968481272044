import React from 'react';

interface CircleButtonProps {
    icon: React.ReactNode;
    color?: string; // Couleur de fond du bouton
    onClick: () => void; // Fonction à exécuter lorsque le bouton est cliqué
    style?: React.CSSProperties; // Styles supplémentaires pour le bouton
    iconColor?: string; // Couleur de l'icône
    borderColor?: string; // Couleur de la bordure
}

const CircleButton: React.FC<CircleButtonProps> = ({ icon, color = '#ff977b', onClick, style, iconColor = 'white', borderColor = '#fffae6' }) => {

    return (
        <button
            onClick={onClick}
            style={{
                width: '45px',
                height: '45px',
                borderRadius: '50%',
                backgroundColor: color,
                color: iconColor,
                border: '4px solid ' + borderColor, // Bordure extérieure blanche
                outline: 'none',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: `0 0 0 2px black`, // Bordure noire
                position: 'relative',
                fontSize: '1rem',
                fontFamily: 'Roboto, sans-serif',
                ...style, // Applique les styles supplémentaires
            }}
        >
            {icon}
        </button>
    );
};

export default CircleButton;
