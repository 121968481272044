import { ReactChild, ReactFragment, ReactPortal } from "react";
import { Layout } from "antd";

const { Content } = Layout;
const Main: React.FC<{ props?: ReactChild | ReactFragment | ReactPortal | null | undefined }> = ({ props }) => {
  return (
    <div>
      {props != null && <Content className="content-ant">{props}</Content>}
    </div>
  );
}

export default Main;
