export enum ActionType {
    SET_USER = 'SET_USER',
    REMOVE_USER = 'REMOVE_USER',
    SET_POKEMON_PRINCIPAL = 'SET_POKEMON_PRINCIPAL',
    SET_BOSS_FIGHTS = 'SET_BOSS_FIGHTS',
    SET_BREEDING = 'SET_BREEDING',
    RESET_BREEDING = 'RESET_BREEDING',
    SET_BUILDING = 'SET_BUILDING',
    SET_POKEEGGS = 'SET_POKEEGGS',
    SET_POKEBALLS = 'SET_POKEBALLS',
    SET_SUPERBALLS = 'SET_SUPERBALLS',
    SET_HYPERBALLS = 'SET_HYPERBALLS',
}