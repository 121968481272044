import {
    Card,
    Layout,
    Typography,
  } from "antd";
  import React from "react";
  import logoprincipal from "../assets/images/PokeAscent.png";
  import { LinkedinFilled } from "@ant-design/icons";
const { Content, Footer } = Layout;
const { Title } = Typography;

const AccountNotActivated: React.FC = () => {
  
    return (
        <div className="layout-default ant-layout layout-sign-up set-bg">
            <Content className="p-0">
                <div className="sign-up-header">
                    <div className="">
                        <Title><img src={logoprincipal} alt="Logo PokeAscent" /></Title>
                    </div>
                </div>
                <Card className="card-signup header-solid h-full ant-card pt-0">
                    <h2 className="font-semibold text-center" style={{ paddingTop: "30px" }}>
                        Compte non activé
                    </h2>
                    <p className="font-semibold text-center" style={{ paddingTop: "30px", fontSize: 15 }}>
                        Votre compte n'est pas encore activé, veuillez patienter afin qu'un administrateur valide votre compte.
                    </p>
                </Card>
            </Content>
            <Footer className='footer-login'>
                <span className="copyright">
                    {" "}
                    Copyright © 2023 Chaloon - <a style={{ textDecoration: 'none' }} href="https://ch.linkedin.com/in/david-ghesquiere">David G.<LinkedinFilled style={{ marginLeft: '5px' }} /></a> - <a style={{ textDecoration: 'none' }} href="https://www.linkedin.com/in/florian-poinsot">Florian P. <LinkedinFilled style={{ marginLeft: '5px' }} /></a>{" "}
                </span>
                <br />
                Pokémon est une marque déposée de Nintendo et The Pokémon Company. Ce contenu est realisé par des fans pour des fans.
            </Footer>
        </div>
    );
};

export default AccountNotActivated;
