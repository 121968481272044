import { Button, Form, Input, InputNumber, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { NotificationService } from '../services/NotificationService';
import { ShopItemService } from '../services/ShopItemService';
import { TaskService } from '../services/TaskService';

type ManageNotificationsProps = {
    updateUser: (userProfile: any) => void;
    userProfile: any;
}

interface Notification {
    user: string;
    type: 'GIFT' | 'BABY' | 'INFO' | 'ALERT' | 'BUG';
    description: string;
    selectedItem?: string; // Only applicable when type is 'GIFT'
    quantity?: number; // Only applicable when type is 'GIFT'
    link?: string;
}


const ManageNotifications: React.FC<ManageNotificationsProps> = ({ userProfile, updateUser }) => {
    const notificationService = new NotificationService();
    const shopItemService = new ShopItemService();
    const taskService = new TaskService();

    const { Option } = Select;

    const [form] = Form.useForm();
    const [selectedType, setSelectedType] = useState<string>('');
    const [users, setUsers] = useState<any[]>([]);
    const [items, setItems] = useState<any[]>([]);

    useEffect(() => {
        getUsers();
        getItems();
    }, [])

    const onFinish = (values: Notification) => {
        const notification: any = {
            targetUserMongoId: values.user === "All" ? users : [users.find(user => user._id === values.user)],
            description: values.description,
            type: values.type,
            item: values.type === "GIFT" ? items.find(item => item.item_ID === values.selectedItem) : null,
            quantity: values.type === "GIFT" ? values.quantity : null,
            link: values.link
        }

        notificationService.sendManualNotification(notification).then(response => {
            if (response.success) {
                form.resetFields();
            }
        })
    };

    const handleTypeChange = (value: string) => {
        setSelectedType(value);
    };

    const getUsers = () => {
        taskService.getUsers().then(response => {
            if (response.success) {
                setUsers(response.users);
            }
        })
    }

    const getItems = () => {
        shopItemService.getAllItems().then(response => {
            if (response) {
                setItems(response);
            }
        });
    }

    return (
        <Form form={form} onFinish={onFinish}>
            <Form.Item label="Envoyer à" name="user" rules={[{ required: true }]}>
                <Select showSearch
                    placeholder="Choisir un utilisateur"
                    style={{ width: 200 }}
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    <Option value="All">Tous</Option>
                    {/* Add options for users */
                        users.map(user => {
                            return <Option value={user._id}>{user.username}</Option>
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item label="Type" name="type" rules={[{ required: true }]}>
                <Select placeholder="Choisir un type" style={{ width: 200 }} onChange={handleTypeChange}>
                    <Option value="GIFT">Gift</Option>
                    <Option value="BABY">Baby</Option>
                    <Option value="INFO">Info</Option>
                    <Option value="ALERT">Alert</Option>
                    <Option value="BUG">Bug</Option>
                </Select>
            </Form.Item>
            <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                <Input.TextArea rows={4} />
            </Form.Item>
            {selectedType === 'GIFT' && (
                <>
                    <Form.Item label="Choisir un item" name="selectedItem" rules={[{ required: true }]}>
                        <Select showSearch
                            placeholder="Choisir un item"
                            style={{ width: 200 }}
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            } >
                            {/* Add options for items */
                                items.map(item => {
                                    return <Option value={item.item_ID}>{item.item_name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label="Quantité" name="quantity" rules={[{ required: true }]}>
                        <InputNumber style={{ width: 200 }} min={1} />
                    </Form.Item>
                </>
            )}
            <Form.Item label="Lien" name="link">
                <Input />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Envoyer la notification
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ManageNotifications;
