import User from "../interface/User";
import { API_URL } from "./EndpointAPI";
import { setCookie, getCookie } from 'react-use-cookie';

export class GatchaService {
    private redirect() {
        setCookie('pokeascenttoken', '', { days: 0 });
        window.location.href = `/`;
    }

    public async getGatcha(user: User, category: string, numberItem: number, itemId: number): Promise<any> {
        const token = getCookie('pokeascenttoken');
        const response = await fetch(`${API_URL}/gatcha/`, { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ category, numberItem, itemId }) });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async setGatchaPrincipal(user: User): Promise<any> {
        const token = getCookie('pokeascenttoken');
        const response = await fetch(`${API_URL}/gatcha/principal`, { method: 'POST', headers: { 'Authorization': `Bearer ${token}` } });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async getOddsGatcha(category: string): Promise<any> {
        const token = getCookie('pokeascenttoken');
        const response = await fetch(`${API_URL}/gatcha/odds`, { method: 'POST', headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${token}` }, body: JSON.stringify({ category }) });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }
}