import { HeartFilled, InfoCircleOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Button, Card, Modal, Rate } from 'antd';
import React, { useState } from 'react';
import RareCandy from '../../assets/images/rare-candy.png';
import FlatModalButton from './FlatModalButton';
import { HeartIcon, SwordSimpleIcon, ShieldIcon, ThunderIcon, ShieldMagicalIcon, ShoeIcon } from '../SVGIcons';
import DiamondButton from './DiamondButton';
import { TaskService } from '../../services/TaskService';

type ItemProps = {
  img: string;
  name: string;
  quantity: number;
  description: string;
  effect: any;
  itemId?: any;
  type?: string;
  exp?: number;
  style?: React.CSSProperties;
  updateUser: (userProfile: any) => void;
};

const Item: React.FC<ItemProps> = ({ updateUser, type, exp, img, name, quantity, description, effect, style, itemId }) => {
  const [showDetail, setShowDetail] = useState(false);

  const handleDetailClick = () => {
    setShowDetail(true);
  };

  const handleModalClose = () => {
    setShowDetail(false);
  };

  const taskService = new TaskService();

  type StatKey = 'hp' | 'attack' | 'defense' | 'special_attack' | 'special_defense' | 'speed';

  const statsConfig: Record<StatKey, { icon: JSX.Element; color: string }> = {
    hp: { icon: <HeartIcon />, color: 'darkred' },
    attack: { icon: <SwordSimpleIcon />, color: '#ffbe5c' },
    defense: { icon: <ShieldIcon />, color: '#fe907a' },
    special_attack: { icon: <ThunderIcon />, color: 'purple' },
    special_defense: { icon: <ShieldMagicalIcon />, color: '#b16455' },
    speed: { icon: <ShoeIcon />, color: '#5c8df3' },
    // Assurez-vous que ces composants d'icônes existent et sont importés
  };

  const showPrice = (quantity: number) => {
    return (
      <Button
        type="primary"
        style={{
          fontSize: '14px',
          fontWeight: 'bold',
          backgroundColor: '#fd9779', // Couleur de fond du bouton
          borderColor: 'black', // Couleur de la bordure du bouton
          width: 'auto', // Largeur fixe à 80% de la taille de la card
          marginLeft: 'auto', // Centrer
          marginRight: 'auto', // Centrer
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '15px',
          borderStyle: 'solid',
          borderWidth: '2px',
          borderBottomWidth: '4px'
        }}
      >
        <span style={{ marginRight: '0.15em', marginLeft: '0.15em' }}>x{quantity}</span>
      </Button>
    );
  };

  const levelupEquipment = (itemId : any) => {
    taskService.levelUpEquipment(itemId).then((response : any) => {
      if (response) {
        updateUser(response)
      }
    });
  }

  const displayExpBar = (exp: number, itemId : any) => {
    // Calcul du pourcentage de l'expérience par rapport au maximum de 200
    const expPercentage = (exp / 200) * 100;

    const expBarContainerStyle: React.CSSProperties = {
      position: 'relative', // Position relative pour positionner le texte de manière absolue à l'intérieur
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%', // La largeur prend toute la largeur disponible
      backgroundColor: '#ddd', // Couleur de fond de la barre d'expérience
      borderRadius: '8px', // Bordures arrondies pour la barre d'expérience
      overflow: 'hidden', // Assure que la barre de progression ne dépasse pas les bords arrondis
      height: '16px', // Augmentation de la hauteur pour accommoder le texte
      border : '2px solid black'
    };

    const expBarFillStyle: React.CSSProperties = {
      height: '100%', // Utiliser 100% de la hauteur du conteneur
      width: `${expPercentage}%`, // Largeur basée sur le pourcentage de l'expérience
      backgroundColor: '#4caf50', // Couleur de la barre de progression
      borderRadius: '8px', // Bordures arrondies pour la barre de progression
      transition: 'width 0.5s ease-in-out', // Transition douce pour les changements de progression
    };

    const expTextStyle: React.CSSProperties = {
      position: 'absolute', // Positionnement absolu pour centrer le texte
      width: '100%', // Prendre toute la largeur du conteneur
      textAlign: 'center', // Centrer le texte horizontalement
      color: 'black', // Couleur du texte pour contraste
      fontWeight: 'bold', // Texte en gras pour une meilleure visibilité
      fontSize: '12px', // Taille du texte ajustée pour s'adapter à la barre
      top: '50%', // Positionner au milieu verticalement
      transform: 'translateY(-50%)', // Centrer verticalement par rapport à sa position
    };

    return (
      <div style={expBarContainerStyle}>
        <div style={expBarFillStyle}></div>
        {exp < 200 ?
          <span style={expTextStyle}>{exp}</span>
          :
          <span style={expTextStyle}><ArrowUpOutlined style={{fontWeight:'bold'}} onClick={() => levelupEquipment(itemId)}/></span>
        }
      </div>
    );
  };

  return (
    <>
      <Card
        style={{
          width: 100,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center', // Aligner le contenu verticalement
          height: '100%', // Hauteur de la Card
          backgroundColor: '#f8f8f8',
          borderStyle: 'solid',
          borderColor: 'black',
          borderWidth: '2px',
          boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.03),0 1px 6px -1px rgba(0, 0, 0, 0.02),0 2px 4px 0 rgba(0, 0, 0, 0.02)',
          cursor: 'pointer',
          marginRight: 'auto',
          marginLeft: 'auto',
          ...style
        }}
        size="small"
      >
        <div style={{ textAlign: 'center', fontWeight: '600', color: 'black' }}>{name}</div>
        <div style={{ textAlign: 'center', fontWeight: '600', color: 'black' }}>
          <InfoCircleOutlined onClick={handleDetailClick} style={description.length > 0 ? { visibility: 'visible' } : { visibility: 'hidden' }} />
        </div>
        <img
          src={img}
          alt={name}
          style={{ width: 'auto', height: '40px', objectFit: 'cover', margin: 'auto', display: 'block' }}
        />
        {type === "held-items" && displayExpBar(exp ? exp : 0, itemId)}
        <div style={{ width: '100%', padding: '5px 0' }}>
          {showPrice(quantity)}
        </div>
      </Card>
      <Modal
        title={name}
        open={showDetail}
        onCancel={handleModalClose}
        footer={[
          <FlatModalButton key="close" onClick={handleModalClose} label={'Fermer'} />,
        ]}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={img}
            alt={name}
            style={{ width: '40px', height: '40px', marginRight: '16px' }}
          />
          <div>
            <div>{description}</div>
            {effect && effect[0] === 'life' ? (
              <div>
                <Rate
                  disabled
                  value={Math.abs(effect[1])}
                  count={effect[1] <= 3 ? 3 : effect[1]}
                  character={<HeartFilled />}
                  style={{ color: effect[1] > 0 ? `#C80000` : `#5f3d46`, marginLeft: '0.5em' }}
                />
              </div>
            ) : null}
            {effect && effect[0] !== 'life' && (
              effect.map((itemEffect: any, index: any) => (
                index % 2 === 0 ? (
                  typeof itemEffect === 'string' && itemEffect in statsConfig ? (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px 2px 2px 2px' }}>
                      <DiamondButton
                        icon={statsConfig[itemEffect as StatKey].icon}
                        color={statsConfig[itemEffect as StatKey].color}
                        iconColor='#fffbd3'
                        onClick={() => { }}
                      />
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                        <span style={{ color: effect[index + 1] < 0 ? '#fffee5' : '#7DCE82', fontWeight: 'bold' }}>{effect[index + 1]}</span>
                      </div>
                    </div>
                  ) : null
                ) : null
              ))
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Item;
