import { Action } from '../actions/index';
import { ActionType } from '../action-types';

const initialState: any = {
    _id: '',
    type: '',
    rewards: [],
    active: false,
    name: '',
    level: 0,
    currentHealth: 0,
    backgroundImage: '',
    enemyPokemon: {},
    description: '',
    recommendedLevel: 0,
    timeNextFightInMin: 0
}

const bossFightsReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case ActionType.SET_BOSS_FIGHTS:
            return action.payload;
        default:
            return state;
    }
};

export default bossFightsReducer;
