import { API_URL } from "./EndpointAPI";
import { setCookie, getCookie } from 'react-use-cookie';

export class ShopItemService {
    private redirect() {
        setCookie('pokeascenttoken', '', { days: 0 });
        window.location.href = `/`;
    }

    public async getAllItems(): Promise<any> {
        const token = getCookie('pokeascenttoken');
        const response = await fetch(`${API_URL}/item`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}` } });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async getAllShopItems(): Promise<any> {
        const token = getCookie('pokeascenttoken');
        const response = await fetch(`${API_URL}/shop/all`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}` } });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async getShopItems(): Promise<any> {
        const token = getCookie('pokeascenttoken');
        const response = await fetch(`${API_URL}/shop`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}` } });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async buyNonUserShopItem(shopItem: any, quantity: any, _user: any): Promise<any> {
        const token = getCookie('pokeascenttoken');
        const response = await fetch(`${API_URL}/shop/buy`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ shopItem, quantity })
        });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async removeShopItem(shopItem: any): Promise<any> {
        const token = getCookie('pokeascenttoken');
        const response = await fetch(`${API_URL}/shop/remove`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ shopItem })
        });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async addShopItem(shopItem: any, quantity: any, cost: any, status: any, buildingType: any, buildingLevel: any): Promise<any> {
        const token = getCookie('pokeascenttoken');
        const response = await fetch(`${API_URL}/shop/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ shopItem, quantity, cost, status, buildingType, buildingLevel })
        });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }
}