import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import backgroundImageUrl from '../../assets/images/background-battlemenu.png';
import backgroundTriArenaImageUrl from '../../assets/images/background-tri-arena.png';
import backgroundArenaImageUrl from '../../assets/images/background-fight-1.png'
import RareCandy from '../../assets/images/rare-candy.png';
import OverlayShop from './OverlayShop';
import NavigationBar from './NavigationBar';
import { Button, Card, Col, Progress, Row } from 'antd';
import './ShopSection.css';
import { useDispatch, useSelector } from 'react-redux';
import { State, actionCreators } from '../../state';
import { BossFightsService } from '../../services/BossFightsService';
import { bindActionCreators } from 'redux';


interface BattleMenuProps {
    userProfile: any;
    updateUser: (userProfile: any) => void;
}

const BattleMenuNew: React.FC<BattleMenuProps> = ({ userProfile, updateUser }) => {

    const dispatch = useDispatch();
    const userPokemonWithStats = useSelector((state: State) => state.userPokemon);
    const bossFights = useSelector((state: State) => state.bossFights);
    const bossFightsService = new BossFightsService();
    const { setBossFights } = bindActionCreators(actionCreators, dispatch);

    const [countdowns, setCountdowns] = useState<{ [key: string]: string }>({});

    const calculTimeBeforeNextBossFight = (bossFights: any, userProfile: any) => {
        if ('bossFightHistory' in userProfile) {
            const lastBossFight = userProfile.bossFightHistory.find((bossFight: any) => bossFight.bossId === bossFights._id);
            if (lastBossFight) {
                const lastBossFightDate = new Date(lastBossFight.date);
                const nextBossFightDate = new Date(lastBossFightDate.getTime() + bossFights.timeNextFightInMin * 60000);
                const now = new Date();
                const timeBeforeNextBossFight = nextBossFightDate.getTime() - now.getTime();
                const hours = Math.floor((timeBeforeNextBossFight % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((timeBeforeNextBossFight % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((timeBeforeNextBossFight % (1000 * 60)) / 1000);
                if (timeBeforeNextBossFight > 0) {
                    return `${hours}h ${minutes}m ${seconds}s`;
                }
            }
        }
        return `Combat disponible !`;
    }

    useEffect(() => {
        getBossFights();
        const updatedCountdowns: { [key: string]: string } = {};
        bossFights.forEach((bossFight: any) => {
            const countdown = calculTimeBeforeNextBossFight(bossFight, userProfile);
            updatedCountdowns[bossFight._id] = countdown;
        });

        setCountdowns(updatedCountdowns);
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const updatedCountdowns: { [key: string]: string } = {};
            bossFights.forEach((bossFight: any) => {
                const countdown = calculTimeBeforeNextBossFight(bossFight, userProfile);
                updatedCountdowns[bossFight._id] = countdown;
            });

            setCountdowns(updatedCountdowns);
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [bossFights, userProfile]);

    const getBossFights = async () => {
        await bossFightsService.getBossFights().then((data: any) => {
            if (data) {
                setBossFights(data);
            }
        });
    }

    const navigate = useNavigate()

    const [dialogCommand, setDialogCommand] = useState<any>(null);
    const [, setDialogType] = useState<string | number | null>(null);

    // Fonction pour définir la commande et le type de dialogue
    const handleDialogCommand = (command: any) => {
        setDialogCommand(command);
        setDialogType(command.type ?? null);
    };

    const centerStyle: React.CSSProperties = {
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        zIndex: 10,
    };

    const imageStyle = {
        width: '100%', // Largeur de l'image
        height: '100%', // Hauteur de l'image
        objectFit: 'cover', // Ajuste la taille de l'image pour couvrir la hauteur et la largeur de la carte
        borderTopLeftRadius: '5px', // Coin supérieur gauche arrondi
        borderBottomLeftRadius: '5px', // Coin inférieur gauche arrondi
        imageRendering: '-webkit-optimize-contrast', // Améliore le contraste de l'image
    };

    function getColor(percentage: number): string {
        const r = Math.floor((222 * (100 - percentage)) / 100)
        const g = Math.floor((222 * percentage) / 100)
        return `rgb(${r}, ${g}, 0)`
    }

    return (
        <>
            <OverlayShop
                backgroundImage={backgroundImageUrl}
                overlayImage={''}
                style={{ marginBottom: '15px' }}
                money={userProfile.money}
                dna={userProfile.adnMoney}
                dialogObject={{
                    npcName: userProfile.username,
                    text: dialogCommand ? <> Oh, <b>{new Intl.NumberFormat().format(Number(dialogCommand))}</b><img src={RareCandy} alt="money" /> !</> : <>Texte par défaut</>,
                    isVisible: dialogCommand !== null,
                    hideAfter: 10000,
                    onHideAfter: () => setDialogCommand(null)
                }}
                lives={userPokemonWithStats.life}
            />
            <div className="shop-section-card">
                <div className="shop-section" style={{ gap: '0.5em' }}>
                    <Card style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center', // Aligner le contenu verticalement
                        backgroundColor: '#f8f8f8',
                        borderStyle: 'solid',
                        borderColor: 'black',
                        borderWidth: '2px',
                        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.03),0 1px 6px -1px rgba(0, 0, 0, 0.02),0 2px 4px 0 rgba(0, 0, 0, 0.02)',
                        cursor: 'pointer',
                        marginRight: 'auto',
                        marginLeft: 'auto',
                    }} key='adventure'>
                        <Row gutter={16}>
                            <Col span={6}>
                                <img src={backgroundArenaImageUrl} alt="battle background" style={imageStyle as React.CSSProperties} />
                            </Col>
                            <Col span={18}>
                                <p style={{ fontWeight: '600', color: 'black' }}>Rencontrez des Pokémons sauvages, affrontez des dresseurs et gagnez en niveau !</p>
                                <Button type="primary"
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        backgroundColor: (userPokemonWithStats._id.length > 0 && userPokemonWithStats.life > 0) ? '#fd9779' : '#dfdfdf', // Couleur de fond du bouton
                                        borderColor: 'black', // Couleur de la bordure du bouton
                                        width: 'auto', // Largeur fixe à 80% de la taille de la card
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '15px',
                                        borderStyle: 'solid',
                                        borderWidth: '2px',
                                        borderBottomWidth: '4px'
                                    }} onClick={() => navigate('/walk')}
                                    disabled={!(userPokemonWithStats._id.length > 0 && userPokemonWithStats.life > 0)}>
                                    Partir à l'aventure
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                    <Card style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center', // Aligner le contenu verticalement
                        backgroundColor: '#f8f8f8',
                        borderStyle: 'solid',
                        borderColor: 'black',
                        borderWidth: '2px',
                        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.03),0 1px 6px -1px rgba(0, 0, 0, 0.02),0 2px 4px 0 rgba(0, 0, 0, 0.02)',
                        cursor: 'pointer',
                        marginRight: 'auto',
                        marginLeft: 'auto',
                    }} key='champion'>
                        <Row gutter={16}>
                            <Col span={6}>
                                <img src={backgroundTriArenaImageUrl} alt="battle background" style={imageStyle as React.CSSProperties} />
                            </Col>
                            <Col span={18}>
                                <p style={{ fontWeight: '600', color: 'black' }}>Entrez dans l'arène ultime et affrontez un parmi trois adversaires uniques, chacun offrant un défi spécifique et des récompenses alléchantes.</p>
                                <Button type="primary"
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        backgroundColor: (userPokemonWithStats._id.length > 0 && userPokemonWithStats.life > 0) ? '#fd9779' : '#dfdfdf', // Couleur de fond du bouton
                                        borderColor: 'black', // Couleur de la bordure du bouton
                                        width: 'auto', // Largeur fixe à 80% de la taille de la card
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '15px',
                                        borderStyle: 'solid',
                                        borderWidth: '2px',
                                        borderBottomWidth: '4px'
                                    }} onClick={() => navigate('/walk-choose')}
                                    disabled={!(userPokemonWithStats._id.length > 0 && userPokemonWithStats.life > 0)}>
                                    Tri-Arena Challenge
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                    {bossFights.map((bossFight: any) => {
                        const healthPercent = Math.floor((100 * bossFight.currentHealth) / bossFight.enemyPokemon.stats.hp);
                        return (
                            <Card style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center', // Aligner le contenu verticalement
                                backgroundColor: '#f8f8f8',
                                borderStyle: 'solid',
                                borderColor: 'black',
                                borderWidth: '2px',
                                boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.03),0 1px 6px -1px rgba(0, 0, 0, 0.02),0 2px 4px 0 rgba(0, 0, 0, 0.02)',
                                cursor: 'pointer',
                                marginRight: 'auto',
                                marginLeft: 'auto',
                            }} key={bossFight._id}>
                                <Row gutter={16}>
                                    <Col span={6}>
                                        <img src={bossFight.backgroundImage} alt="boss" style={imageStyle as React.CSSProperties} />
                                    </Col>
                                    <Col span={18}>
                                        <Row gutter={16} align="middle">
                                            {/* Barre de progression et pourcentage à gauche */}
                                            <Col span={18}>
                                                <Progress
                                                    status="active"
                                                    strokeLinecap="butt"
                                                    percent={healthPercent}
                                                    showInfo={false}
                                                    strokeColor={getColor(healthPercent)}
                                                    trailColor="lightgrey"
                                                />
                                            </Col>
                                            <Col span={6} style={{ textAlign: 'left', fontWeight: '650' }}>
                                                {`${healthPercent}%`}
                                            </Col>
                                        </Row>
                                        <p style={{ fontWeight: '600', color: 'black' }}>{bossFight.description}</p>
                                        <Button type="primary"
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                backgroundColor: countdowns[bossFight._id] === "Combat disponible !" ? '#fd9779' : '#dfdfdf', // Couleur de fond du bouton
                                                borderColor: 'black', // Couleur de la bordure du bouton
                                                width: 'auto', // Largeur fixe à 80% de la taille de la card
                                                display: countdowns[bossFight._id] ? 'flex' : 'none',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                borderRadius: '15px',
                                                borderStyle: 'solid',
                                                borderWidth: '2px',
                                                borderBottomWidth: '4px'
                                            }}
                                            disabled={countdowns[bossFight._id] !== "Combat disponible !" ? true : false}
                                            onClick={() => navigate(`/boss?id=${bossFight._id}`)}>
                                            {countdowns[bossFight._id] === "Combat disponible !" ? `Combattre ${bossFight.name + ` | Niv. ???`}` : `Attendre ${countdowns[bossFight._id]}`}
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                        )
                    })
                    }
                </div>
            </div>
            <NavigationBar userProfile={userProfile} updateUser={updateUser} style={centerStyle} onButtonMonneyClick={handleDialogCommand} />
        </>
    )
};

export default BattleMenuNew;