import React, { useState } from 'react';

interface ExperienceBarProps {
  userPokemon: any;
}

const ExperienceBar: React.FC<ExperienceBarProps> = ({ userPokemon }) => {
  const [showExpDetails, setShowExpDetails] = useState(false);
  const currentExperience = userPokemon.experience;
  const currentLevel = userPokemon.stats.level;
  
  const beforeExperience = Math.pow(currentLevel, 3);
  const nextLevel = currentLevel + 1;
  const nextExperience = Math.pow(nextLevel, 3);
  
  const rangeEnd = nextExperience - beforeExperience;
  const rangeValue = currentExperience - beforeExperience;
  const expPercentage = (rangeValue / rangeEnd) * 100;

  const progressBarStyle: React.CSSProperties = {
    width: `${expPercentage}%`,
    height: '100%',
    backgroundColor: '#4CAF50', // Couleur verte pour la progression
    transition: 'width 0.5s ease-in-out',
  };

  // Afficher les détails de l'expérience au clic
  const handleClick = () => {
    setShowExpDetails(prev => !prev);
  };

  return (
    <div style={{ position: 'relative', width: '100%' }} onClick={handleClick}>
      {showExpDetails && (
        <div style={{ position: 'absolute', top: '-20px', left: '50%', transform: 'translateX(-50%)', zIndex: 2, fontSize: '0.9rem', fontWeight: 'bold', color: '#080808' }}>
          {`${currentExperience}/${nextExperience} XP`}
        </div>
      )}
      <div style={{
        width: '100%',
        height: '8px',
        backgroundColor: '#ddd',
        overflow: 'hidden',
      }}>
        <div style={progressBarStyle} />
      </div>
    </div>
  );
};

export default ExperienceBar;
