import Icon from '@ant-design/icons';
import React from 'react';
import { ReactComponent as Shoe } from "../assets/images/fast_mode.svg";
import { ReactComponent as DNA } from "../assets/images/icnIcons/dna.svg";
import { ReactComponent as Shield } from "../assets/images/shield.svg";
import { ReactComponent as ShieldMagical } from "../assets/images/shield_magical.svg";
import { ReactComponent as SwordSimple } from "../assets/images/sword_simple.svg";
import { ReactComponent as Shop } from "../assets/images/shop.svg";
import { ReactComponent as Egg } from "../assets/images/egg.svg";
import { ReactComponent as Walk } from "../assets/images/grass.svg";
import { ReactComponent as Life } from "../assets/images/life.svg";
import { ReactComponent as Ball } from '../assets/images/pokeball.svg'
import { ReactComponent as Bag } from '../assets/images/bag.svg'
import { ReactComponent as Logo } from '../assets/images/logo-pokeascent.svg'
import { ReactComponent as Shiny } from "../assets/images/shiny.svg";
import { ReactComponent as Female } from "../assets/images/icnGender/female.svg";
import { ReactComponent as Male } from "../assets/images/icnGender/male.svg";
import { ReactComponent as Fence } from "../assets/images/icnIcons/fence.svg";
import { ReactComponent as Share } from "../assets/images/icnIcons/share.svg";
import { HeartFilled, ThunderboltFilled, ExperimentFilled, HomeFilled, CrownFilled, SettingFilled } from '@ant-design/icons';

interface SVGIconsProps {
  style?: React.CSSProperties;
}

// SwordSimpleIcon
export const SwordSimpleIcon: React.FC<SVGIconsProps> = ({style}) => (
  <Icon component={SwordSimple} style={style} />
);

// ShieldIcon
export const ShieldIcon: React.FC<SVGIconsProps> = ({style}) => (
  <Icon component={Shield} style={style} />
);

// ShieldIcon
export const ShieldMagicalIcon: React.FC<SVGIconsProps> = ({style}) => (
  <Icon component={ShieldMagical} style={style} />
);

// ShoeIcon
export const ShoeIcon: React.FC<SVGIconsProps> = ({style}) => (
  <Icon component={Shoe} style={style} />
);

// ShopIcon
export const ShopIcon: React.FC<SVGIconsProps> = ({style}) => (
  <Icon component={Shop} style={style} />
);

export const EggIcon: React.FC<SVGIconsProps> = ({style}) => (
  <Icon component={Egg} style={style}/>
);

export const WalkIcon: React.FC<SVGIconsProps> = ({style}) => (
  <Icon component={Walk} style={style}/>
);

export const HeartIcon: React.FC<SVGIconsProps> = ({style}) => {
  return <HeartFilled style={style} />;
}

export const LifeIcon: React.FC<SVGIconsProps> = ({style}) => (
  <Icon component={Life} style={style}/>
);

export const BuildingIcon: React.FC<SVGIconsProps> = ({style}) => {
  return <ExperimentFilled style={style} />;
}

export const ThunderIcon: React.FC<SVGIconsProps> = ({style}) => {
  return <ThunderboltFilled style={style} />;
}

export const BallIcon: React.FC<SVGIconsProps> = ({style}) => (
  <Icon component={Ball} style={style}/>
);

export const BagIcon: React.FC<SVGIconsProps> = ({style}) => (
  <Icon component={Bag} style={style}/>
);

export const HomeIcon: React.FC<SVGIconsProps> = ({style}) => {
  return <HomeFilled style={style} />;
};

export const LadderIcon: React.FC<SVGIconsProps> = ({style}) => {
  return <CrownFilled style={style} />;
};

export const GearIcon: React.FC<SVGIconsProps> = ({style}) => {
  return <SettingFilled style={style} />;
}

export const LogoIcon: React.FC<SVGIconsProps> = ({style}) => (
  <Icon component={Logo} style={style}/>
);

export const ShinyIcon: React.FC<SVGIconsProps> = ({style}) => (
  <Icon component={Shiny} style={style}/>
);

export const FemaleIcon: React.FC<SVGIconsProps> = ({style}) => (
  <Icon component={Female} style={style}/>
);

export const MaleIcon: React.FC<SVGIconsProps> = ({style}) => (
  <Icon component={Male} style={style}/>
);

export const FenceIcon: React.FC<SVGIconsProps> = ({style}) => (
  <Icon component={Fence} style={style}/>
);

export const ShareIcon: React.FC<SVGIconsProps> = ({style}) => (
  <Icon component={Share} style={style}/>
);


// DNAIcon
type DNAIconProps = {
  disable?: boolean;
}

export const DNAIcon: React.FC<DNAIconProps> = ({disable}) => (
  <Icon component={DNA} style={disable ? { fontSize: '17px', stroke:"#b8b8b8" } : { fontSize: '17px', stroke:"#020202" }} />
);

const SVGIcons = {
  ShareIcon,
  ShieldMagicalIcon,
  FenceIcon,
  FemaleIcon,
  MaleIcon,
  SwordSimpleIcon,
  ShieldIcon,
  ShoeIcon,
  DNAIcon,
  ShopIcon,
  EggIcon,
  WalkIcon,
  HeartIcon,
  ThunderIcon,
  LifeIcon,
  BuildingIcon,
  BallIcon,
  HomeIcon,
  BagIcon,
  LadderIcon,
  GearIcon,
  LogoIcon,
  ShinyIcon
};

export default SVGIcons;
