import React, { useState } from 'react';
import FlatButton from './newUI/FlatButton';
import { BallIcon, BuildingIcon, FenceIcon, LadderIcon } from './SVGIcons';
import PokemonStatsCard from './newUI/PokemonStatsCard';
import OverlayImage from './newUI/OverlayImage';
import backgroundPartner from './../assets/images/background-partner.png';
import { useSelector } from 'react-redux';
import { State } from '../state';
import NavigationBar from './newUI/NavigationBar';
import SubMenu from './newUI/SubMenu';
import { useNavigate } from 'react-router-dom';
import RareCandy from '../assets/images/rare-candy.png';
import { getStats } from '../services/UserPokemonService';

type BaseContentProps = {
  updateUser: (userProfile: any) => void;
  userProfile: any;
}

const BaseContent: React.FC<BaseContentProps> = ({ userProfile, updateUser }) => {

  const [dialogCommand, setDialogCommand] = useState<any>(null);

  // Fonction pour définir la commande de dialogue
  const handleDialogCommand = (command: string) => {
    setDialogCommand(command);
  };

  const userPokemonWithStats = useSelector((state: State) => state.userPokemon);
  const backgroundImageUrl = backgroundPartner;
  // URL de l'image Pokémon à superposer
  const pokemonImageUrl = 'https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/202.png';
  const buffer = Buffer.from(userPokemonWithStats.img_front.data) || pokemonImageUrl;
  const base64String = buffer.toString('base64');
  const pokemonImage = `data:images/png;base64,${base64String}`;

  const navigate = useNavigate();

  const centerStyle: React.CSSProperties = {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    zIndex: 10,
  };

  return (<>
    {/* <div className="ant-row .ant-form-item.aligin-center fill" style={{ display: 'flex', flexDirection: 'column' }}>
      <UserProfileInfo userProfile={userProfile} updateUser={updateUser} isMobile={true} />
    </div>
    <div style={{ position: 'relative', zIndex: 1 }}>
      <Divider orientation="left" plain style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
        Derniers pokémons obtenus
      </Divider>
      <div>
        <LatestObtainedPokemon />
      </div>
    </div> */}
    <OverlayImage
      backgroundImage={backgroundImageUrl}
      overlayImage={userPokemonWithStats._id.length > 0 ? pokemonImage : pokemonImageUrl}
      style={{ marginBottom: '15px' }}
      lives={userPokemonWithStats.life}
      money={userProfile.money}
      dna={userProfile.adnMoney}
      dialogObject={{
        npcName: userPokemonWithStats.pokemon_name,
        text: dialogCommand ? <> Grk bbrrk <b>{new Intl.NumberFormat().format(Number(dialogCommand))}</b><img src={RareCandy} alt="money" /> !</> : <>Texte par défaut</>,
        isVisible: dialogCommand !== null,
        hideAfter: 10000,
        onHideAfter: () => setDialogCommand(null)
      }}
      userPokemonWithStats={userPokemonWithStats}
      userProfile={userProfile}
      updateUser={updateUser}
    />
    <PokemonStatsCard
      onLeftCircleClick={() => {}}
      onRightCircleClick={() => {}}
      onDiamondClick={() => {}}
      userPokemonWithStats={userPokemonWithStats}
      updateUser={updateUser}
      userProfile={userProfile}
    />
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    }}>
      <FlatButton
        color='#FEC569'
        label="Bâtiments"
        icon={<BuildingIcon />}
        iconPosition="left" // or "right"
        onClick={() => navigate('/building')}
        style={{ margin: '8px', color: '#f8f8f8' }}
      />
      <FlatButton style={{ margin: '8px', color: '#f8f8f8' }} color='#548bfc' label="" icon={<LadderIcon />} iconPosition="left" onClick={() => navigate('/ladder')} />
      {/* <FlatButton style={{ margin: '10px', color: '#f8f8f8' }} color='#fd9779' label="" icon={<BallIcon />} iconPosition="left" onClick={() => navigate('/pokedex')} disabled /> */}
      <FlatButton
        color='#6fae7f'
        label=""
        icon={<FenceIcon />}
        iconPosition="left" // or "right"
        onClick={() => navigate('/breeding')}
        style={{ margin: '8px', color: '#f8f8f8' }}
      />
      <FlatButton
        color='#fd9779'
        label=""
        icon={<BallIcon />}
        iconPosition="left" // or "right"
        onClick={() => navigate('/pokedex')}
        style={{ margin: '8px', color: '#f8f8f8' }}
      />
    </div>
    <SubMenu userPokemonWithStats={userPokemonWithStats} updateUser={updateUser} userProfile={userProfile} />
    <NavigationBar userProfile={userProfile} updateUser={updateUser} onButtonMonneyClick={handleDialogCommand} style={centerStyle} />
  </>
  );
};

export default BaseContent;
