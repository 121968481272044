import {
  Card,
  Layout,
  Spin,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import { wait } from '@testing-library/user-event/dist/utils';
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getCookie, setCookie } from 'react-use-cookie';
import { bindActionCreators } from "redux";
import logoprincipal from "../assets/images/PokeAscent.png";
import { TaskService } from "../services/TaskService";
import { UserPokemonService, getStats } from "../services/UserPokemonService";
import { actionCreators } from "../state";
import { BossFightsService } from "../services/BossFightsService";
import { BreedingService } from "../services/BreedingService";
import { GatchaService } from "../services/GatchaService";

const { Title } = Typography;
const { Footer, Content } = Layout;

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const { setUser, setPokemonPrincipal, setBreeding, setBossFights, setPokeEggs, setPokeballs, setSuperballs, setHyperballs } = bindActionCreators(actionCreators, dispatch);

  const bossFightsService = new BossFightsService();
  const breedingService = new BreedingService();
  const gatchaService = new GatchaService();

  const getOdds = async () => {
    gatchaService.getOddsGatcha('POKEEGG').then((response: any) => {
        setPokeEggs(response);
    });

    gatchaService.getOddsGatcha('POKEBALL').then((response: any) => {
        setPokeballs(response);
    });

    gatchaService.getOddsGatcha('SUPERBALL').then((response: any) => {
        setSuperballs(response);
    });

    gatchaService.getOddsGatcha('HYPERBALL').then((response: any) => {
        setHyperballs(response);
    });
  }

  const getBossFights = async () => {
    await bossFightsService.getBossFights().then((data: any) => {
      if (data) {
        setBossFights(data);
      }
    });
  }

  const getBreeding = async () => {
    await breedingService.getBreeding().then((reponse: any) => {
      if (reponse.success) {
        setBreeding(reponse.breeding);
      }
    });
  }

  const loadGlobalStates = () => {
      getOdds();
      getBossFights();
      getBreeding();
  }

  const userPokemonService = new UserPokemonService();

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');
  const path = searchParams.get('path');

  const taskService = new TaskService();

  const getPokemon = (pokemonId: any, user: any) => {
    userPokemonService.getUserPokemon(pokemonId).then(response => {
      const userPokemonWithStats = getStats(response, user);
      setPokemonPrincipal(userPokemonWithStats);
      navigate(path || '/');
    });
  }

  const getInfos = (code: string | null) => {
    taskService.getDiscordUser(code).then((response : any) => {
      if (response.success) {
        setCookie('pokeascenttoken', response.token, { days: 7, SameSite: 'Strict', Secure: true, path: '/' });
        wait(1000);
        setUser(response.user);
        loadGlobalStates();
        if (response.user.pokemon_principal_id) {
          getPokemon(response.user.pokemon_principal_id, response.user);
        } else {
          navigate(path || '/');
        }
      }
    });
  }

  const getInfosFromCookie = () => {
    taskService.getDiscordUserFromCookie().then(response => {
      if (!response) {
        taskService.loginDiscord().then(response => {
          window.location.href = response
        });
      } else if ('error' in response) {
        window.location.href = '/';
        setCookie('pokeascenttoken', '', { days: 0 });
      } else {
        setUser(response);
        loadGlobalStates();
        if (response.pokemon_principal_id) {
          getPokemon(response.pokemon_principal_id, response);
        } else {
          navigate(path || '/');
        }
      }
    });
  }

  useEffect(() => {
    const token = getCookie('pokeascenttoken');
    if (code) {
      getInfos(code)
    } else if (token) {
      getInfosFromCookie()
    }
  }, []);

  return (
    <>
      <div className="layout-default ant-layout layout-sign-up set-bg">
        <Content className="p-0">
          <div className="sign-up-header">
            <div className="">
              <Title><img src={logoprincipal} alt="Logo PokeAscent" /></Title>
            </div>
          </div>
          <Card
            className="card-signup header-solid h-full ant-card pt-0"
          >
            <div className="sign-up-gateways">
              <Spin />
            </div>
            <p className="font-semibold text-muted text-center" style={{ paddingTop: "30px" }}>
              Connexion en cours...
            </p>
          </Card>
        </Content>
        <Footer className='footer-login'>
          <span className="copyright">
            {" "}
            Copyright © 2024 Chaloon - Pokémon est une marque déposée de Nintendo et The Pokémon Company. Ce contenu est realisé par des fans pour des fans.
            {" "}
          </span>
          <br />
        </Footer>
      </div>
    </>
  );

}


export default Login
