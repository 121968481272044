import React from 'react';
import { Col, Progress, Row } from 'antd';

interface HealthBarProps {
    hp: number;
    maxHp: number;
    isEnemy: boolean;
    isPercent?: boolean;
}

const HealthBar: React.FC<HealthBarProps> = ({ hp, maxHp, isPercent }) => {

    function getColor(percentage: number): string {
        const r = Math.floor((222 * (100 - percentage)) / 100)
        const g = Math.floor((222 * percentage) / 100)
        return `rgb(${r}, ${g}, 0)`
    }

    return (
        <Row className="battle-pokemon-hp">
            <Col
                span={3}
                style={{ textAlign: 'center', fontWeight: '650', margin: 'auto' }}
            >
                PV
            </Col>
            <Col span={12} style={{ marginBottom: '1px' }}>
                <Progress
                    status="active"
                    strokeLinecap="butt"
                    percent={(100 * hp) / maxHp}
                    showInfo={false}
                    strokeColor={getColor((100 * hp) / maxHp)}
                    trailColor="lightgrey"
                />
            </Col>
            <Col span={8} style={{ textAlign: 'center', fontWeight: '650', margin: 'auto' }}>
                {isPercent ? `${Math.floor((100 * hp) / maxHp)}%` : `${hp}/${maxHp}`}
            </Col>
        </Row>
    );
};

export default HealthBar;
