import { combineReducers } from "redux";
import userReducer from "./userReducer";
import userPokemonReducer from "./userPokemonReducer";
import bossFightsReducer from "./bossFightsReducer";
import breedingReducer from "./breedingReducer";
import oddsReducer from "./oddsReducer";

const reducers = combineReducers({
    user: userReducer,
    userPokemon: userPokemonReducer,
    bossFights: bossFightsReducer,
    breeding: breedingReducer,
    odds: oddsReducer
});

export default reducers;

export type State = ReturnType<typeof reducers>;