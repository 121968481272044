import { API_URL } from "./EndpointAPI";
import { setCookie, getCookie } from 'react-use-cookie';

export class TaskService {
    private redirect() {
        setCookie('pokeascenttoken', '', { days: 0 });
        window.location.href = `/`;
    }

    public async loginDiscord(): Promise<any> {
        const response = await fetch(`${API_URL}/login`, { method: 'GET' });
        const json = await response.json();
        return json;
    }

    public async getDiscordUser(code: any): Promise<any> {
        const response = await fetch(`${API_URL}/discord/login/callback/${code}`, { method: 'GET' });
        const json = await response.json();
        return json;
    }

    public async getDiscordUserFromCookie(): Promise<any> {
        const token = getCookie('pokeascenttoken');
        const response = await fetch(`${API_URL}/discord/login/token`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}` } });
        const json = await response.json();
        if ('error' in json && json.enabled) {
            this.redirect();
        }
        return json;
    }

    public async bePaid(): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/user/paid`, {
            method: 'POST',
            // Setup headers with cors
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async newUserReward(): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/user/reward`, {
            method: 'POST',
            // Setup headers with cors
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async getUsers(): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/users`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}` } });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async updateUser(user: any, payload?: any): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/user/update`, { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ user, payload }) });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async updateAdminUser(user: any, payload?: any): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/admin/user/update`, { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ user, payload }) });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async getPokemonById(pokemonId: string): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/pokemon`, { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ pokemonId }) });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async resetUser(): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/user/reset`, { method: 'POST', headers: { 'Authorization': `Bearer ${token}` } });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }
    
    public async updateUserEquipments(equipments: any): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/user/equipments`, { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ equipments }) });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async levelUpEquipment(itemId: any): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/user/equipment/levelup`, { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ itemId }) });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }
}