import React, { useEffect, useState } from 'react';
import backgroundImageUrl from '../../assets/images/background-breeding.png';
import RareCandy from '../../assets/images/rare-candy.png';
import NavigationBar from './NavigationBar';
import { Input, Modal } from 'antd';
import './ShopSection.css';
import { useDispatch, useSelector } from 'react-redux';
import { State, actionCreators } from '../../state';
import PokemonItem from './PokemonItem';
import { UserPokemonService } from '../../services/UserPokemonService';
import FlatModalButton from './FlatModalButton';
import FlatButton from './FlatButton';
import { EggIcon } from '../SVGIcons';
import OverlayBreeding from './OverlayBreeding';
import { BreedingService } from '../../services/BreedingService';
import SubMenuBreeding from './SubMenuBreeding';
import { bindActionCreators } from 'redux';

interface BreedingMenuProps {
    userProfile: any;
    updateUser: (userProfile: any) => void;
}

const BreedingMenu: React.FC<BreedingMenuProps> = ({ userProfile, updateUser }) => {

    const breeding = useSelector((state: State) => state.breeding);
    const userPokemonWithStats = useSelector((state: State) => state.userPokemon);

    const dispatch = useDispatch();
    const { setBreeding, resetBreeding } = bindActionCreators(actionCreators, dispatch);

    const [dialogCommand, setDialogCommand] = useState<any>(null);
    const [, setDialogType] = useState<string | number | null>(null);
    const [allPokemons, setAllPokemons] = useState<any>(null);
    const [countdowns, setCountdowns] = useState<{ [key: string]: string }>({});

    const userPokemonService = new UserPokemonService();
    const breedingService = new BreedingService();

    useEffect(() => {
        if (breeding && breeding._id.length > 0) {
            setSelectedPokemon({ ...selectedPokemon, 1: breeding.pokemon1, 2: breeding.pokemon2 });

            const updatedCountdowns: { [key: string]: string } = {};
            const countdown = breedingTimeLeft();
            updatedCountdowns[breeding._id] = countdown;

            setCountdowns(updatedCountdowns);
        }

        userPokemonService.getPokemonsInStock().then((pokemons: any) => {
            setAllPokemons(pokemons);
        });
    }, []);

    useEffect(() => {
        if (breeding && breeding.status === 'In Progress') {
            const intervalId = setInterval(() => {
                const updatedCountdowns: { [key: string]: string } = {};
                const countdown = breedingTimeLeft();
                updatedCountdowns[breeding._id] = countdown;

                setCountdowns(updatedCountdowns);
            }, 1000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [breeding]);

    // Calcule le nombre de secondes restantes avant la fin de l'élevage
    const breedingTimeLeft = () => {
        if (!breeding || (breeding && breeding._id === '')) return '';
        const now = new Date();
        const end = new Date(breeding.breedingEnd);
        // Formate l'affichage du temps restant en heures, minutes et secondes
        const hours = Math.floor((end.getTime() - now.getTime()) / 3600000);
        const minutes = Math.floor((end.getTime() - now.getTime()) / 60000) % 60;
        const seconds = Math.floor((end.getTime() - now.getTime()) / 1000) % 60;

        if (hours <= 0 && minutes <= 0 && seconds <= 0 && breeding.status === 'In Progress') {
            isBreedingInProgress();
            return '';
        }

        return `${hours}h ${minutes}m ${seconds}s`;
    };

    // Check if the breeding is in progress
    const isBreedingInProgress = () => {
        breedingService.checkBreedingStatus(breeding._id).then((response: any) => {
            if (response.success) {
                setBreeding(response.breeding);
            }
        });
    };

    // Retrieve the honor ball
    const retrieveHonorBall = () => {
        breedingService.retrieveHonorBall(breeding._id).then((response: any) => {
            if (response.success) {
                resetBreeding();
                setSelectedPokemon({ 1: null, 2: null });
                updateUser(response.user);
            }
        });
    };

    // Fonction pour définir la commande et le type de dialogue
    const handleDialogCommand = (command: any) => {
        setDialogCommand(command);
        setDialogType(command.type ?? null);
    };

    const centerStyle: React.CSSProperties = {
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        zIndex: 10,
    };

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedPokemon, setSelectedPokemon] = useState<any>({});
    const [selectedCard, setSelectedCard] = useState<any>(null);
    const [searchText, setSearchText] = useState('');

    const showModal = (cardId: number) => {
        setIsModalVisible(true);
        setSelectedCard(cardId);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const selectPokemon = (pokemon: any) => {
        setSelectedPokemon({ ...selectedPokemon, [selectedCard]: pokemon });
        setIsModalVisible(false);

    };

    const createImageSrc = (pokemon: any) => {
        let base64String = pokemon.img_front.data;
        if (pokemon.img_front.data.type && pokemon.img_front.data.type === 'Buffer') {
            const buffer = Buffer.from(pokemon.img_front.data);
            base64String = buffer.toString('base64');
        }
        const pokemonImage = `data:images/png;base64,${base64String}`;
        return pokemonImage;
    }

    const normalizeText = (text: string) => {
        return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    };

    const filteredInventory = allPokemons && allPokemons.sort((a: any, b: any) => a.pokemon_name.localeCompare(b.pokemon_name))
        .filter((pokemon: any) => {
            const pokemonNameNormalized = normalizeText(pokemon.pokemon_name);
            const searchTextNormalized = normalizeText(searchText);
            return pokemonNameNormalized.includes(searchTextNormalized);
        });

    const noPokemonImg: string = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeBAMAAADJHrORAAAAA3NCSVQFBQUYJt5DAAAAIVBMVEX///8xMTH/lDqcSjr/1jr////eWjpKUlKlpcV7e4TezvdcMgHRAAAAAXRSTlMAQObYZgAAAH5JREFUGNNjYKALEBQUROEqKSkhCTACuUpqAnC+kAtIIBEurRLqBOSnCeDgCymB1aclwvlg83DyhcH8tDRDKF/cSL0cyE0uhPHLKyLK0szLYXzG6aWh5eXllXD7l86IAHJXwB0sFTlz1apVjQj/dAG5HQJI/u3o6BDEEx60AwCJgyJdBXcpkgAAAABJRU5ErkJggg==';

    const canReproduce = () => {
        const pokemon1 = selectedPokemon[1];
        const pokemon2 = selectedPokemon[2];

        // Ensure both Pokémon are selected
        if (!pokemon1 || !pokemon2) return false;

        // Check if they have the same pokemon_ID and different genders
        if (pokemon1.pokemon_ID === pokemon2.pokemon_ID && pokemon1.gender !== pokemon2.gender) return true;

        // Check if either Pokémon has a pokemon_ID of 132
        if (pokemon1.pokemon_ID === 132 || pokemon2.pokemon_ID === 132) return true;

        // If none of the conditions are met, reproduction is not possible
        return false;
    };

    const handleReproduction = () => {
        if (!canReproduce()) return;
        breedingService.initiateBreeding(selectedPokemon[1], selectedPokemon[2]).then((response: any) => {
            if (response.success) {
                setCountdowns({ [response.breeding._id]: '' });
                setBreeding(response.breeding);
            }
        });
    };

    const honorBallImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeBAMAAADJHrORAAAAA3NCSVQFBQUYJt5DAAAAGFBMVEX///8xMTH///+lpcXezvd7e4ScSjreWjp0ij1fAAAAAXRSTlMAQObYZgAAAHZJREFUeNq90D0SQDAQhmFukMU4wDLUxAWwDqDR+9sLKFxfGBExo83bPbPVt56bAMAiIoKhj6pUPA7wrDbnKxI/Dm7XltNfh5eJ+ttxmbFiN2rznCw0sLY/TMjMq9ButkSx1faifCeiwuypFKV47ZVSwvcfTjoAHUsYJtF4/l4AAAAASUVORK5CYII=';

    // Fonction pour déterminer quel bouton afficher
    const renderBreedingButton = () => {
        if (breeding && breeding._id === '') {
            return (
                <FlatButton
                    color='#fd9779'
                    disabled={!canReproduce()}
                    label="Reproduire"
                    icon={<EggIcon />}
                    onClick={handleReproduction}
                    style={{ width: '100%', justifyContent: 'center' }}
                />
            );
        } else if (breeding && breeding.status === 'In Progress') {
            return (
                <FlatButton
                    color='#6fae7f'
                    label={`Attendre ${countdowns[breeding._id]}`}
                    style={{ width: '100%', justifyContent: 'center' }}
                    disabled // Désactivé car c'est juste un indicateur
                    onClick={() => { }}
                />
            );
        } else if (breeding && breeding.status === 'Completed' && !breeding.ballId) {
            return (
                <FlatButton
                    color='#548bfc'
                    label="Récupérer l'Honor Ball"
                    icon={<img src={honorBallImage} alt="Honor Ball" style={{ width: '24px', height: '24px' }} />}
                    onClick={retrieveHonorBall}
                    style={{ width: '100%', justifyContent: 'center' }}
                />
            );
        }
        // Retourne null si aucune des conditions n'est remplie (ou un élément vide si nécessaire)
        return null;
    };

    const calculateIVsPotential = (pokemon : any) => {
        // Création directe d'un tableau avec les valeurs IV du Pokémon
        const IVs = [
            pokemon.iv_attack,
            pokemon.iv_defense,
            pokemon.iv_special_attack,
            pokemon.iv_special_defense,
            pokemon.iv_speed,
            pokemon.iv_hp
        ];
    
        // Calcul du total des IVs
        const maxIVs = 186; // 31 * 6
        const totalIVs = IVs.reduce((acc, current) => acc + current, 0);
    
        // Calcul du pourcentage
        const ivsPercentage = (totalIVs / maxIVs) * 100;
    
        // Retourne le pourcentage arrondi à l'entier le plus proche
        return ivsPercentage.toFixed(0); // Arrondi à l'entier le plus proche
    };
    
    
    const calculateEVsPotential = (pokemon : any) => {
        const EVs = [pokemon.ev_attack, pokemon.ev_defense, pokemon.ev_special_attack, pokemon.ev_special_defense, pokemon.ev_speed, pokemon.ev_hp];
        const maxEVs = 510; // Maximum distribuable
        const totalEVs = EVs.reduce((acc : any, current : any) => acc + current, 0);
        const evsPercentage = (totalEVs / maxEVs) * 100;
        return evsPercentage.toFixed(0); // Arrondi à deux décimales
    };

    return (
        <>
            <OverlayBreeding
                backgroundImage={backgroundImageUrl}
                pokemonBreeding1Image={selectedPokemon[1] ? createImageSrc(selectedPokemon[1]) : ''}
                pokemonBreeding2Image={selectedPokemon[2] ? createImageSrc(selectedPokemon[2]) : ''}
                style={{ marginBottom: '15px' }}
                money={userProfile.money}
                dna={userProfile.adnMoney}
                dialogObject={{
                    npcName: dialogCommand ? userProfile.username : 'Pr. Gamble',
                    text: dialogCommand ? <> Oh, <b>{new Intl.NumberFormat().format(Number(dialogCommand))}</b><img src={RareCandy} alt="money" /> !</> : <>Pour reproduire tes Pokémons, ils doivent être de la même espèce et de sexe différent, sinon, utilise un Métamorph !</>,
                    isVisible: true || dialogCommand !== null,
                    hideAfter: 15000,
                    onHideAfter: () => setDialogCommand(null)
                }}
                pokemonBreeding1ImgClassName={undefined} pokemonBreeding2ImgClassName={undefined}
            />

            <div className="shop-section-card">
                <div className="shop-section" style={{ position: 'relative', width: '100%' }}>
                    {/* Pokemon Items */}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        padding: '0px 5px',
                    }}>
                        <PokemonItem
                            img={selectedPokemon[1] ? createImageSrc(selectedPokemon[1]) : noPokemonImg}
                            name={selectedPokemon[1]?.pokemon_name || 'Pokémon 1'}
                            onClick={() => breeding && breeding._id.length > 0 ? {} : showModal(1)}
                            gender={selectedPokemon[1]?.gender}
                            rank={selectedPokemon[1] ? selectedPokemon[1].rank : -1}
                            evsPotentiel={selectedPokemon[1] ? calculateEVsPotential(selectedPokemon[1]) : undefined}
                            ivsPotentiel={selectedPokemon[1] ? calculateIVsPotential(selectedPokemon[1]) : undefined}
                        />
                        <div style={{ flex: 1, marginLeft: '20px', marginRight: '20px' }}></div>
                        <PokemonItem
                            img={selectedPokemon[2] ? createImageSrc(selectedPokemon[2]) : noPokemonImg}
                            name={selectedPokemon[2]?.pokemon_name || ' Pokémon 2'}
                            onClick={() => breeding && breeding._id.length > 0 ? {} : showModal(2)}
                            gender={selectedPokemon[2]?.gender}
                            rank={selectedPokemon[2]? selectedPokemon[2].rank : -1}
                            evsPotentiel={selectedPokemon[2] ? calculateEVsPotential(selectedPokemon[2]) : undefined}
                            ivsPotentiel={selectedPokemon[2] ? calculateIVsPotential(selectedPokemon[2]) : undefined}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', padding: '0px 0px' }}>
                        <SubMenuBreeding userPokemonWithStats={userPokemonWithStats} updateUser={updateUser} userProfile={userProfile} honorBallImg={honorBallImage} button={renderBreedingButton()} />
                    </div>
                </div>

                <Modal
                    title="Choisissez un Pokémon"
                    open={isModalVisible}
                    onCancel={handleCancel}
                    footer={[
                        <FlatModalButton
                            key="back"
                            label="Annuler"
                            onClick={handleCancel}
                        />
                    ]}
                >
                    {isModalVisible && (<>
                        <Input.Search
                            placeholder="Nom de l'objet"
                            onChange={(e) => setSearchText(e.target.value)}
                            style={{ marginBottom: '15px' }}
                            size='large'
                            key="searchInput"
                        />

                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', textAlign: 'center', justifyContent: 'center', maxHeight: '35vh', overflowY: 'auto' }}>
                            {allPokemons && filteredInventory.map((pokemon: any) => (
                                <PokemonItem
                                    img={createImageSrc(pokemon)}
                                    name={pokemon.pokemon_name}
                                    onClick={() => selectPokemon(pokemon)}
                                    style={{ width: '40%' }}
                                    gender={pokemon.gender}
                                    key={pokemon._id}
                                    rank={pokemon.rank}
                                    evsPotentiel={calculateEVsPotential(pokemon)}
                                    ivsPotentiel={calculateIVsPotential(pokemon)}
                                />
                            ))}

                        </div> </>)}
                </Modal>
                <div className="breeding-action">

                </div>
            </div >

            <NavigationBar userProfile={userProfile} updateUser={updateUser} style={centerStyle} onButtonMonneyClick={handleDialogCommand} />
        </>
    );

};

export default BreedingMenu;