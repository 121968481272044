import React, { useEffect, useState } from 'react';
import { Table, Switch } from 'antd';
import { TaskService } from '../services/TaskService';

const AdminNewUsers : React.FC = () => {
    const [users, setUsers] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
      });
    const taskService = new TaskService();

    const handleToggleActivation = (_id : any, activated : any) => {
        const user = { _id: _id };
        const payload = { enabled: activated };
        taskService.updateAdminUser(user, payload).then(response => {
            if (response.success) {
                getProperties();
            }
        });
    };

    useEffect(() => {
        getProperties();
    }, [])

    const getProperties = () => {
        taskService.getUsers().then(response => {
            if (response.success) {
                setUsers(response.users);
            }
        });
    }

    const handleTableChange = (pagination : any, filters : any, sorter : any) => {
        setPagination({ ...pagination });
      };

    const columns = [
        {
          title: 'Nom de l\'utilisateur',
          dataIndex: 'username',
          key: 'username',
          sorter: (a: any, b: any) => a.username.localeCompare(b.username),
        },
        {
          title: 'Nouvel utilisateur',
          dataIndex: 'userIsNew',
          key: 'userIsNew',
          sorter: (a: any, b: any) => a.userIsNew === b.userIsNew ? 0 : a.userIsNew ? -1 : 1,
          render: (userIsNew: boolean) => (userIsNew ? 'Oui' : 'Non'),
        },
        {
          title: 'Activé',
          dataIndex: 'enabled',
          key: 'enabled',
          sorter: (a: any, b: any) => a.enabled === b.enabled ? 0 : a.enabled ? -1 : 1,
          render: (enabled: boolean, record: any) => (
            <Switch
              checked={enabled}
              onChange={(checked) => handleToggleActivation(record._id, checked)}
            />
          ),
        },
      ];

      return (
        <Table
          dataSource={users}
          columns={columns}
          rowKey="_id"
          pagination={pagination}
          onChange={handleTableChange}
        />
      );
};

export default AdminNewUsers;
