import 'antd/dist/reset.css'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { getCookie } from 'react-use-cookie'
import { bindActionCreators } from 'redux'
import './App.css'
import './assets/styles/main.css'
import './assets/styles/responsive.css'
import './assets/styles/userProfileInfo.css';
import BaseContent from './components/BaseContent'
import CheckCookie from './components/CheckCookie'
import Loading from './components/Loading'
import Main from './components/Main'
import ManageNotifications from './components/ManageNotifications'
import ManageShop from './components/ManageShop'
import ManageUser from './components/ManageUser'
import NewPlayerModal from './components/NewPlayer'
import SignUp from './components/SignUp'
import User from './interface/User'
import { State, actionCreators } from './state'
import AccountNotActivated from './components/AccountNotActivated'
import AdminNewUsers from './components/AdminNewUsers'
import ShopElement from './components/newUI/ShopElement'
import WalkElement from './components/newUI/WalkElement'
import BattleMenuNew from './components/newUI/BatteMenu'
import BossElement from './components/newUI/BossElement'
import BuildingMenu from './components/newUI/BuildingMenu'
import LadderMenu from './components/newUI/LadderMenu'
import BagMenu from './components/newUI/BagMenu'
import BreedingMenu from './components/newUI/BreedingMenu'
import Header from './components/newUI/Header'
import WalkChooseElement from './components/newUI/WalkChooseElement'
import FriendMenu from './components/newUI/FriendMenu'
import InstallButton from './components/newUI/InstallButton'
import PokedexMenu from './components/newUI/PokedexMenu'
import { setPokemonPrincipal } from './state/action-creators'
import { getStats } from './services/UserPokemonService'

const imageUrls = [
  '/images/background-2.jpg',
  '/images/background-3.png',
  '/images/background-4.png',
];

window.onload = function () {
  const body = document.querySelector('body');
  if (body) {
    const imageUrl = imageUrls[Math.floor(Math.random() * imageUrls.length)];
    body.style.backgroundImage = `url(${imageUrl})`;
  }
}

function App() {
  // Load music settings from cookie
  const [isLoaded, setIsLoaded] = useState(false);
  const [checked, setChecked] = useState(false);
  const [defaultMusic, setDefaultMusic] = useState('auto');
  const audioSrcChill = './audios/pokechill.mp3';
  const audioSrcEpic = './audios/pokeepic.mp3';


  // Only for testing
  const dispatch = useDispatch();
  const { setUser } = bindActionCreators(actionCreators, dispatch);

  const updateUser = (newUser: any) => {
    setUser(newUser);
  }

  const user: User = useSelector((state: State) => {
    return state.user
  })

  const onSaveMusicSettings = () => {
    const cookieMusicSettings = getCookie('pokeascenttokensettings');
    const musicSettings = JSON.parse(cookieMusicSettings);
    setIsLoaded(true);
    setChecked(musicSettings.playOnStart);
    setDefaultMusic(musicSettings.defaultMusic);
  }

  if (!isLoaded) {
    const cookieMusicSettings = getCookie('pokeascenttokensettings');
    if (cookieMusicSettings) {
      const musicSettings = JSON.parse(cookieMusicSettings);
      setChecked(musicSettings.playOnStart);
      setDefaultMusic(musicSettings.defaultMusic);
      setIsLoaded(true);
    } else {
      setChecked(true);
      setDefaultMusic('auto');
      setIsLoaded(true);
    }
  }

  return (
    <BrowserRouter basename="/">
      <div>
        <Routes>
          <Route path="/loading" element={<Loading />} />
        </Routes>
        {user._id !== 'undefined' && user.enabled ?
          <>
            <NewPlayerModal updateUser={updateUser} userProfile={user} />
            {/* Panel du milieu */}
            <div className="main-content">
              <div className="content-right command-bar-container">
                <Header audioSrc={defaultMusic === "chill" ? audioSrcChill : audioSrcEpic} playOnLoad={checked} userId={user.userId} avatar={user.avatar} username={user.username} userProfile={user} updateUser={updateUser} onSaveMusicSettings={onSaveMusicSettings} />
                <Routes>
                  <Route path="/" element={<Main props={<BaseContent userProfile={user} updateUser={updateUser} />} />} />
                  <Route path="/admin/shop" element={<Main props={<ManageShop />} />} />
                  <Route path="/admin/notification" element={<ManageNotifications userProfile={user} updateUser={updateUser} />} />
                  <Route path="/admin/users" element={<ManageUser />} />
                  <Route path="/admin/new-users" element={<AdminNewUsers />} />
                  {/* Route User */}
                  <Route path="/pokedex" element={<Main props={<PokedexMenu updateUser={updateUser} userProfile={user} />} />} />
                  <Route path="/shop" element={<Main props={<ShopElement updateUser={updateUser} userProfile={user} />} />} />
                  <Route path="/walk" element={<Main props={<WalkElement updateUser={updateUser} userProfile={user} />} />} />
                  <Route path="/walk-choose" element={<Main props={<WalkChooseElement updateUser={updateUser} userProfile={user} />} />} />
                  <Route path="/boss" element={<Main props={<BossElement userProfile={user} updateUser={updateUser} />} />} />
                  <Route path="/battle-menu" element={<Main props={<BattleMenuNew userProfile={user} updateUser={updateUser} />} />} />
                  <Route path="/building" element={<Main props={<BuildingMenu updateUser={updateUser} userProfile={user} />} />} />
                  <Route path="/ladder" element={<LadderMenu updateUser={updateUser} userProfile={user} />} />
                  <Route path="/bag" element={<Main props={<BagMenu userProfile={user} updateUser={updateUser} />} />} />
                  <Route path="/breeding" element={<BreedingMenu userProfile={user} updateUser={updateUser} />} />
                  <Route path="/friend" element={<Main props={<FriendMenu userProfile={user} updateUser={updateUser} />} />} />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </div>
            </div>
          </>
          : user._id !== 'undefined' && !user.enabled ? <AccountNotActivated /> :
            <div>
              <CheckCookie props={{ path: window.location.pathname }} />
              <Routes>
                <Route path="/" element={<Main props={<SignUp />} />} />
              </Routes>
            </div>}
            <InstallButton />
      </div>
    </BrowserRouter>
  )
}

export default App
