import Icon, { ExperimentFilled } from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { Avatar, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SVGEgg } from "../assets/images/egg.svg";
import { ReactComponent as SVGWalk } from "../assets/images/grass.svg";
import { ReactComponent as SVGPokedex } from '../assets/images/pokeball.svg';
import { ReactComponent as SVGShop } from "../assets/images/shop.svg";
import { TaskService } from '../services/TaskService';
import { DNAIcon } from './SVGIcons';

interface NewPlayerModalProps {
    updateUser: (userProfile: any) => void;
    userProfile: any;
}

const NewPlayerModal: React.FC<NewPlayerModalProps> = ({ userProfile, updateUser }) => {

    const taskService = new TaskService();
    const navigate = useNavigate();

    const PokeBallIcon = (props: Partial<CustomIconComponentProps>) => (
        <Icon component={SVGPokedex} {...props} />
    )

    const EggIcon = (props: Partial<CustomIconComponentProps>) => (
        <Icon component={SVGEgg} {...props} />
    );
    const ShopIcon = (props: Partial<CustomIconComponentProps>) => (
        <Icon component={SVGShop} {...props} />
    );
    const WalkIcon = (props: Partial<CustomIconComponentProps>) => (
        <Icon component={SVGWalk} {...props} />
    );

    // Function used for onOk modal to call the API and get the first egg
    const getFirstEgg = async () => {
        taskService.newUserReward().then((data) => {
            if (data) {
                updateUser(data);
            }
        });
    }

    return (
        <Modal
            title={<div><Avatar src={require('../assets/images/prGamble.webp')} style={{ marginRight: '10px' }} />Bienvenue sur Poke Ascent !</div>}
            open={userProfile.userIsNew}
            onOk={getFirstEgg}
            okType='primary'
            okText={<div>Recevoir et ouvrir mes premiers oeufs <EggIcon /></div>}
            cancelButtonProps={{ style: { display: 'none' } }}
            closable={false}
        >
            <div>
                <p>Merci d'avoir rejoint notre jeu !</p>
                <p>Vous venez de recevoir <b>votre premier oeuf</b>. Vous pouvez <b>l'ouvrir</b> dès maintenant pour découvrir quelle créature se cache à l'intérieur.<EggIcon style={{ marginLeft: '5px' }} /></p>
                <p>N'oubliez pas de <b>faire des combats</b> pour entraîner vos créatures <WalkIcon style={{ marginLeft: '2.5px', marginRight: '2.5px' }} /> et de vous rendre dans la <b>boutique</b> pour acheter des balls supplémentaires.<ShopIcon style={{ marginLeft: '5px' }} /></p>
                <p>La boutique propose un nombre limité d'objets. <br/>Afin d'avoir plus de choix, il est nécéssaire d'<b>améliorer vos bâtiments.</b><ExperimentFilled style={{ marginLeft: '5px' }} /></p>
                <p>Pour améliorer les bâtiments, vous avez besoin d'<b>ADN</b> <DNAIcon /> pouvant être obtenu en transmutant les Pokémons obtenus.</p>
                <p>Faites monter votre Pokémon de niveau, découvrez en de nouveau et <b>complètez le Pokédex</b> ! <PokeBallIcon style={{ marginLeft: '5px' }} /></p>
                <p>Bonne chance, jeune dresseur !</p>
            </div>
        </Modal>
    );
};

export default NewPlayerModal;
