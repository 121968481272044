import { Checkbox, Modal, Row, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import React, { useState } from 'react';
import { getCookie, setCookie } from 'react-use-cookie';
import FlatModalButton from './newUI/FlatModalButton';
import { LinkedinFilled } from "@ant-design/icons";
import { NUM_VERSION } from '../utils/numVersion';
import PillsButton from './newUI/PillsButton';
import { TaskService } from '../services/TaskService';
import { removeUser } from '../state/action-creators';

type MusicSettingsPopupProps = {
    onClose: () => void;
};

const MusicSettingsPopup: React.FC<MusicSettingsPopupProps> = ({ onClose }) => {

    const taskService = new TaskService();

    const [isLoaded, setIsLoaded] = useState(false);
    const [checked, setChecked] = useState(false);
    const [defaultMusic, setDefaultMusic] = useState('auto');
    const [resetClicked, setResetClicked] = useState(false);

    if (!isLoaded) {
        const cookieMusicSettings = getCookie('pokeascenttokensettings');
        if (cookieMusicSettings) {
            const musicSettings = JSON.parse(cookieMusicSettings);
            setIsLoaded(true);
            setChecked(musicSettings.playOnStart);
            setDefaultMusic(musicSettings.defaultMusic);
        }
        else {
            setIsLoaded(true);
        }
    }

    const onChange = (e: CheckboxChangeEvent) => {
        setChecked(e.target.checked);
    };

    const handleChange = (value: string) => {
        setDefaultMusic(value);
    };


    const onSave = () => {
        const musicSettings = {
            playOnStart: checked,
            defaultMusic: defaultMusic
        }
        setCookie('pokeascenttokensettings', JSON.stringify(musicSettings), { days: 7, SameSite: 'Strict', Secure: true, path: '/' });
        onClose();
    }

    const resetAccount = () => {
        taskService.resetUser().then(response => {
           removeUser();
        setCookie('pokeascenttoken', '', { days: 0 });
        window.location.href = `/`;
        });
    }

    const label = `${checked ? 'Play' : 'Pause'}`;

    return (
        <Modal
            title="Paramètres"
            open={true}
            okText="Sauvegarder"
            cancelText="Annuler"
            onCancel={onClose}
            onOk={onSave}
            footer={[
                <FlatModalButton
                    key="back"
                    label={'Annuler'}
                    onClick={onClose}
                />,
                <FlatModalButton
                    key="submit"
                    label={'Sauvegarder'}
                    onClick={onSave}
                />
            ]}
        >
            <div className="ant-row .ant-form-item.aligin-center fill">
                <div style={{ paddingTop: '15px' }}>
                    <span style={{ fontSize: '16px', fontWeight: 600, textAlign: 'left', paddingRight: '10px' }}>Musique</span>
                    <Row>
                        {/*Musique sur play ou pause par défaut :*/}
                        <span style={{ fontSize: '14px', textAlign: 'left', paddingRight: '10px', color: '#e8eddc' }}><b>Play</b> ou <b>Pause</b> par défaut : </span>
                        <Checkbox checked={checked} onChange={onChange} defaultChecked={checked} style={{ color: '#e8eddc', marginBottom: '5px' }}>
                            {label}
                        </Checkbox>
                        <span style={{ fontSize: '14px', textAlign: 'left', paddingRight: '10px' }}>Sélection de la musique par défaut :</span>
                        <Select
                            defaultValue={defaultMusic}
                            style={{ width: 150 }}
                            onChange={handleChange}
                            options={[
                                { value: 'battle', label: 'Combat' },
                                { value: 'chill', label: 'Chill' }
                            ]}
                        />
                    </Row>
                </div>
                <div style={{ paddingTop: '15px' }}>
                    <span style={{ fontSize: '16px', fontWeight: 600, textAlign: 'left', paddingRight: '10px' }}>Compte</span>
                    <Row style={{ marginTop: '5px' }}>
                        {!resetClicked ?
                            <PillsButton label="Réinitialiser mon compte" onClick={() => setResetClicked(true)} icon={undefined} />
                            :
                            <>
                                <span style={{ fontSize: '14px', textAlign: 'left', paddingRight: '10px', color: '#e8eddc', marginBottom: '5px' }}>Réinitialiser votre compte supprimera toutes vos données et vous déconnectera. Êtes-vous sûr de vouloir continuer ?</span>
                                <PillsButton label="Non" onClick={() => setResetClicked(false)} icon={undefined} />
                                <PillsButton label="Oui" onClick={() => resetAccount()} icon={undefined} style={{ marginLeft: '10px', backgroundColor: '#8b0000', borderColor: '#8b0000', color: '#fffde9' }} />
                            </>
                        }
                    </Row>
                </div>
                {/* Div avec informations à propos, numéro de version, développeur, etc */}
                <div style={{ paddingTop: '15px' }}>
                    <span style={{ fontSize: '16px', fontWeight: 600, textAlign: 'left', paddingRight: '10px' }}>À propos</span>
                    <Row>
                        <span style={{ fontSize: '14px', textAlign: 'left', paddingRight: '10px', color: '#e8eddc' }}>Version : {NUM_VERSION}</span>
                    </Row>
                    <Row>
                        <span style={{ fontSize: '14px', textAlign: 'left', paddingRight: '10px', color: '#e8eddc' }}>Groupe : <b> Chaloon Inc.</b></span>
                    </Row>
                    <Row>
                        <span style={{ fontSize: '14px', textAlign: 'left', paddingRight: '10px', color: '#e8eddc' }}>Développeurs :<br /> <a style={{ textDecoration: 'underline', color: '#fdfee5' }} href="https://ch.linkedin.com/in/david-ghesquiere">David G. (Front - Back) <LinkedinFilled style={{ marginLeft: '5px' }} /></a> - <a style={{ textDecoration: 'underline', color: '#fdfee5' }} href="https://www.linkedin.com/in/florian-poinsot">Florian P. (Front) <LinkedinFilled style={{ marginLeft: '5px' }} /></a></span>
                    </Row>
                </div>
            </div>
        </Modal>
    );
};

export default MusicSettingsPopup;
