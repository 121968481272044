import React from 'react';

interface PillsButtonProps {
    icon: React.ReactNode; // Accepte n'importe quel élément React comme icône
    onClick: () => void; // Fonction à exécuter lorsque le bouton est cliqué
    style?: React.CSSProperties; // Styles supplémentaires pour le bouton
    iconPosition?: 'left' | 'right';
    label: string;
    className?: string;
}

const PillsButton: React.FC<PillsButtonProps> = ({ label = '10000', icon, onClick, style, iconPosition = 'left', className }) => {

    const iconStyle: React.CSSProperties = {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '30px',
        height: 'auto',
        marginRight: iconPosition === 'left' ? label.length > 0 ? '5px' : '0' : '0',
        marginLeft: iconPosition === 'right' ? label.length > 0 ? '5px' : '0' : '0',
    };

    return (
        <button
            className={className}
            onClick={onClick}
            style={{
                minWidth: '60px',
                width: 'auto',
                height: '30px',
                borderRadius: '20px',
                backgroundColor: '#fffde9',
                color: 'black',
                border: '4px solid #fffde9', // Bordure extérieure blanche
                outline: 'none',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: `0 0 0 2px black`, // Bordure noire
                position: 'relative',
                fontSize: '1rem',
                padding: '0px 10px 0px 10px',
                fontFamily: 'Roboto, sans-serif',
                fontWeight: 'bold',
                ...style, // Applique les styles supplémentaires
            }}
        >
            {icon && iconPosition === 'left' && <span className="icon" style={iconStyle}>{icon}</span>}
            {label}
            {icon && iconPosition === 'right' && <span className="icon" style={iconStyle}>{icon}</span>}
        </button>
    );
};

export default PillsButton;
