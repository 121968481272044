import React, { useState } from 'react';
import PokemonLives from './PokemonLives';
import './OverlayImageStyles.css';
import PillsButton from './PillsButton';
import RareCandy from '../../assets/images/rare-candy.png';
import { DNAIcon, HeartIcon, ShieldIcon, ShieldMagicalIcon, ShoeIcon, SwordSimpleIcon, ThunderIcon } from '../SVGIcons';
import RetroTextBox from './RetroTextBox';
import PokemonRank from '../PokemonRank';
import { Modal, Input, Badge, Card } from 'antd';
import DiamondButton from './DiamondButton';
import FlatModalButton from './FlatModalButton';
import { DeleteOutlined } from '@ant-design/icons';
import './OverlayEquipment.css';
import { TaskService } from '../../services/TaskService';
import { getStats } from '../../services/UserPokemonService';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state';
import { useDispatch } from 'react-redux';

interface OverlayImageProps {
  backgroundImage: string; // URL de l'image de fond
  overlayImage: string; // URL de l'image à superposer
  style?: React.CSSProperties
  lives: number;
  money: number;
  dna: number;
  dialogObject?: {
    npcName?: string;
    text: any;
    isVisible: boolean;
    hideAfter?: number; // Temps en millisecondes
    onHideAfter?: () => void;
  },
  userPokemonWithStats?: any;
  userProfile?: any;
  updateUser: (userProfile: any) => void;
}

const OverlayImage: React.FC<OverlayImageProps> = ({ updateUser, userProfile, userPokemonWithStats, dialogObject = { onHideAfter: () => { }, npcName: 'Pr. Chen', text: 'Salut !', isVisible: false, hideAfter: 10000 }, backgroundImage, overlayImage, style, lives, money, dna }) => {

  const [visible, setVisible] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(-1); // Identifie le slot sélectionné
  const [selectedEquipment, setSelectedEquipment] = useState(() => {
    // Initialisation de selectedEquipment
    const initialEquipment = userProfile.equipments.slice(0, 3); // Prend les 3 premiers éléments de userProfile.equipments
    while (initialEquipment.length < 3) {
      initialEquipment.push(null); // Complète avec null jusqu'à ce que la longueur atteigne 3
    }
    return initialEquipment;
  });
  const [searchText, setSearchText] = useState('');
  const [inItemSelection] = useState(true);

  const taskService = new TaskService();

  const dispatch = useDispatch();
  const { setPokemonPrincipal } = bindActionCreators(actionCreators, dispatch);

  type StatKey = 'hp' | 'attack' | 'defense' | 'special_attack' | 'special_defense' | 'speed';

  const statsConfig: Record<StatKey, { icon: JSX.Element; color: string }> = {
    hp: { icon: <HeartIcon />, color: 'darkred' },
    attack: { icon: <SwordSimpleIcon />, color: '#ffbe5c' },
    defense: { icon: <ShieldIcon />, color: '#fe907a' },
    special_attack: { icon: <ThunderIcon />, color: 'purple' },
    special_defense: { icon: <ShieldMagicalIcon />, color: '#b16455' },
    speed: { icon: <ShoeIcon />, color: '#5c8df3' },
    // Assurez-vous que ces composants d'icônes existent et sont importés
  };

  const showModal = (slotIndex: any) => {
    setSelectedSlot(slotIndex);
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleEquipmentSelection = (equipment: any) => {
    const updatedSelection = [...selectedEquipment];
    updatedSelection[selectedSlot] = equipment; // Met à jour l'équipement pour le slot sélectionné
    setSelectedEquipment(updatedSelection);
    setVisible(false); // Ferme la modal après la sélection
    let equipments: any = [];
    // Update equipments with only _id
    updatedSelection.forEach((equipment: any) => {
      if (equipment) {
        equipments.push(equipment._id);
      }
    });
    updateEquipments(equipments);
  };

  const handleRemoveEquipment = (slotIndex: any) => {
    const updatedSelection = [...selectedEquipment];
    updatedSelection[slotIndex] = null; // Supprime l'équipement du slot sélectionné
    setSelectedEquipment(updatedSelection);
    let equipments: any = [];
    // Update equipments with only _id
    updatedSelection.forEach((equipment: any) => {
      if (equipment) {
        equipments.push(equipment._id);
      }
    });
    updateEquipments(equipments);
  };

  const updateEquipments = async (equipments: any) => {
    await taskService.updateUserEquipments(equipments).then((res) => {
      if (res) {
        updateUser(res);
        setPokemonPrincipal(getStats(userPokemonWithStats, res));
      }
    });
  };

  const filteredInventory = userProfile.inventory
    .filter((item: any) => {
      return (
        item.const === 'held-items' &&
        item.quantity > 0 &&
        !userProfile.equipments.some((selectedItem : any) => selectedItem ? selectedItem.item_ID === item.item_ID : null) &&
        item.item_name.toLowerCase().includes(searchText.toLowerCase())
      );
    });

  const createImageSrc = (item: any) => {
    if (!item) return
    if (!item.img) return
    if (!item.img.data) return
    const buffer = Buffer.from(item.img.data)
    const base64String = buffer.toString('base64')
    const src = `data:images/png;base64,${base64String}`
    return src
  }

  const overlayEquipmentStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column', // Empile les éléments verticalement
    alignItems: 'flex-end', // Alignement à droite
    // Assurez-vous que ce conteneur a une largeur ou est dans un conteneur avec une largeur définie pour que l'alignement à droite soit effectif.
  };

  // Avant de retourner le JSX, définissez le style pour equipmentSlotContainer si nécessaire
  const equipmentSlotContainerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column', // Empile les éléments verticalement
    alignItems: 'flex-start', // Alignement initial des éléments à l'intérieur de chaque slot
    marginBottom: '8px', // Espace entre chaque slot
  };

  const grayStyle = lives === 0 ? { filter: 'grayscale(100%)' } : {};

  return (
    <div style={{
      position: 'relative',
      width: '100%',
      paddingTop: '50%',
      height: '0',
      overflow: 'hidden',
      borderColor: 'black',
      borderWidth: '2px',
      borderStyle: 'solid',
      ...style
    }}>
      <img
        src={backgroundImage}
        alt="Background"
        style={{
          position: 'absolute',
          bottom: '0', // Aligner l'image de fond vers le bas du conteneur
          left: '0',
          width: '100%', // Assurez-vous que l'image couvre le conteneur
          height: 'auto', // Hauteur automatique pour éviter la déformation
          objectFit: 'cover', // Couvre le conteneur sans déformation
          objectPosition: 'bottom', // Assurez-vous que le bas de l'image est visible
          ...grayStyle
        }}
      />
      <img
        src={overlayImage}
        alt="Pokémon partenaire"
        style={{
          position: 'absolute',
          bottom: '5%',
          left: '35%',
          width: 'auto', // Ajustez la largeur à 'auto' pour éviter la déformation
          height: '55%', // Ajustez la hauteur pour que le Pokémon s'intègre mieux
          ...grayStyle
        }}
        className='breathe-animation'
      />
      <RetroTextBox
        npcName={dialogObject.npcName}
        text={dialogObject.text}
        isVisible={dialogObject.isVisible}
        hideAfter={dialogObject.hideAfter} // Masquer après 5000 millisecondes (5 secondes)
        onHideAfter={dialogObject.onHideAfter}
      />
      <PokemonLives lives={lives} style={{
        position: 'absolute',
        top: '1.9%',
        left: '1%',
      }} />
      <PokemonRank userPokemonWithStats={userPokemonWithStats} style={{
        position: 'absolute',
        bottom: '1.9%',
        left: '1%',
      }} userProfile={userProfile} />
      <div style={{
        position: 'absolute',
        top: '3%',
        right: '1%',
        display: 'flex',
        flexDirection: 'row',
      }}>
        <PillsButton
          label={new Intl.NumberFormat().format(money)}
          icon={<img src={RareCandy} alt='money' />}
          onClick={() => { }}
          style={{
            marginRight: '8px', // Ajoutez une marge à droite
            borderRadius: '3px',
            backgroundColor: 'rgba(255, 253, 233,0.5)',
            border: '4px solid rgba(255, 253, 233,0.1)'
          }}
          iconPosition='right'
        />
        <PillsButton
          label={new Intl.NumberFormat().format(dna)}
          icon={<DNAIcon />}
          onClick={() => { }}
          style={{
            borderRadius: '3px',
            backgroundColor: 'rgba(255, 253, 233,0.5)',
            border: '4px solid rgba(255, 253, 233,0.1)'
          }}
          iconPosition='right'
        />
      </div>
      <div style={overlayEquipmentStyle} className="overlayEquipment">
        {selectedEquipment.map((equipment: any, index: any) => (
          <div key={index} style={equipmentSlotContainerStyle}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <PillsButton
                icon={<DeleteOutlined style={{ fontSize: '15px' }} />}
                onClick={() => handleRemoveEquipment(index)}
                style={{ padding: '0px', minWidth: '3px', marginRight: '6px', visibility: equipment ? 'visible' : 'hidden' }}
                label={''}
              />
              <div className="equipmentSlot" onClick={() => showModal(index)}>
                {equipment && <img src={createImageSrc(equipment)} alt={equipment.item_name} style={{ width: '100%', height: '100%' }} />}
              </div>
            </div>
          </div>
        ))}
      </div>

      <Modal
        title="Choisissez votre équipement"
        open={visible}
        onCancel={handleCancel}
        footer={[
          <FlatModalButton
            key="back"
            label="Annuler"
            onClick={handleCancel}
          />]}
      >
        {inItemSelection && (<>
          <Input.Search
            placeholder="Nom de l'objet"
            onChange={(e) => setSearchText(e.target.value)}
            style={{ marginBottom: '15px' }}
            size='large'
          />

          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px', textAlign: 'center', justifyContent: 'center', maxHeight: '35vh', overflowY: 'auto' }}>
            {filteredInventory.map((item: any) => (
              <div
                key={item.item_name}
                style={{
                  marginTop: '10px',
                  border: '3px solid transparent',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
                onClick={() => handleEquipmentSelection(item)}
              >
                <Badge count={item.quantity} style={{ backgroundColor: '#02133b' }} overflowCount={99}>
                  <Card style={{ width: 120, backgroundColor: '#e8eddc', borderRadius: '3px' }} size='small'>
                    <div style={{ textAlign: 'center', fontWeight: '600' }}>{item.item_name}</div>
                    <img src={createImageSrc(item)} alt={item.item_name} style={{ width: 'auto', height: '30px', objectFit: 'cover', margin: 'auto', display: 'block' }} />
                    {item.effect && item.effect.length > 0 && item.effect.map((effect: any, index: any) => (
                      index % 2 === 0 ? (
                        typeof effect === 'string' && effect in statsConfig ? (
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px 2px 2px 2px' }}>
                            <DiamondButton
                              icon={statsConfig[effect as StatKey].icon}
                              color={statsConfig[effect as StatKey].color}
                              iconColor='#fffbd3'
                              onClick={() => { }}
                            />
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                              <span style={{ color: item.effect[index + 1] < 0 ? '#021239' : '#7DCE82', fontWeight: 'bold' }}>{item.effect[index + 1]}</span>
                            </div>
                          </div>
                        ) : null
                      ) : null
                    ))}
                  </Card>
                </Badge>
              </div>
            ))
            }
          </div> </>)}
      </Modal>
    </div>
  );
};

export default OverlayImage;
