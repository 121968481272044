import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import RareCandy from '../../assets/images/rare-candy.png';
import { State } from '../../state';
import backgroundFight1 from './../../assets/images/background-fight-1.png';
import NavigationBar from './NavigationBar';
import OverlayBattle from './OverlayBattle';
import TimelineElement from './TimelineElement';


import FlatButton from './FlatButton';
import OverlayLoading from './OverlayLoading';
import { BossFightsService } from '../../services/BossFightsService';
import './BattleElement.css';

interface WalkProps {
    updateUser: (userProfile: any) => void;
    userProfile: any;
}

let fightObject: any = {
    walk: {},
    enemyImage: "",
    fightBegin: false,
    message: "",
    type: "",
    bossId: "",
    _id: ""
}

const BossElement: React.FC<WalkProps> = ({ updateUser, userProfile }) => {
    const navigate = useNavigate();

    const [timelineItems, setTimelineItems] = useState<any[]>([]); // State to hold timeline items
    const lastTimelineElementRef = useRef<HTMLDivElement>(null); // Référence pour le dernier élément de la timeline
    const [enemyHP, setEnemyHP] = useState(0);
    const [userPokemonHP, setUserPokemonHP] = useState(0);

    const [fightObjectState, setFightObjectState] = useState<any>({
        walk: {},
        enemyImage: "",
        fightBegin: false,
        message: "",
        type: "",
        bossId: "",
        _id: ""
    });

    const [userPokemonIsAttacking, setUserPokemonIsAttacking] = useState(false);
    const [enemyPokemonIsAttacking, setEnemyPokemonIsAttacking] = useState(false);

    // Fonction pour déclencher l'animation
    const triggerAttackAnimation = (attackerId: any) => {
        if (attackerId === userPokemonWithStats._id) {
            setUserPokemonIsAttacking(true);
            setTimeout(() => setUserPokemonIsAttacking(false), 800); // Durée de l'animation
        } else {
            setEnemyPokemonIsAttacking(true);
            setTimeout(() => setEnemyPokemonIsAttacking(false), 800); // Durée de l'animation
        }
    };

    const backgroundFightTab = [backgroundFight1];
    const backgroundFight = backgroundFightTab[Math.floor(Math.random() * backgroundFightTab.length)];

    const user = useSelector((state: State) => state.user);
    const userPokemonWithStats = useSelector((state: State) => state.userPokemon);
    const pokemonImageUrl = 'https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/202.png';
    const buffer = Buffer.from(userPokemonWithStats.img_front.data);
    const base64String = buffer.toString('base64');
    const pokemonImage = `data:images/png;base64,${base64String}` || pokemonImageUrl;

    const [dialogCommand, setDialogCommand] = useState<any>(null);

    const timelineContainerRef = useRef<HTMLDivElement>(null);

    const bossFightsService = new BossFightsService()

    const handleDialogCommand = (command: string) => {
        setDialogCommand(command);
    };

    const launchWalkAction = async (user: any, bossId: any) => {
        try {
            if (!fightObject.fightBegin) {
                const response = await bossFightsService.launchBossFight(user, bossId);
                if (response.type === 'error') {
                    return false;
                }
                fightObject = { ...response, fightBegin: true, bossId: bossId, _id: response.userBossDamage._id, level: response.boss.level };
            }

            setTimeout(() => {
                if (fightObject.type === "MORT") {
                    setTimeout(() => {
                        navigate('/');
                    }, 2000);
                }
                else {
                    if (fightObject.type === "BOSS") {
                        const base64StringEnnemy = fightObject.userBossDamage.fightObject.enemyPokemon.img_front.data.toString('base64');
                        setFightObjectState({
                            walk: fightObject.userBossDamage.fightObject,
                            enemyImage: `data:images/png;base64,${base64StringEnnemy}`,
                            fightBegin: true,
                            message: fightObject.message,
                            type: fightObject.type,
                            bossId: fightObject.bossId,
                            _id: fightObject._id,
                            level: fightObject.level
                        });
                        setUserPokemonHP(userPokemonWithStats.stats.hp);
                        setEnemyHP(fightObject.boss.currentHealth);
                        setTimelineItems(prevItems => []);
                        // Generate timeline items with delay
                        fightObject.userBossDamage.fightObject.sequence.forEach((item: any, index: number) => {
                            const delay = (index + 1) * 1200; // Delay in milliseconds (1 second delay between items)
                            setTimeout(async () => {
                                const color = item.attacker._id === userPokemonWithStats._id ? 'green' : 'red';
                                const attacker = item.attacker.pokemon_name;
                                const move = item.move.move_name;
                                const defenser = item.defenser.pokemon_name;
                                const damage = item.damage;

                                updateHP(item.defenser._id, item.damage);

                                let timelineItem = (
                                    <div ref={lastTimelineElementRef}>
                                        <TimelineElement mode='item' key={index + "" + item.move.move_ID} color={color} attacker={attacker} defenser={defenser} move={move} damage={damage} type={item.move.type} efficientMultiplier={item.efficientMultiplier} />
                                    </div>
                                );

                                triggerAttackAnimation(item.attacker._id);

                                setTimelineItems(prevItems => [...prevItems, timelineItem]);
                            }, delay);
                        });
                    }
                }
            }, 2000);
        } catch (error) {
            throw error;
        }
    };

    const endWalkAction = async (walk: any, user: any) => {
        try {
            const walkDone = { ...walk.walk, fightState: "Done", _id: walk._id, bossId: walk.bossId };
            const response = await bossFightsService.checkEndUserBossDamage(user, walkDone.bossId, walkDone);
            if (response) {
                updateUser(response.user);
                navigate('/battle-menu');
                fightObject = {
                    walk: {},
                    enemyImage: "",
                    fightBegin: false,
                    message: "",
                    type: "",
                    bossId: "",
                    _id: ""
                }
            }
        } catch (error) {
        }
    }

    const updateHP = (defenderId: any, damage: number) => {
        // Check if defenderId or fightObjectState.walk.enemyPokemon._id exists
        if (!defenderId || !fightObject.userBossDamage.fightObject.enemyPokemon)
            return;
        // Update enemy's Pokemon HP
        if (defenderId === fightObject.userBossDamage.fightObject.enemyPokemon._id) {
            setEnemyHP((prevHP: any) => Math.max(0, prevHP - damage));
        }

        // Update user's Pokemon HP
        if (defenderId === userPokemonWithStats._id) {
            setUserPokemonHP(prevHP => Math.max(0, prevHP - damage));
        }
    };

    const location = useLocation();

    useEffect(() => {
        if (!fightObjectState.fightBegin) {
            fightObject = {
                walk: {},
                enemyImage: "",
                fightBegin: false,
                message: "",
                type: "",
                bossId: "",
                _id: ""
            };
        }

        if (!fightObject.fightBegin) {
            const myQuery = new URLSearchParams(location.search).get("id");
            launchWalkAction(user, myQuery);
        }
    }, [fightObject.fightBegin])

    const centerStyle: React.CSSProperties = {
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        zIndex: 10,
    };

    const endElementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const container = timelineContainerRef.current;
        const lastItem = container?.lastChild as HTMLElement;

        if (lastItem) {
            lastItem.scrollIntoView({ behavior: "smooth" });
        }
        //lastTimelineElementRef.current?.scrollIntoView({ behavior: "smooth" });
        // Check if sequence in walk exist
        if (!fightObjectState.walk.sequence)
            return;

        if (fightObjectState.walk.sequence.length > 0 && fightObjectState.walk.sequence.length === timelineItems.length) {
            if (endElementRef.current) {
                endElementRef.current.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [timelineItems, fightObjectState.walk.sequence]); // Dépendances incluant la longueur de la séquence de combat

    const timelineElements = timelineItems.map((item, index) => {
        if (index === timelineItems.length - 1) { // Dernier élément
            return React.cloneElement(item, { ref: lastTimelineElementRef });
        }
        return item;
    });

    return (
        <>
            {fightObjectState.fightBegin && fightObject.type === "BOSS" ?
                <>
                    <OverlayBattle
                        backgroundImage={backgroundFight}
                        userPokemonImage={pokemonImage}
                        dialogObject={{
                            npcName: userPokemonWithStats.pokemon_name,
                            text: dialogCommand ? <> J'ai volé à {fightObjectState.walk.enemyPokemon.pokemon_name} <b>{new Intl.NumberFormat().format(Number(dialogCommand))}</b><img src={RareCandy} alt="money" /> !</> : <>Texte par défaut</>,
                            isVisible: dialogCommand !== null,
                            hideAfter: 10000,
                            onHideAfter: () => setDialogCommand(null)
                        }}
                        enemyImage={fightObjectState.enemyImage}
                        enemyHP={enemyHP}
                        enemyHPMax={fightObjectState.walk.enemyPokemon.stats.hp}
                        userPokemonHP={userPokemonHP}
                        userPokemonHPMax={userPokemonWithStats.stats.hp}
                        userPokemonName={userPokemonWithStats.pokemon_name}
                        userPokemonLevel={userPokemonWithStats.stats.level}
                        enemyPokemonName={fightObjectState.walk.enemyPokemon.pokemon_name}
                        enemyPokemonLevel={fightObjectState.walk.enemyPokemon.stats.level}
                        typeFight={fightObjectState.type}
                        formatHP={'percent'}
                        userPokemonImageClassName={userPokemonIsAttacking ? 'pokemon-attack-animation' : ''}
                        enemyPokemonImageClassName={enemyPokemonIsAttacking ? 'pokemon-attack-animation-enemy' : ''}
                    />
                    <div className="row-history" ref={timelineContainerRef}>
                        <TimelineElement mode='begin' />
                        {timelineElements}
                        {fightObjectState.walk.sequence.length === timelineItems.length &&
                            (
                                <>
                                    {fightObjectState.walk.victory ?
                                        <TimelineElement mode='victory' money={fightObjectState.walk.gainMonney} exp={fightObjectState.walk.gainExp} />
                                        :
                                        <TimelineElement mode='defeat' />
                                    }
                                    <div ref={endElementRef}>
                                        <TimelineElement mode='end' />
                                    </div>
                                </>
                            )}
                    </div>
                    {fightObjectState.walk.sequence.length === timelineItems.length &&
                        <div style={{ display: 'flex', justifyContent: 'center', position: 'fixed', bottom: '90px', width: '100%', margin: 'auto', left: '0', right: '0' }}>
                            <FlatButton color='#fd9779' onClick={() => endWalkAction(fightObjectState, user)} label={'Quitter'} style={{ margin: '5px' }} />
                        </div>
                    }
                </>
                :
                <>
                    <OverlayLoading
                        backgroundImage={0 + (Math.random() * 100) > 50 ? "images/loading1.gif" : "images/loading2.gif"}
                        dialogObject={{
                            npcName: userPokemonWithStats.pokemon_name,
                            text: dialogCommand ? <> Tiens, il y avait <b>{new Intl.NumberFormat().format(Number(dialogCommand))}</b><img src={RareCandy} alt="money" /> ici !</> : <>Texte par défaut</>,
                            isVisible: dialogCommand !== null,
                            hideAfter: 10000,
                            onHideAfter: () => setDialogCommand(null)
                        }}
                    />
                </>
            }
            <NavigationBar updateUser={updateUser} onButtonMonneyClick={handleDialogCommand} userProfile={userProfile} style={centerStyle} />
        </>
    );
};

export default BossElement;
