import React, { useEffect, useState } from 'react';
import { Form, Select, InputNumber, Button, Divider, Input, Checkbox } from 'antd'
import { TaskService } from '../services/TaskService';
import { UserPokemonService } from '../services/UserPokemonService';

const ManageUser: React.FC = () => {
    const taskService = new TaskService();
    // const bossFightsService = new BossFightsService();
    const userPokemonService = new UserPokemonService();

    const [users, setUsers] = useState<any[]>([]);
    const [selectedUser, setSelectedUser] = useState<any | null>(null);
    // const [bossFight, setBossFight] = useState<any[]>([]);
    const [userPokemons, setUserPokemons] = useState<any[]>([]);

    const { Option } = Select;

    const handleUserSelect = (userId: string) => {
        const user = users.find(user => user.userId === userId);
        setSelectedUser(user);
        getAllPokemon(user);
        setTimeout(() => {
            form.resetFields();
        }, 100);
    };

    useEffect(() => {
        getProperties();
    }, [])

    const handleSave = () => {
        if (selectedUser) {
            form.validateFields().then(values => {
                const updatedUser: any = {
                    ...users.find(user => user.userId === selectedUser.userId),
                    ...values,
                };
                taskService.updateAdminUser(selectedUser, updatedUser).then(response => {
                    if (response.success) {
                        getProperties();
                    }
                });
                //onUpdateUser(updatedUser);
            });
        }
    };

    const [form] = Form.useForm();

    const getProperties = () => {
        taskService.getUsers().then(response => {
            if (response.success) {
                setUsers(response.users);
            }
        });
        // bossFightsService.getBossFights().then(response => {
        //     if (response) {
        //         setBossFight(response)
        //     }
        // });
    }

    const getAllPokemon = (user: any) => {
        userPokemonService.getAllUserPokemon(user).then(response => {
            if (response) {
                setUserPokemons(response)
            }
        })
    }

    const calculLevel = (pokemon: any) => {
        const level = Math.floor(Math.pow(pokemon.experience, 1 / 3));
        return level;
    }

    return (
        <div>
            <h2>Edit User Properties</h2>
            <Select showSearch
                placeholder="Choisir un utilisateur"
                style={{ width: 200 }}
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleUserSelect}>
                {/* Add options for users */
                    users.map(user => (
                        <Option key={user.username} value={user.userId}>
                            {user.username}
                        </Option>
                    ))
                }
            </Select>
            <Divider />
            {selectedUser && (
                <Form form={form} initialValues={selectedUser} layout="vertical">
                    <Form.Item label="Pseudo" name="username">
                        <Input style={{ width: 300 }} />
                    </Form.Item>
                    <Form.Item label="Nouveau joueur" name="userIsNew" valuePropName="checked">
                        <Checkbox />
                    </Form.Item>
                    <Form.Item label="Money" name="money">
                        <InputNumber style={{ width: 200 }} min={0} />
                    </Form.Item>
                    <Form.Item label="Pokémon principal" name="pokemon_principal_id">
                        <Select
                            showSearch
                            placeholder="Choisir un Pokémon"
                            style={{ width: 200 }}
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {/* Add options for items */
                                userPokemons
                                    .sort((a: any, b: any) => a.pokemon_name.localeCompare(b.pokemon_name))
                                    .map((pokemon: any) => (
                                        <Option key={pokemon._id} value={pokemon._id}>
                                            {`${pokemon.pokemon_name} - Niv. ${calculLevel(pokemon)}`}
                                        </Option>
                                    ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={handleSave}>
                            Enregistrer
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </div>
    );

};

export default ManageUser;
