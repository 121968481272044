// PokemonMoveSection.tsx
import { Row, message } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import User from '../../interface/User';
import { TaskService } from '../../services/TaskService';
import { UserPokemonService, getStats } from '../../services/UserPokemonService';
import { actionCreators } from '../../state';
import PokemonMoveCard from './PokemonMoveCard';
import './PokemonMoveSection.css';

type PokemonMoveSectionProps = {
    userPokemonWithStats: any;
    updateUser: (userProfile: any) => void;
    userProfile: any;
};

const PokemonMoveSection: React.FC<PokemonMoveSectionProps> = ({ userPokemonWithStats, updateUser, userProfile }) => {
    const messageTopMargin = '36px';
    const dispatch = useDispatch();
    const { setPokemonPrincipal } = bindActionCreators(
        actionCreators,
        dispatch,
    );

    const taskService = new TaskService();

    const userPokemonService = new UserPokemonService();

    const changeUserPokemonMove = (moveId: Number) => {
        userPokemonService
            .changeUserPokemonMove(userPokemonWithStats, moveId)
            .then((response : any) => {
                if (!response.success) {
                    message.open({
                        type: 'error',
                        content: response.message,
                        duration: 2,
                        key: 1, // Utilisez la clé unique
                        style: {
                          marginTop: messageTopMargin, // Ajustez la position verticale
                        },
                      });
                    return
                }
                const userPokemonWithStats = getStats(response.userPokemon, userProfile)
                setPokemonPrincipal(userPokemonWithStats)
                taskService.getDiscordUserFromCookie().then((updatedUser) => {
                    updateUser(updatedUser)
                })
            })
    }

    const checkNumberEcaille = (user: User) => {
        let enougthEcailleLocal = false
        let itemEcaille = user.inventory.find((item) => item.item_ID === 93)
        if (itemEcaille && itemEcaille.quantity >= 1) {
            enougthEcailleLocal = true
        }

        return { quantity: itemEcaille?.quantity, enougthEcaille: enougthEcailleLocal }
    }

    const renderSpellCards = () => {
        return userPokemonWithStats.movesString.map((move: any) => (
            <div key={move.move_ID.toString()} className="container-spell">
                <PokemonMoveCard
                    move={move}
                    user={userProfile}
                    checkNumberEcaille={checkNumberEcaille}
                    changeUserPokemonMove={changeUserPokemonMove}
                    numberMove={userPokemonWithStats.movesString.length}
                />
            </div>
        ));
    };

    return (
        <Row className="profile-pokemon-spells">
            {renderSpellCards()}
        </Row>
    );
};

export default PokemonMoveSection;
