import React from 'react';

type IconButtonProps = {
  icon: React.ReactNode // URL de l'image ou élément SVG
  onClick: () => void; // Gestionnaire de clic pour le bouton
  style?: React.CSSProperties;
};

const IconButton: React.FC<IconButtonProps> = ({ icon, onClick, style }) => {
  return (
    <button onClick={onClick} style={{ border: 'none', background: 'none', width:'auto', height:'40px', cursor:'pointer', ...style }}>
      {icon}
    </button>
  );
};

export default IconButton;
