import { Avatar } from "antd";
import { GearIcon, LogoIcon } from "../SVGIcons";
import IconButton from "./IconButton";
import CircleButton from "./CircleButton";
import MusicSettingsPopup from "../MusicSettingsPopup";
import { useState } from "react";
import NotificationMenu from "../NotificationMenu";
import AudioPlayer from "../AudioPlayer";
import { TeamOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

interface HeaderProps {
    userId: any;
    avatar: any;
    username: string;
    onSaveMusicSettings: () => void;
    updateUser: (userProfile: any) => void;
    userProfile: any;
    audioSrc: string;
    playOnLoad: boolean;
}

const Header: React.FC<HeaderProps> = ({ userId, avatar, username, onSaveMusicSettings, updateUser, userProfile, audioSrc, playOnLoad }) => {

    const [isMusicSettingsVisible, setIsMusicSettingsVisible] = useState(false);
    const navigate = useNavigate();
    const handleLeftButtonClick = () => {
        
    };

    const handleSettingsButtonClick = () => {
        setIsMusicSettingsVisible(true);
    };

    const titleStyle: React.CSSProperties = {
        fontFamily: 'Roboto', // Cette police imite le style des jeux vidéo rétro.
        color: '#030303', // Couleur bleue, vous pouvez choisir la couleur qui convient à votre design.
        textAlign: 'center',
        fontSize: '1.2rem', // Taille de la police.
        letterSpacing: '1px', // Espacement entre les lettres.
        fontWeight: 600, // Épaisseur de la police.
        margin: '0 10px', // Marge à gauche et à droite.
        marginTop: '5px'
    };


    return (
        <div>
            <header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px', marginTop: '0%' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton icon={<LogoIcon style={{ fontSize: '40px' }} />} onClick={handleLeftButtonClick} style={{ marginLeft: '10px' }} />
                <CircleButton iconColor='#02133b' color='#fffee5' icon={<TeamOutlined />} borderColor="#ff977b" onClick={() => navigate('/friend')} style={{ boxShadow: 'none', borderColor: '#02133b', borderWidth: '2px', width: '35px', height: '35px', marginRight: '10px', marginLeft: '10px' }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <span style={titleStyle}>{username}</span>
                    <Avatar
                        src={userId ? `https://cdn.discordapp.com/avatars/${userId}/${avatar}.webp` : ""}
                        size={30}
                        shape="circle"
                    />
                    <NotificationMenu updateUser={updateUser} userProfile={userProfile} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <AudioPlayer audioSrc={audioSrc} playOnLoad={playOnLoad} />
                    <CircleButton icon={<GearIcon style={{ fontSize: '20px', color: '#02133b', marginLeft: '1px', marginTop: '1px' }} />} borderColor="#ff977b" onClick={handleSettingsButtonClick} style={{ boxShadow: 'none', borderColor: '#02133b', borderWidth: '2px', width: '35px', height: '35px', marginRight: '10px', marginLeft: '10px' }} />
                </div>
            </header>
            {isMusicSettingsVisible && (
                <MusicSettingsPopup
                    onClose={() => {
                        setIsMusicSettingsVisible(false)
                        onSaveMusicSettings();
                    }}
                />)}
        </div>
    );
};

export default Header;
