import React from 'react';
import './OverlayImageStyles.css';
import RetroTextBox from './RetroTextBox';

interface OverlayLoadingProps {
  backgroundImage: string; // URL de l'image de fond
  style?: React.CSSProperties
  dialogObject?: {
    npcName?: string;
    text: any;
    isVisible: boolean;
    hideAfter?: number; // Temps en millisecondes
    onHideAfter?: () => void;
  }
}

const OverlayLoading: React.FC<OverlayLoadingProps> = ({ dialogObject = { onHideAfter: () => {}, npcName: 'Pr. Chen', text: 'Salut !', isVisible: false, hideAfter: 10000 }, backgroundImage, style }) => {

  return (
    <div style={{
      position: 'relative',
      width: '100%',
      paddingTop: '56.25%',
      height: '0',
      overflow: 'hidden',
      borderColor: 'black',
      borderWidth: '2px',
      borderStyle: 'solid',
      backgroundColor:'#4a9cff',
      ...style
    }}>
      <img
        src={backgroundImage}
        alt="Background"
        style={{
          position: 'absolute',
          bottom: '0', // Aligner l'image de fond vers le bas du conteneur
          left: '0',
          width: '100%', // Assurez-vous que l'image couvre le conteneur
          height: 'auto', // Hauteur automatique pour éviter la déformation
          objectFit: 'cover', // Couvre le conteneur sans déformation
          objectPosition: 'bottom', // Assurez-vous que le bas de l'image est visible
        }}
      />
      <RetroTextBox
        npcName={dialogObject.npcName}
        text={dialogObject.text}
        isVisible={dialogObject.isVisible}
        hideAfter={dialogObject.hideAfter} // Masquer après 5000 millisecondes (5 secondes)
        onHideAfter={dialogObject.onHideAfter}
      />
    </div>
  );
};

export default OverlayLoading;
