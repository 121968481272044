import React, { useEffect, useState } from 'react';
import './RetroTextBox.css';

interface RetroTextBoxProps {
  npcName?: string;
  text: any;
  isVisible: boolean;
  hideAfter?: number; // Temps en millisecondes
  style?: React.CSSProperties;
  onHideAfter?: () => void;
}

const RetroTextBox: React.FC<RetroTextBoxProps> = ({ npcName, text, isVisible, hideAfter, style, onHideAfter }) => {
  const [isShown, setIsShown] = useState(isVisible);

  useEffect(() => {
    setIsShown(isVisible);
    if (isVisible && hideAfter) {
      const timer = setTimeout(() => {
        setIsShown(false);
        if (onHideAfter) onHideAfter();
      }, hideAfter);
      return () => clearTimeout(timer);
    }
  }, [isVisible, hideAfter]);

  if (!isShown) return null;

  return (
    <div className="retro-text-box" style={style}>
      {npcName && <p className="npc-name">{npcName}</p>}
      <p>{text}</p>
    </div>
  );
};

export default RetroTextBox;
