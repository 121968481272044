import React from 'react';
import CircleButton from './CircleButton';
import DiamondButton from './DiamondButton';
import { HeartIcon, ShieldIcon, ShieldMagicalIcon, ShoeIcon, SwordSimpleIcon, ThunderIcon } from '../SVGIcons';
import TypeIcon from '../TypeIcon';
import { typeColors } from '../../utils/typeColors';
import PokemonMoveSection from './PokemonMoveSection';
import { Divider, Modal } from 'antd';
import ExperienceBar from './ExperienceBar';
import potionImage from '../../assets/images/icnIcons/potion.png';
import reviveImage from '../../assets/images/icnIcons/revive.png';
import EvolveButton from './EvolveButton';
import PillsButton from './PillsButton';
import HealButton from './HealButton';
import BoostButton from './BoostButton';
import { CaretUpOutlined } from '@ant-design/icons';

interface PokemonStatsCardProps {
  onLeftCircleClick: () => void;
  onRightCircleClick: () => void;
  onDiamondClick: (stat: string) => void;
  userPokemonWithStats: any;
  updateUser: (userProfile: any) => void;
  userProfile: any;
}

const PokemonStatsCard: React.FC<PokemonStatsCardProps> = ({
  onLeftCircleClick,
  onRightCircleClick,
  onDiamondClick,
  userPokemonWithStats,
  updateUser,
  userProfile,
}) => {

  const hasStats = userPokemonWithStats && userPokemonWithStats._id;

  const stats = [
    { icon: <HeartIcon />, color: 'darkred', value: hasStats ? userPokemonWithStats.stats.hp : '370' },
    { icon: <SwordSimpleIcon />, color: '#ffbe5c', value: hasStats ? userPokemonWithStats.stats.attack : '370' },
    { icon: <ShieldIcon />, color: '#fe907a', value: hasStats ? userPokemonWithStats.stats.defense : '78' },
    { icon: <ThunderIcon />, color: 'purple', value: hasStats ? userPokemonWithStats.stats.specialAttack : '370' },
    { icon: <ShieldMagicalIcon />, color: '#b16455', value: hasStats ? userPokemonWithStats.stats.specialDefense : '78' },
    { icon: <ShoeIcon />, color: '#5c8df3', value: hasStats ? userPokemonWithStats.stats.speed : '713' },
  ];

  const statContainerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '10px 7px 7px 7px', // Adjust the vertical margin to align with DiamondButtons
  };

  const statsValueStyle: React.CSSProperties = {
    fontWeight: '600',
    fontSize: '1rem',
    marginTop: '4px', // Adjust this to align vertically as needed
  };

  const cardContainerStyle: React.CSSProperties = {
    position: 'relative', // Position relative pour le conteneur global
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '5px 0px 5px 0px',
    fontFamily: 'Roboto, sans-serif',
    background: '#fffee5',
    borderRadius: '20px 20px 0 0',
    borderColor: 'black',
    borderStyle: 'solid',
    borderWidth: '2px',
    margin: '0px 10px 5px 10px',
    zIndex: 2, // Z-index supérieur pour le conteneur
  };

  // Styles pour le conteneur des boutons Niveau et Boost, positionné absolument
  const levelAndBoostContainerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '-4%', // Ajustez cette valeur pour positionner verticalement selon vos besoins
    left: '-1.5%', // Ajustez cette valeur pour positionner horizontalement selon vos besoins
  };

  // Style pour le bouton Niveau, sans transformations inutiles
  const levelButtonStyle: React.CSSProperties = {
    fontWeight: '600',
    backgroundColor: '#ff967b',
    color: '#fffee5',
  };

  // Style pour le bouton Boost, avec une marge à gauche pour l'espacement
  const boostButtonStyle: React.CSSProperties = {
    marginLeft: '10px', // Espace entre le bouton Niveau et Boost
    fontWeight: '600',
    backgroundColor: '#3c5c89',
    color: '#fffee5',
    display: userProfile.inventory.find((item: any) => (item.const === 'stat-boosts' || item.const === 'vitamins') && item.quantity > 0) ? '' : 'none',
    minWidth: '0px',
    padding: '0px 0px',
  };

  const rightButtonStyle: React.CSSProperties = {
    position: 'absolute',
    right: 0,
    top: 0,
    transform: 'translate(10%, -10%)',
    fontWeight: '600',
    color: '#f8f8f8'
  };
  const healOrReviveButtonStyle: React.CSSProperties = {
    position: 'absolute',
    right: 55,
    top: 0,
    transform: 'translate(10%, -10%)',
    fontWeight: '600',
    color: '#f8f8f8'
  };

  return (
    <div style={cardContainerStyle}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '0 5px',
      }}>
        <div style={levelAndBoostContainerStyle}>
          <PillsButton style={levelButtonStyle} label={`Niv. ${userPokemonWithStats.stats.level}`} onClick={onLeftCircleClick} icon={undefined} />
          <BoostButton style={boostButtonStyle} label={''} icon={<CaretUpOutlined />} updateUser={updateUser} userProfile={userProfile} />
        </div>

        {/* <CircleButton icon={`${userPokemonWithStats.stats.level}`} onClick={onLeftCircleClick} style={levelButtonStyle} /> */}
        <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1, justifyContent: 'center', paddingTop:'6px', marginBottom:'-6px' }}>
          <span style={{
            textAlign: 'center',
            fontSize: '1.3rem',
            fontWeight: 'bold',
            marginRight: '10px', // Marge à droite du nom pour les types
            color: '#030303',
          }}>
            {hasStats ? userPokemonWithStats.pokemon_name : 'Qulbutoké'}
          </span>
          {/* Icônes des types */}
          {userPokemonWithStats._id &&
            userPokemonWithStats.types.length >= 1 &&
            userPokemonWithStats.types.map((type: number) => (
              <TypeIcon type={typeColors[type][1]} key={type} />
            ))}
        </div>
        {userPokemonWithStats.life > 0 && userPokemonWithStats.life < 3 &&
          <HealButton color={'#f05b7d'} updateUser={updateUser} userProfile={userProfile} style={healOrReviveButtonStyle} image={<img style={{ width: '30px', height: 'auto' }} src={potionImage} alt='potion' />} />
        }
        {userPokemonWithStats.life === 0 && !userPokemonWithStats.zombie &&
          <HealButton color={'#f05b7d'} updateUser={updateUser} userProfile={userProfile} style={healOrReviveButtonStyle} image={<img style={{ width: '30px', height: 'auto' }} src={reviveImage} alt='revive' />} />
        }
        {'canEvolve' in userPokemonWithStats && userPokemonWithStats.canEvolve && (
          <EvolveButton updateUser={updateUser} userProfile={userProfile} style={rightButtonStyle} />)
        }
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
      }}>
        {stats.map((stat, index) => (
          <div style={statContainerStyle} key={index}>
            <DiamondButton
              icon={stat.icon}
              color={stat.color}
              iconColor='#fffbd3'
              onClick={() => onDiamondClick(stat.value)}
            />
            <span style={statsValueStyle}>{stat.value}</span>
          </div>
        ))}
      </div>
      <ExperienceBar userPokemon={userPokemonWithStats} />
      <Divider style={{ width: '100%', margin: '0px 0 5px 0', borderColor: 'black', borderStyle: 'solid', borderWidth: '1px' }} />
      <PokemonMoveSection
        userPokemonWithStats={userPokemonWithStats}
        updateUser={updateUser}
        userProfile={userProfile}
      />
    </div>
  );
};

export default PokemonStatsCard;
