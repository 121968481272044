import { API_URL } from "./EndpointAPI";
import { setCookie, getCookie } from 'react-use-cookie';

export class BuildingService {
    private redirect() {
        setCookie('pokeascenttoken', '', { days: 0 });
        window.location.href = `/`;
    }

    public async getBuildingsForUser(): Promise<any> {
        const token = getCookie('pokeascenttoken');
        const response = await fetch(`${API_URL}/buildings`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}` } });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async createBuildingElement(): Promise<any> {
        const token = getCookie('pokeascenttoken');
        const response = await fetch(`${API_URL}/buildings/create`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}` } });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async upgradeBuilding(buildingId: string): Promise<any> {
        const token = getCookie('pokeascenttoken');
        const response = await fetch(`${API_URL}/buildings/upgrade`, { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ buildingId }) });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }
}