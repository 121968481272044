import { Button, Card } from 'antd';
import React from 'react';
import { FemaleIcon, MaleIcon } from '../SVGIcons';
import RareCandy from '../../assets/images/rare-candy.png';
import PixelXP from '../../assets/images/xpPixel.png';
import TypeIcon from '../TypeIcon';
import { typeColors } from '../../utils/typeColors';
import StarPiece from '../../assets/images/icnRanks/starpiece.png';
import '../../assets/styles/pokedex.css';

type PokemonItemProps = {
  img: string;
  name: string;
  gender: number;
  style?: React.CSSProperties;
  onClick: () => void;
  mode?: "choose";
  money?: number;
  exp?: number;
  difficulty?: string;
  odds?: number;
  type?: any;
  rank?: number;
  pokedexId?: number;
  isOwned?: boolean;
  evsPotentiel?: any;
  ivsPotentiel?: any;
};

const PokemonItem: React.FC<PokemonItemProps> = ({ ivsPotentiel, evsPotentiel, img, name, style, onClick, gender, mode, money, exp, difficulty, odds, type, rank = 0, pokedexId, isOwned = true }) => {

  const determineGender = (gender: number) => {
    switch (gender) {
      case 0:
        return <FemaleIcon />;
      case 1:
        return <MaleIcon />;
      default:
        return '';
    }
  }

  const StarImage = () => (
    <img
      src={StarPiece}
      alt="star"
      style={{ width: '24px', height: '24px' }}
    />
  );

  const Stars = (count: number) => {
    // If count === 0, return star grayed out
    if (count === 0) {
      return <><img
        src={StarPiece}
        alt="star"
        style={{ width: '24px', height: '24px', filter: 'grayscale(100%)' }}
      /></>;
    } else {
      return <><StarImage key={count} /> <b>x{count}</b></>;
    }
  };

  return (
    <>
      <Card
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center', // Aligner le contenu verticalement
          height: 'auto', // Hauteur de la Card
          backgroundColor: '#fffde9',
          borderStyle: 'solid',
          borderColor: 'black',
          borderWidth: '2px',
          boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.03),0 1px 6px -1px rgba(0, 0, 0, 0.02),0 2px 4px 0 rgba(0, 0, 0, 0.02)',
          cursor: 'pointer',
          minWidth: '100px',
          ...style
        }}
        size="small"
        onClick={onClick}
      >
        <div style={{ textAlign: 'center', fontWeight: '600', color: 'black' }}>{name} {determineGender(gender)}</div>
        <div style={{ textAlign: 'center', fontWeight: '600', color: 'black' }}>{type &&
          type.length >= 1 &&
          type.map((type: number) => (
            <TypeIcon type={typeColors[type][1]} key={type} />
          ))}</div>
        <img
          src={img}
          alt={name}
          style={{ width: 'auto', height: '60px', objectFit: 'cover', margin: 'auto', display: 'block' }}
          className={isOwned ? '' : 'none-img'}
        />
        {rank >= 0 && !odds &&
          <div style={{display:'flex', justifyContent:'center', marginBottom:'5px'}}>
            {Stars(rank)}
          </div>
        }
        {odds &&
          <Button
            type="primary"
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              backgroundColor: '#fd9779', // Couleur de fond du bouton
              borderColor: 'black', // Couleur de la bordure du bouton
              width: 'auto', // Largeur fixe à 80% de la taille de la card
              marginLeft: 'auto', // Centrer
              marginRight: 'auto', // Centrer
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '15px',
              borderStyle: 'solid',
              borderWidth: '2px',
              borderBottomWidth: '4px'
            }}
          >
            <span style={{ marginRight: '0.15em', marginLeft: '0.15em' }}>{odds}%</span>
          </Button>
        }
        {(ivsPotentiel) &&
          <Button
            type="primary"
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              backgroundColor: '#fd9779', // Couleur de fond du bouton
              borderColor: 'black', // Couleur de la bordure du bouton
              width: 'auto', // Largeur fixe à 80% de la taille de la card
              marginLeft: 'auto', // Centrer
              marginRight: 'auto', // Centrer
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '15px',
              borderStyle: 'solid',
              borderWidth: '2px',
              borderBottomWidth: '4px',
              marginBottom: '5px'
            }}
          >
            <span style={{ marginRight: '0.15em', marginLeft: '0.15em', fontSize:'12px' }}>IVs {ivsPotentiel}%</span>
          </Button>
        }
        {(evsPotentiel) &&
          <Button
            type="primary"
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              backgroundColor: '#fd9779', // Couleur de fond du bouton
              borderColor: 'black', // Couleur de la bordure du bouton
              width: 'auto', // Largeur fixe à 80% de la taille de la card
              marginLeft: 'auto', // Centrer
              marginRight: 'auto', // Centrer
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '15px',
              borderStyle: 'solid',
              borderWidth: '2px',
              borderBottomWidth: '4px',
              marginBottom: '3px'
            }}
          >
            <span style={{ marginRight: '0.15em', marginLeft: '0.15em', fontSize:'12px' }}>EVs {evsPotentiel}%</span>
          </Button>
        }
        { pokedexId && <Button
            type="primary"
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              backgroundColor: '#fd9779', // Couleur de fond du bouton
              borderColor: 'black', // Couleur de la bordure du bouton
              width: 'auto', // Largeur fixe à 80% de la taille de la card
              marginLeft: 'auto', // Centrer
              marginRight: 'auto', // Centrer
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '15px',
              borderStyle: 'solid',
              borderWidth: '2px',
              borderBottomWidth: '4px'
            }}
          >
            <span style={{ marginRight: '0.15em', marginLeft: '0.15em' }}>#{pokedexId}</span>
          </Button> }
        {mode === "choose" &&
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span style={{ textAlign: 'center', fontWeight: '600', color: 'black', marginBottom: '2px', marginTop: '3px' }}>{difficulty}</span>
            <div style={{ marginBottom: '2px' }}>
              <span style={{ fontWeight: '600' }}>+{money}
                <img src={RareCandy} alt="money" style={{ marginLeft: '5px' }} />
              </span>
            </div>
            <div>
              <span style={{ fontWeight: '600' }}>+{exp}
                <img src={PixelXP} style={{ width: "25px", height: "auto", marginLeft: '5px' }} alt="exp" />
              </span>
            </div>
          </div>
        }

      </Card>
    </>
  );
};

export default PokemonItem;
