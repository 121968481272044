import { CaretDownFilled, CaretUpFilled, ForwardFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { typeColors } from '../utils/typeColors';
import TypeIcon from './TypeIcon';

type EffectiveIconProps = {
    spellType: number
    efficientMultiplier: number;
    size?: number;
}

const EffectiveIcon: React.FC<EffectiveIconProps> = ({ spellType, efficientMultiplier, size = 22 }) => {

    const generateIcon = () => {
        switch (efficientMultiplier) {
            case 0:
                return (
                    <>
                        <TypeIcon type={typeColors[spellType][1]} size={size} />
                        <Tooltip title="Inefficace">
                            <ForwardFilled style={{ transform: 'rotate(90deg)', color: 'red' }} size={size} />
                        </Tooltip>
                    </>
                );
            case 0.5:
                return (
                    <>
                        <TypeIcon type={typeColors[spellType][1]} size={size} />
                        <Tooltip title="Peu efficace">
                            <CaretDownFilled style={{ color: 'red' }} size={size} />
                        </Tooltip>
                    </>
                );
            case 1:
                return (
                    <>
                        <TypeIcon type={typeColors[spellType][1]} size={size} />
                        <Tooltip title="Efficace">
                            <CaretUpFilled style={{ color: 'black' }} size={size} />
                        </Tooltip>
                    </>
                );
            case 2:
                return (
                    <>
                        <TypeIcon type={typeColors[spellType][1]} size={size} />
                        <Tooltip title="Super efficace">
                            <ForwardFilled style={{ transform: 'rotate(-90deg)', color: 'green' }} size={size} />
                        </Tooltip>
                    </>
                );
        }
    }

    return (
        <>
            {generateIcon()}
        </>
    );
};

export default EffectiveIcon;