import React from 'react';

interface DiamondButtonProps {
  icon: React.ReactNode; // Utilisez ReactNode pour passer un élément comme icône
  color?: string; // Couleur de fond du bouton
  iconColor?: string; // Couleur de l'icône
  onClick: () => void;
}

const DiamondButton: React.FC<DiamondButtonProps> = ({ icon, color, iconColor, onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{
        width: '25px',
        height: '25px',
        backgroundColor: color, // Couleur de fond du bouton
        color: iconColor,
        border: '2px solid #fffae6', // Bordure blanche
        outline: 'none',
        cursor: 'pointer',
        margin: '0px 10px 0px 10px',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'rotate(45deg)', // Rotation de 45 degrés pour l'effet diamant
      }}
    >
      <span style={{
        transform: 'rotate(-45deg)', // Contre-rotation pour que l'icône apparaisse droite
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '13px', // Taille de l'icône
      }}>
        {icon}
      </span>
    </button>
  );
};

export default DiamondButton;
