import { Badge, Card, Input, Modal, Rate, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import "../../assets/styles/evolve.css";
import '../../assets/styles/pokedex.css';
import UserPokemon from '../../interface/UserPokemon';
import { EvolveService } from '../../services/EvolveService';
import { State, actionCreators } from '../../state';
import CircleButton from './CircleButton';
import FlatModalButton from './FlatModalButton';
import { getStats, UserPokemonService } from '../../services/UserPokemonService';
import { HeartIcon } from '../SVGIcons';

type HealPopupProps = {
    updateUser: (userProfile: any) => void;
    userProfile: any;
    image: any;
    color?: string;
    style?: React.CSSProperties;
}

interface Revival {
    item_ID: number;
    item_name: string;
}

const HealButton: React.FC<HealPopupProps> = ({ updateUser, userProfile, style, image, color }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedRevival, setSelectedRevival] = useState<Revival | null>(null);
    const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
    const [searchText, setSearchText] = useState('');
    const [inItemSelection, setInItemSelection] = useState(true);

    const userPokemonService = new UserPokemonService();
    const dispatch = useDispatch()

    const { setPokemonPrincipal } = bindActionCreators(actionCreators, dispatch);
    const userPokemonWithStats: UserPokemon = useSelector((state: State) => {
        return state.userPokemon
    })

    const handleCardClick = (itemId: number) => {
        setSelectedItemId(itemId);
        setSelectedRevival(userProfile.inventory.find((item: any) => item.item_ID === itemId))
    };

    const handleOk = async () => {
        if (selectedRevival) {
            await userPokemonService.addLifeWithObject(userPokemonWithStats, selectedRevival).then((res) => {
                if (res.success) {
                    updateUser(res.user);
                    const pokemon = getStats(res.pokemon, res.user);
                    setPokemonPrincipal(pokemon);
                }
            });
            setModalVisible(false);
        }
    };

    const handleCancel = () => {
        setSelectedItemId(null);
        setModalVisible(false);
    };

    const handleOpenPopup = () => {
        setInItemSelection(true);
        setModalVisible(true);
    };

    const createImageSrc = (item: any) => {
        if (!item.img.data) return
        const buffer = Buffer.from(item.img.data)
        const base64String = buffer.toString('base64')
        const src = `data:images/png;base64,${base64String}`
        return src
    }

    const filteredInventory = userProfile.inventory
        .filter((item: any) => {
            if (userPokemonWithStats.life === 0) {
                return item.const === 'revival' && item.quantity > 0 && item.target === 'principal' && item.item_name.toLowerCase().includes(searchText.toLowerCase())
            } else if (userPokemonWithStats.life > 0 && userPokemonWithStats.life < 3) {
                return item.const === 'healing' && item.quantity > 0 && item.target === 'principal' && item.item_name.toLowerCase().includes(searchText.toLowerCase())
            }
        });

    return (
        <>
            <CircleButton icon={image} color={color} onClick={handleOpenPopup} style={style} />
            <Modal
                title="Choisissez votre objet de soin"
                open={modalVisible}
                onCancel={handleCancel}
                footer={[
                    <FlatModalButton
                        key="back"
                        label="Annuler"
                        onClick={handleCancel}
                    />,
                    <FlatModalButton
                        key="go"
                        label="Utiliser"
                        onClick={handleOk}
                        disabled={!selectedItemId}
                    />]}
                okButtonProps={{ disabled: !selectedItemId }}
            >
                {inItemSelection && (<>
                    <Input.Search
                        placeholder="Nom de l'objet"
                        onChange={(e) => setSearchText(e.target.value)}
                        style={{ marginBottom: '15px' }}
                        size='large'
                    />

                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px', textAlign: 'center', justifyContent: 'center', maxHeight: '35vh', overflowY: 'auto' }}>

                        {filteredInventory.map((item: any) => (
                            <div
                                key={item.item_name}
                                style={{
                                    marginTop: '10px',
                                    border: selectedItemId === item.item_ID ? '3px solid rgb(255, 150, 123)' : '3px solid transparent',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleCardClick(item.item_ID)}
                            >
                                <Tooltip placement="top" title={item.description}>
                                    <Badge count={item.quantity} style={{ backgroundColor: '#02133b' }} overflowCount={99}>
                                        <Card style={{ width: 120, backgroundColor: '#e8eddc', borderRadius: '3px' }} size='small'>
                                            <div style={{ textAlign: 'center', fontWeight: '600' }}>{item.item_name}</div>
                                            <img src={createImageSrc(item)} alt={item.item_name} style={{ width: 'auto', height: '30px', objectFit: 'cover', margin: 'auto', display: 'block' }} />
                                            <Rate disabled value={Math.abs(item.effect[1])} count={item.effect[1] <= 3 ? 3 : item.effect[1]} character={<HeartIcon />} style={{ color: item.effect[1] > 0 ? `#C80000` : `#5f3d46` }} />
                                        </Card>
                                    </Badge>
                                </Tooltip>
                            </div>
                        ))
                        }
                    </div> </>)}
            </Modal>
        </>
    );

};

export default HealButton;
