import { API_URL } from "./EndpointAPI";
import { setCookie, getCookie } from 'react-use-cookie';

export class FriendService {
    private redirect() {
        setCookie('pokeascenttoken', '', { days: 0 });
        window.location.href = `/`;
    }

    // Create a friend request
    public async createFriendRequest(friend: any): Promise<any> {
        const token = getCookie('pokeascenttoken');
        const response = await fetch(`${API_URL}/friend/add`, { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ friend }) });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    // Accept a friend request
    public async acceptFriendRequest(friendRequestId: any): Promise<any> {
        const token = getCookie('pokeascenttoken');
        const response = await fetch(`${API_URL}/friend/accept`, { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ friendRequestId }) });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    // Get friend requests
    public async getFriendRequests(): Promise<any> {
        const token = getCookie('pokeascenttoken');
        const response = await fetch(`${API_URL}/friend/requests`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}` } });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    // Get friends
    public async getFriends(): Promise<any> {
        const token = getCookie('pokeascenttoken');
        const response = await fetch(`${API_URL}/friend/list`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}` } });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    // Decline a friend request
    public async declineFriendRequest(friendRequestId: any): Promise<any> {
        const token = getCookie('pokeascenttoken');
        const response = await fetch(`${API_URL}/friend/decline`, { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ friendRequestId }) });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    // Delete a friend
    public async deleteFriend(friendId: any): Promise<any> {
        const token = getCookie('pokeascenttoken');
        const response = await fetch(`${API_URL}/friend/delete`, { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ friendId }) });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }
}