import React from 'react';
import { LifeIcon } from '../SVGIcons';
import { Rate } from 'antd';
import Life from '../../assets/images/life.png';

interface PokemonLivesProps {
  lives: number;
  style?: React.CSSProperties;
}

const PokemonLives: React.FC<PokemonLivesProps> = ({ lives, style }) => {

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Rate
        disabled
        value={lives}
        count={lives <= 3 ? 3 : lives}
        character={(index : any) => (
          // <LifeIcon
          //   style={{
          //     fontSize: '90px',
              
          //     filter: index.index >= lives ? 'grayscale(100%)' : 'none'
          //   }}
          // />
          <img src={Life} alt='life' style={{width:'30px', filter: index.index >= lives ? 'grayscale(100%)' : 'none'}}/>
        )}
        style={style}
      />
    </div>
  );
};

export default PokemonLives;
