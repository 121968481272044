import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

import './../assets/styles/pokemonTypes.css';

import { Tooltip } from 'antd';
import { ReactComponent as BugType } from "../assets/images/icnTypes/Pokemon_Type_Icon_Bug.svg";
import { ReactComponent as DarkType } from "../assets/images/icnTypes/Pokemon_Type_Icon_Dark.svg";
import { ReactComponent as DragonType } from "../assets/images/icnTypes/Pokemon_Type_Icon_Dragon.svg";
import { ReactComponent as ElectricType } from "../assets/images/icnTypes/Pokemon_Type_Icon_Electric.svg";
import { ReactComponent as FairyType } from "../assets/images/icnTypes/Pokemon_Type_Icon_Fairy.svg";
import { ReactComponent as FightingType } from "../assets/images/icnTypes/Pokemon_Type_Icon_Fighting.svg";
import { ReactComponent as FireType } from "../assets/images/icnTypes/Pokemon_Type_Icon_Fire.svg";
import { ReactComponent as FlyingType } from "../assets/images/icnTypes/Pokemon_Type_Icon_Flying.svg";
import { ReactComponent as GhostType } from "../assets/images/icnTypes/Pokemon_Type_Icon_Ghost.svg";
import { ReactComponent as GrassType } from "../assets/images/icnTypes/Pokemon_Type_Icon_Grass.svg";
import { ReactComponent as GroundType } from "../assets/images/icnTypes/Pokemon_Type_Icon_Ground.svg";
import { ReactComponent as IceType } from "../assets/images/icnTypes/Pokemon_Type_Icon_Ice.svg";
import { ReactComponent as NormalType } from "../assets/images/icnTypes/Pokemon_Type_Icon_Normal.svg";
import { ReactComponent as PoisonType } from "../assets/images/icnTypes/Pokemon_Type_Icon_Poison.svg";
import { ReactComponent as PsyType } from "../assets/images/icnTypes/Pokemon_Type_Icon_Psychic.svg";
import { ReactComponent as RockType } from "../assets/images/icnTypes/Pokemon_Type_Icon_Rock.svg";
import { ReactComponent as SteelType } from "../assets/images/icnTypes/Pokemon_Type_Icon_Steel.svg";
import { ReactComponent as WaterType } from "../assets/images/icnTypes/Pokemon_Type_Icon_Water.svg";

const typeComponents: { [key: string]: React.ComponentType } = {
  Psy: PsyType,
  Insecte: BugType,
  Ténèbres: DarkType,
  Dragon: DragonType,
  Electrik: ElectricType,
  Fée: FairyType,
  Combat: FightingType,
  Feu: FireType,
  Vol: FlyingType,
  Spectre: GhostType,
  Plante: GrassType,
  Sol: GroundType,
  Glace: IceType,
  Normal: NormalType,
  Poison: PoisonType,
  Roche: RockType,
  Acier: SteelType,
  Eau: WaterType
};

type TypeIconProps = {
  type: string;
  size?: number;
}

const TypeIcon: React.FC<TypeIconProps> = ({ type, size = 22 }) => {
  const TypeComponent = typeComponents[type];

  if (!TypeComponent) {
    return null;
  }

  const iconStyle = {
    margin: '0 3px',
    fontSize: `${size}px`,
  };

  const generateIcon = () => {
    const CustomIcon = (props: Partial<CustomIconComponentProps>) => (
      <Icon component={TypeComponent} {...props} style={iconStyle} />);

    return (
      <Tooltip placement="top" title={type}>
        <CustomIcon />
      </Tooltip>
    )
  }

  return (
    <>
      {generateIcon()}
    </>
  );
};

export default TypeIcon;