import './ShopSection.css';
import PokemonItem from './PokemonItem';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { UserPokemonService } from '../../services/UserPokemonService';
import { Button, Input } from 'antd';
import debounce from 'lodash/debounce'; // Install lodash.debounce if not already
import React from 'react';

type PokedexSectionProps = {
    updateUser: (userProfile: any) => void;
    userProfile: any;
};
interface UserPokemonTab {
    pokemon_ID: number;
    count: number;
    last_catched: Date;
    isOwned: boolean;
}

interface AllPokemonTab {
    pokemon_ID: number;
    pokemon_name: string;
    references: string[];
    img_front_default: any;
    types: number[];
}

const PokedexSection: React.FC<PokedexSectionProps> = ({ userProfile, updateUser }) => {
    const userPokemonService = new UserPokemonService();
    const [searchQuery, setSearchQuery] = useState('');
    const [tabCollection, setTabCollection] = useState<{ allPokemonTab: AllPokemonTab[]; userPokemonTab: UserPokemonTab[] }>({ allPokemonTab: [], userPokemonTab: [] });

    useEffect(() => {
        getTabCollection();
    }, []);

    const getTabCollection = async () => {
        const response = await userPokemonService.getCollection();
        setTabCollection({ allPokemonTab: response.allPokemonTab, userPokemonTab: response.userPokemonTab });
    };

    const debouncedSearch = useCallback(debounce((newSearchQuery: string) => {
        setSearchQuery(newSearchQuery);
    }, 300), []);

    const filteredPokemon = useMemo(() => {
        const query = searchQuery.toLowerCase();
        return tabCollection.allPokemonTab
            .filter(pokemon => searchQuery.length < 3 || pokemon.pokemon_name.toLowerCase().includes(query))
            .map(pokemon => ({
                ...pokemon,
                isOwned: tabCollection.userPokemonTab.some(userPokemon => userPokemon.pokemon_ID === pokemon.pokemon_ID),
            }));
    }, [searchQuery, tabCollection]);

    const createImageSrc = useCallback((pokemon: any) => {
        if (!pokemon.img_front_default) {
            return '';
        }

        const byteData = pokemon.img_front_default.data ? pokemon.img_front_default.data.data : pokemon.img_front_default.data;
        const base64String = Buffer.from(new Uint8Array(byteData)).toString('base64');
        return `data:image/png;base64,${base64String}`;
    }, []);

    const preComputedImages = useMemo(() => {
        return tabCollection.allPokemonTab.reduce((acc, pokemon) => {
            acc[pokemon.pokemon_ID] = createImageSrc(pokemon);
            return acc;
        }, {} as { [key: number]: string });
    }, [tabCollection.allPokemonTab, createImageSrc]);

    const [displayedPokemon, setDisplayedPokemon] = useState<any>([]);
    const [itemsToShow, setItemsToShow] = useState(20);

    useEffect(() => {
        // Simuler le chargement des données
        setDisplayedPokemon(filteredPokemon.slice(0, itemsToShow));
    }, [itemsToShow, filteredPokemon]);

    const handleScroll = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) setItemsToShow(itemsToShow + 20); // Charger 20 éléments supplémentaires
    };

    return (
        <>
            <div className="shop-section-card">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px', width: '100%' }}>
                    <Input.Search
                        placeholder="Nom du Pokémon"
                        onSearch={(value) => debouncedSearch(value)}
                        size='large'
                        style={{ flexGrow: 1, maxWidth: '250px', marginLeft: '10px', marginTop: '5px', height: '40px' }}
                    />
                    <Button
                        type="primary"
                        style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            backgroundColor: '#3c5c8a',
                            borderColor: 'black',
                            borderRadius: '15px',
                            borderStyle: 'solid',
                            borderWidth: '2px',
                            borderBottomWidth: '4px',
                            marginRight: '10px',
                            paddingRight: '5px',
                            paddingLeft: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '40px'
                        }}
                    >
                        <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {tabCollection.userPokemonTab.length}/{tabCollection.allPokemonTab.length}
                        </span>
                    </Button>
                </div>
                <div onScroll={handleScroll} className="grid-pokedex" style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 0.3fr))', gap: '5px', alignItems: 'stretch', justifyContent: 'center', width: '100%', overflowY: 'auto' }}>
                    {displayedPokemon.map((pokemon: any, index: any) => (
                        <PokemonItem key={index} style={{ backgroundColor: '#f6f6f6' }}
                            img={preComputedImages[pokemon.pokemon_ID]}
                            name={pokemon.isOwned ? pokemon.pokemon_name : '???'}
                            pokedexId={pokemon.pokemon_ID}
                            type={pokemon.types}
                            isOwned={pokemon.isOwned}
                            gender={-1}
                            rank={-1}
                            onClick={() => { /* Your click handler here */ }} />
                    ))}
                </div>
            </div>

        </>
    );
}

export default PokedexSection;