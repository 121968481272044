import React from 'react';
import '../../assets/styles/flat-button.css';

interface FlatButtonProps {
    label: string;
    onClick: () => void;
    color?: string;
    textColor?: string;
    icon?: React.ReactNode; // React node to allow for any React element to be passed
    iconPosition?: 'left' | 'right';
    disabled?: boolean;
    style?: React.CSSProperties;
    isAnimated?: boolean;
}

const FlatButton: React.FC<FlatButtonProps> = ({
    label,
    color = '#FEC569',
    textColor = '#F6F6F6',
    icon,
    iconPosition = 'left',
    onClick,
    disabled = false,
    style,
    isAnimated = true,
}) => {
    // Define a style for the icon container
    const iconStyle: React.CSSProperties = {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: !label ? '0' : iconPosition === 'left' ? '10px' : '0',
        marginLeft: !label ? '0' : iconPosition === 'right' ? '10px' : '0',
        color: textColor,
    };

    const darkenColor = (hexColor: string, percent: number): string => {
        const f = parseInt(hexColor.slice(1), 16),
            t = percent < 0 ? 0 : 255,
            p = percent < 0 ? percent * -1 : percent,
            R = f >> 16,
            G = (f >> 8) & 0x00ff,
            B = f & 0x0000ff;
        return (
            '#' +
            (
                0x1000000 +
                (Math.round((t - R) * p) + R) * 0x10000 +
                (Math.round((t - G) * p) + G) * 0x100 +
                (Math.round((t - B) * p) + B)
            )
                .toString(16)
                .slice(1)
        );
    };

    const currentColor = disabled ? '#A9A9A9' : color;
    const currentBorderColor = disabled ? darkenColor('#A9A9A9', -0.1) : darkenColor(color, -0.2);
    const cursor = disabled ? 'not-allowed' : 'pointer';

    return (
        <button
            className="flat-button"
            disabled={disabled}
            onClick={onClick}
            style={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: currentColor,
                border: 'none',
                borderColor: currentBorderColor,
                borderBottom: `3px solid ${currentBorderColor}`,
                borderRadius: '30px',
                padding: '10px 15px',
                color: textColor,
                fontSize: '1rem',
                fontWeight: 600,
                fontFamily: 'Roboto, sans-serif',
                cursor: cursor,
                outline: 'none',
                transition: 'transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out',
                boxShadow: '0 2px #888',
                minWidth: label ? '150px' : '49px',
                ...style,
                minHeight: '49px',
                transform: 'translateY(-1px)',
            }}
            onMouseDown={(e) => {
                e.currentTarget.style.transform = 'translateY(1px)';
                e.currentTarget.style.boxShadow = '0 1px #888';
            }}
            onMouseUp={(e) => {
                e.currentTarget.style.transform = 'translateY(-1px)';
                e.currentTarget.style.boxShadow = '0 2px #888';
            }}
            onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'translateY(-1px)';
                e.currentTarget.style.boxShadow = '0 2px #888';
            }}
        >
            {icon && iconPosition === 'left' && <span className={isAnimated ? "icon" : ''} style={iconStyle}>{icon}</span>}
            {label}
            {icon && iconPosition === 'right' && <span className={isAnimated ? "icon" : ''} style={iconStyle}>{icon}</span>}
        </button>
    );
};

export default FlatButton;
