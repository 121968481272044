import React, { useEffect, useState } from 'react';
import backgroundImageUrl from '../../assets/images/background-ladder.png';
import RareCandy from '../../assets/images/rare-candy.png';
import OverlayShop from './OverlayShop';
import NavigationBar from './NavigationBar';
import { Button, Card, Col, Row } from 'antd';
import './ShopSection.css';
import StarPiece from '../../assets/images/icnRanks/starpiece.png';
import '../../assets/styles/ladder.css';
import { LadderService } from '../../services/LadderService';
import { useSelector } from 'react-redux';
import { State } from '../../state';
import { SwapOutlined } from '@ant-design/icons';
import { set } from 'mongoose';

interface LadderMenuProps {
    userProfile: any;
    updateUser: (userProfile: any) => void;
}

interface PokemonLadder {
    username: string;
    level: number;
    pokemon_name: string;
    img: any;
    rank: number;
}

const LadderMenu: React.FC<LadderMenuProps> = ({ userProfile, updateUser }) => {

    const userPokemonWithStats = useSelector((state: State) => state.userPokemon);

    const [dialogCommand, setDialogCommand] = useState<any>(null);
    const [, setDialogType] = useState<string | number | null>(null);

    // Fonction pour définir la commande et le type de dialogue
    const handleDialogCommand = (command: any) => {
        setDialogCommand(command);
        setDialogType(command.type ?? null);
    };

    const centerStyle: React.CSSProperties = {
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        zIndex: 10,
    };

    const ladderService = new LadderService();
    const [ladderData, setLadderData] = useState<PokemonLadder[]>([]);
    const [ladderCurrent, setLadderCurrent] = useState<PokemonLadder[]>([]);
    const [ladderHighest, setLadderHighest] = useState<PokemonLadder[]>([]);
    const [typeLadder, setTypeLadder] = useState<string>('current');

    useEffect(() => {
        async function fetchLadderData() {
            try {
                ladderService.getTopUsersWithHighestLevelPokemon().then(response => {
                    setLadderData(response);
                    setLadderCurrent(response);
                });

            } catch (error) {
                console.error('Error fetching ladder data:', error);
            }
        }

        fetchLadderData();
        getHighest();
    }, []);

    const getHighest = () => {
        try {
            ladderService.getHighestLevelPokemonByUsers().then(response => {
                setLadderHighest(response);
            });

        } catch (error) {
            console.error('Error fetching highest level pokemon:', error);
        }
    }

    const changeLadder = () => {
        if (typeLadder === 'current') {
            setLadderData(ladderHighest);
            setTypeLadder('highest');
        } else {
            setLadderData(ladderCurrent);
            setTypeLadder('current');
        }
    }

    const StarImage = (count: any) => {

        return <img
            src={StarPiece}
            alt={`star ${count}`}
            style={count.count <= 0 ? { width: '24px', height: '24px', marginRight: '-7px', filter: 'grayscale(100%)' } : { width: '24px', height: '24px', marginLeft: '3px', marginRight: '-7px' }}
        />
    };

    const Stars = (count: any) => {
        const stars = [];
        if (count <= 0) {
            stars.push(<StarImage key={count} count={count} />);
        }
        for (let i = 0; i < count; i++) {
            stars.push(<StarImage key={i} count={count} />);
        }

        return <>{stars}</>;
    };

    const createImage = (img: any) => {
        if (!img.data) return '';

        let src = '';
        const buffer = Buffer.from(img.data);
        if (typeof img.data === 'string') {
            src = `data:image/png;base64,${buffer}`
        } else {
            const base64String = buffer.toString('base64');
            src = `data:image/png;base64,${base64String}`;
        }

        return src;
    };

    return (
        <>
            <OverlayShop
                backgroundImage={backgroundImageUrl}
                overlayImage={''}
                style={{ marginBottom: '15px' }}
                money={userProfile.money}
                dna={userProfile.adnMoney}
                dialogObject={{
                    npcName: userProfile.username,
                    text: dialogCommand ? <> Oh, <b>{new Intl.NumberFormat().format(Number(dialogCommand))}</b><img src={RareCandy} alt="money" /> !</> : <>Texte par défaut</>,
                    isVisible: dialogCommand !== null,
                    hideAfter: 10000,
                    onHideAfter: () => setDialogCommand(null)
                }}
                lives={userPokemonWithStats.life}
            />
            <div className="shop-section-card" style={{gap:'0.5rem'}}>
                {/** Ladder highest level pokemon */}
                <div style={{display:'flex', margin:'auto'}}>
                <Button
                    type="primary"
                    style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        backgroundColor: '#3c5c8a', // Couleur de fond du bouton
                        borderColor: 'black', // Couleur de la bordure du bouton
                        width: 'auto', // Largeur fixe à 80% de la taille de la card
                        marginLeft: 'auto', // Centrer
                        marginRight: 'auto', // Centrer
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '15px',
                        borderStyle: 'solid',
                        borderWidth: '2px',
                        borderBottomWidth: '4px'
                    }}
                    onClick={() => changeLadder()} // Vous pouvez gérer l'achat ici si nécessaire
                >
                    <SwapOutlined style={{marginRight:'10px', fontSize:'18px'}} />
                    <span style={{ marginRight: '0.15em', marginLeft: '0.15em' }}>{typeLadder === 'highest' ? 'Plus haut niveau global' : 'Meilleur actuel'}</span>
                </Button>
                </div>
                <div className="shop-section" style={{ gap: '0.5em' }}>
                    {ladderData.map((entry, index) => (
                        <Card className="ladder-card" style={{
                            display: 'flex',
                            backgroundColor: '#f8f8f8',
                            borderStyle: 'solid',
                            borderColor: 'black',
                            borderWidth: '2px',
                            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02)',
                            cursor: 'pointer',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                            width: 'calc(100% - 20px)'
                        }}>
                            <Row wrap={false}>
                                <Col flex="100px"> {/* Colonne pour l'image */}
                                    <img
                                        src={entry.img ? createImage(entry.img) : ''}
                                        alt={entry.pokemon_name}
                                        style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                                    />
                                </Col>
                                <Col span={18}> {/* Colonne pour les infos */}
                                    <div style={{ display: 'grid' }}>
                                        <div className="pokemon-name">
                                            {index === 0 && <span style={{ fontWeight: '600', color: 'white', backgroundColor: 'gold', padding: '3px 10px', borderRadius: '5px', zIndex: 2, marginRight: '5px' }}>1er</span>}
                                            {index === 1 && <span style={{ fontWeight: '600', color: 'white', backgroundColor: 'silver', padding: '3px 10px', borderRadius: '5px', zIndex: 2, marginRight: '5px' }}>2eme</span>}
                                            {index === 2 && <span style={{ fontWeight: '600', color: 'white', backgroundColor: '#cd7f32', padding: '3px 10px', borderRadius: '5px', zIndex: 2, marginRight: '5px' }}>3eme</span>}
                                            {index >= 3 && <span style={{ fontWeight: '600', zIndex: 1, marginRight: '5px' }}>{index + 1}eme</span>}
                                            {entry.pokemon_name}</div>
                                        <span>Propriétaire: <b>{entry.username}</b></span>
                                        <span>Rang: {Stars(entry.rank)}</span>
                                        <span>Niv. <b>{entry.level}</b></span>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    ))}
                </div>
            </div>

            <NavigationBar userProfile={userProfile} updateUser={updateUser} style={centerStyle} onButtonMonneyClick={handleDialogCommand} />
        </>
    )
};

export default LadderMenu;