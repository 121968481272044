import { API_URL } from "./EndpointAPI";
import { setCookie, getCookie } from 'react-use-cookie';

export class NotificationService {
    private redirect() {
        setCookie('pokeascenttoken', '', { days: 0 });
        window.location.href = `/`;
    }

    public async getNotifications(): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/notification/getNotifications`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}` } });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async sendNotification(notificationTab: any[]): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/notification/sendNotification`, { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ notificationTab }) });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async clearNotifications(notifications: any[]): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/notification/clearNotifications`, { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ notifications }) });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async sendManualNotification(notification: any): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/notification/sendManualNotification`, { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ notification }) });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }
}