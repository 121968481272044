import React from 'react';
import '../../assets/styles/flat-button.css';
import { Button, ButtonProps } from 'antd';

interface FlatModalButtonProps extends ButtonProps {
    label: string;
    icon?: React.ReactNode;
    iconPosition?: 'left' | 'right';
    disabled?: boolean;
    style?: React.CSSProperties;
    hideLabelOnMobile?: boolean;
}

const FlatModalButton: React.FC<FlatModalButtonProps> = ({
    label,
    icon,
    iconPosition = 'left',
    style,
    disabled = false,
    hideLabelOnMobile = false,
    ...props
}) => {
    // Define a style for the icon container
    const iconStyle: React.CSSProperties = {
        display: 'inline-block',
        verticalAlign: 'middle',
        marginRight: hideLabelOnMobile ? '0' : iconPosition === 'left' ? '8px' : '0',
        marginLeft: hideLabelOnMobile ? '0' : iconPosition === 'right' ? '8px' : '0',
        marginBottom: '2px',
    };

    // Style if disabled is true
    const disabledStyle: React.CSSProperties = {
        backgroundColor: '#A9A9A9',
        color: '#414141',
        cursor: 'not-allowed',
        border: '4px solid #A9A9A9',
        boxShadow: `0 0 0 2px #414141`,
    };

    return (
        <Button
            className="flat-button"
            disabled={disabled}
            {...props}
            style={{
                minWidth: '60px',
                width: 'auto',
                height: '30px',
                borderRadius: '20px',
                backgroundColor: '#fffde9',
                color: 'black',
                border: '4px solid #fffde9', // Bordure extérieure blanche
                outline: 'none',
                cursor: 'pointer',
                boxShadow: `0 0 0 2px black`, // Bordure noire
                fontSize: '1rem',
                padding: '0px 10px 0px 10px',
                fontFamily: 'Roboto, sans-serif',
                fontWeight: 'bold',
                display: 'inline-block',
                verticalAlign: 'middle',
                ...style,
                ...(disabled ? disabledStyle : {}) // Appliquer le style désactivé si nécessaire
            }}
        >
            {icon && iconPosition === 'left' && <span className="icon" style={iconStyle}>{icon}</span>}
            <span className={hideLabelOnMobile ? "label-hide-mobile" : ""} style={hideLabelOnMobile && iconPosition === 'left' ? { marginLeft: '8px' } : {}}>{label}</span>
            {icon && iconPosition === 'right' && <span className="icon" style={iconStyle}>{icon}</span>}
        </Button>
    );
};

export default FlatModalButton;
