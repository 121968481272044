import { Action } from '../actions/index';
import { ActionType } from '../action-types';

const initialState: any = {
    egg: [],
    pokeball: [],
    superball: [],
    hyperball: []
}

const oddsReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case ActionType.SET_POKEEGGS:
            return {...state, egg: action.payload } ;
        case ActionType.SET_POKEBALLS:
            return { ...state, pokeball: action.payload}
        case ActionType.SET_SUPERBALLS:
            return { ...state, superball: action.payload}
        case ActionType.SET_HYPERBALLS:
            return { ...state, hyperball: action.payload}
        default:
            return state;
    }
};

export default oddsReducer;
