import { Action } from '../actions/index';
import { ActionType } from '../action-types';
import UserPokemon from '../../interface/UserPokemon';

const initialState : UserPokemon = {
  _id: '',
  img_front: { data: Buffer.from(''), contentType: '' },
  img_back: { data: Buffer.from(''), contentType: '' },
  pokemon_ID: 0,
  pokemon_name: '',
  base_experience: 0,
  experience: 0,
  life: 0,
  owner: '',
  hapiness: 0,
  base_hp: 0,
  base_attack: 0,
  base_defense: 0,
  base_speed: 0,
  base_special_attack: 0,
  base_special_defense: 0,
  gender: 0,
  is_shiny: false,
  types: [0],
  moves: [0],
  ev_hp: 0,
  ev_attack: 0,
  ev_defense: 0,
  ev_speed: 0,
  ev_special_attack: 0,
  ev_special_defense: 0,
  iv_hp: 0,
  iv_attack: 0,
  iv_defense: 0,
  iv_speed: 0,
  iv_special_attack: 0,
  iv_special_defense: 0,
  stats: {
    hp: 0,
    attack: 0,
    defense: 0,
    speed: 0,
    specialAttack: 0,
    specialDefense: 0,
    level: 0
  },
  movesString: [],
  dateCapture: new Date(),
  zombie: false,
  canEvolve: false,
  rarity: 'Inconnu',
  rank: 0,
  date: new Date()
}

const userPokemonReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.SET_POKEMON_PRINCIPAL:
      return action.payload;
    default:
      return state;
  }
};

export default userPokemonReducer;
