import { useEffect, useState } from "react";
import './ShopSection.css';
import { Card, Row, Col, Select, Avatar, Button, Modal } from "antd";
import { FriendService } from "../../services/FriendService";
import { TaskService } from "../../services/TaskService";
import '../../assets/styles/ladder.css';
import { UserDeleteOutlined, CheckOutlined, CloseOutlined, FieldTimeOutlined, UserSwitchOutlined } from "@ant-design/icons";
import CircleButton from "./CircleButton";

type FriendListProps = {
    updateUser: (userProfile: any) => void;
    userProfile: any;
};

const FriendList: React.FC<FriendListProps> = ({ userProfile, updateUser }) => {

    const friendService = new FriendService();
    const taskService = new TaskService();

    const [friends, setFriends] = useState<any>([]);
    const [friendsRequests, setFriendsRequests] = useState<any>([]);
    const [users, setUsers] = useState<any[]>([]);
    const [selectedUser, setSelectedUser] = useState<any | null>(null);

    const { Option } = Select;

    useEffect(() => {
        fetchFriends();
        fetchFriendRequests();
        getUserList();
    }, [userProfile, updateUser]);

    const fetchFriends = async () => {
        friendService.getFriends().then((json) => {
            if (json.success) {
                setFriends(json.friends);
            }
        });
    };

    const fetchFriendRequests = async () => {
        friendService.getFriendRequests().then((json) => {
            if (json.success) {
                setFriendsRequests(json.friendRequests);
            }
        });
    }

    const getUserList = async () => {
        taskService.getUsers().then(response => {
            if (response.success) {
                // Remove the current user from the list
                const users = response.users.filter((user: any) => user.userId !== userProfile.userId);
                setUsers(users);
            }
        });
    }

    const handleUserSelect = (userId: string) => {
        const user = users.find(user => user.userId === userId);
        setSelectedUser(user);
    };

    const constructFriendList = () => {
        return friends.concat(friendsRequests).sort((a: any, b: any) => a.status.localeCompare(b.status));
    }

    const removeExistingFriend = () => {
        // Remove the users that are already friends
        return users.filter((user: any) => !constructFriendList().some((friend: any) => friend.userId === user.userId)).sort((a, b) => a.username.localeCompare(b.username));
    }

    const createFriendRequest = () => {
        if (selectedUser) {
            friendService.createFriendRequest(selectedUser._id).then(response => {
                if (response.success) {
                    fetchFriends();
                    fetchFriendRequests();
                }
            });
        }
    }

    const acceptFriendRequest = (friendRequestId: string) => {
        friendService.acceptFriendRequest(friendRequestId).then(response => {
            if (response.success) {
                fetchFriends();
                fetchFriendRequests();
            }
        });
    }

    const declineFriendRequest = (friendRequestId: string) => {
        friendService.declineFriendRequest(friendRequestId).then(response => {
            if (response.success) {
                fetchFriendRequests();
            }
        });
    }

    const deleteFriend = (friendId: string) => {
        friendService.deleteFriend(friendId).then(response => {
            if (response.success) {
                fetchFriends();
            }
        });
    }

    return (
        <>
            <div className="shop-section-card" style={{ gap: "0.5rem", paddingLeft: "10px", paddingRight: "10px" }}>
                <Row align="middle" justify="space-between" style={{ marginBottom: "10px" }}>
                    <Col flex="auto">
                        <Select
                            showSearch
                            placeholder="Chercher un utilisateur"
                            style={{ width: 200 }}
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onChange={handleUserSelect}
                        >
                            {removeExistingFriend().map((user : any) => (
                                <Option key={user.username} value={user.userId}>
                                    {user.username}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                    <Col style={{ marginLeft: "15px" }}>
                        <Button
                            type="primary"
                            style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                backgroundColor: "#3c5c8a",
                                borderColor: "black",
                                width: "auto",
                                marginLeft: "auto",
                                marginRight: "auto",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "15px",
                                borderStyle: "solid",
                                borderWidth: "2px",
                                borderBottomWidth: "4px",
                            }}
                            onClick={createFriendRequest}
                        >
                            <span style={{ marginRight: "0.15em", marginLeft: "0.15em" }}>{'Ajouter'}</span>
                        </Button>
                    </Col>
                </Row>
                <div className="shop-section" style={{ overflow: "auto", paddingLeft: "2px", paddingRight: "2px", width: '100%' }}>
                    {constructFriendList().map((friend: any) => (
                        <Card
                            key={friend._id}
                            style={{
                                backgroundColor: "#f8f8f8",
                                borderStyle: "solid",
                                borderColor: "black",
                                borderWidth: "2px",
                                boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02)",
                                cursor: "pointer",
                                width: "100%",
                            }}
                        >
                            <Row align="middle" justify="space-between">
                                <Col span="50px">
                                    <Avatar
                                        src={friend.userId ? `https://cdn.discordapp.com/avatars/${friend.userId}/${friend.avatar}.webp` : ""}
                                        size={30}
                                        shape="circle"
                                    />
                                </Col>
                                <Col flex="auto">
                                    <span style={{ fontWeight: "bolder", marginLeft: "3%" }}>{friend.username}</span>
                                </Col>
                                <Col>
                                    <div style={{ display: "flex", gap: "15px", justifyContent: "flex-end" }}>
                                        {friend.status === "PENDING" && friend.owner !== userProfile._id ? (
                                            <>
                                                <CircleButton
                                                    onClick={() => acceptFriendRequest(friend.friendRequestId)}
                                                    icon={<CheckOutlined />}
                                                    color="#4fb471"
                                                    borderColor="#4fb471"
                                                    iconColor="#000000"
                                                    style={{ height: "30px", width: "30px" }}
                                                />
                                                <CircleButton
                                                    onClick={() => declineFriendRequest(friend.friendRequestId)}
                                                    icon={<CloseOutlined />}
                                                    color="#fffae6"
                                                    iconColor="#000000"
                                                    style={{ height: "30px", width: "30px" }}
                                                />
                                            </>
                                        ) : friend.status === "PENDING" && friend.owner === userProfile._id ? (
                                            <CircleButton
                                                onClick={() => { }}
                                                icon={<FieldTimeOutlined style={{marginLeft:'2px'}}/>}
                                                color="#ffa14b"
                                                iconColor="#fffee5"
                                                style={{ height: "30px", width: "30px", borderColor: "#ffa14b"}}
                                            />
                                        ) : (
                                            <>
                                                <CircleButton
                                                    onClick={() => deleteFriend(friend._id)}
                                                    icon={<UserDeleteOutlined />}
                                                    color="#fffae6"
                                                    iconColor="#000000"
                                                    style={{ height: "30px", width: "30px" }}
                                                />
                                            </>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    ))}
                </div>
            </div>
            {/* Modal to select pokemon to use for exchange */}
            <Modal
                title="Echanger avec un ami"
                open={false}
                onOk={() => { }	}
                onCancel={() => { }}
                okText="Echanger"
                cancelText="Annuler"
            />
        </>
    );
}

export default FriendList;