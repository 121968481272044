import React, { memo, useState } from 'react';
import RareCandy from '../../assets/images/rare-candy.png';
import OverlayShop from './OverlayShop';
import backgroundShopUrl from '../../assets/images/background-shop.png';
import pnjShopUrl from '../../assets/images/pnj-shop.png';
import NavigationBar from './NavigationBar';
import ShopSection from './ShopSection';
import { useSelector } from 'react-redux';
import { State } from '../../state';

type ShopElementProps = {
  updateUser: (userProfile: any) => void;
  userProfile: any;
}

const ShopElement: React.FC<ShopElementProps> = ({ updateUser, userProfile }) => {

  const userPokemonWithStats = useSelector((state: State) => state.userPokemon);
  
  const [dialogCommand, setDialogCommand] = useState<any>(null);
  const [dialogType, setDialogType] = useState<string | number | null>(null);

  // Fonction pour définir la commande et le type de dialogue
  const handleDialogCommand = (command: any) => {
    setDialogCommand(command);
    setDialogType(command.type ?? null);
  };

  const createImageItem = (item: any) => {
    if (!item) return '';

    let src = '';
    const buffer = Buffer.from(item.item.img.data.data);
    if (typeof item.item.img.data.data === 'string') {
      src = `data:image/png;base64,${buffer}`
    } else {
      const base64String = buffer.toString('base64');
      src = `data:image/png;base64,${base64String}`;
    }

    return src;
  };

  const centerStyle: React.CSSProperties = {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    zIndex: 10,
  };

  return (
    <>
      <OverlayShop
        backgroundImage={backgroundShopUrl}
        overlayImage={pnjShopUrl}
        style={{ marginBottom: '15px' }}
        money={userProfile.money}
        dna={userProfile.adnMoney}
        dialogObject={{
          npcName: 'Serge le vendeur',
          text: dialogType === 'SHOP' ?
            <>Voici pour vous <b>{dialogCommand.selectedItem.item.item_name} x{dialogCommand.numberItem}</b> <img src={createImageItem(dialogCommand.selectedItem)} alt="objet acheté" style={{ maxWidth: '50px' }} />
              pour <b>{new Intl.NumberFormat().format(dialogCommand.selectedItem.cost * dialogCommand.numberItem)}</b> <img src={RareCandy} alt="Rare candy" style={{ height: '22px' }} /></>
            : typeof dialogCommand === 'number' ?
              <>Oh, vous avez fait tomber <b>{new Intl.NumberFormat().format(Number(dialogCommand))}</b><img src={RareCandy} alt="money" /> !</>
              : <>Texte par défaut</>,
          isVisible: dialogCommand !== null,
          hideAfter: 5000,
          onHideAfter: () => {
            setDialogType(null);
            setDialogCommand(null);
          }
        }}
        lives={userPokemonWithStats.life}
      />
      <ShopSection updateUser={updateUser} userProfile={userProfile} onBuy={handleDialogCommand} />
      <NavigationBar userProfile={userProfile} updateUser={updateUser} style={centerStyle} onButtonMonneyClick={handleDialogCommand} />
    </>
  );
}

export default memo(ShopElement)
