import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from 'react-use-cookie';

interface CheckCookieProps {
  path: string;
}

const CheckCookie: React.FC<{ props: CheckCookieProps }> = ({ props }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (getCookie('pokeascenttoken')) {
      navigate('/loading?path=' + props.path);
    }
  }, []);

  return (
    <>
    </>
  );

}


export default CheckCookie
