import Button from "antd/es/button/button";
import Card from "antd/es/card/Card";
import { Content, Footer } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import React from "react";
import { Link } from "react-router-dom";
import logoprincipal from "../assets/images/PokeAscent.png";
import logo1 from "../assets/images/discord.svg";
import { TaskService } from "../services/TaskService";
import { LinkedinFilled } from "@ant-design/icons";

const Login: React.FC = () => {
  const taskService = new TaskService();

  const launchLogin = () => {
    taskService.loginDiscord().then(response => {
      window.location.href = response
    });
  }

  return (
    <>
      <div className="layout-default ant-layout layout-sign-up set-bg">

        <Content className="p-0">
          <div className="sign-up-header">
            <div className="">
              <Title><img src={logoprincipal} alt="Logo PokeAscent" /></Title>
            </div>
          </div>

          <Card
            className="card-signup header-solid h-full ant-card pt-0"
            title={<h5>Connecte toi avec <b style={{ color: '#5662f6' }} onClick={() => launchLogin()}>Discord</b> !</h5>}
          >
            <div className="sign-up-gateways">
              <Button onClick={() => launchLogin()}>
                <img src={logo1} alt="logo 1" />
              </Button>
            </div>
            <p className="font-semibold text-muted text-center" style={{ paddingTop: "30px" }}>
              Vous acceptez {" "}
              <Link to="/conditions" className="font-bold text-dark" style={{ color: '#5662f6' }}>
                les conditions d'utilisation
              </Link>
            </p>
          </Card>
        </Content>
        <Footer className='footer-login'>
          <span className="copyright">
            {" "}
            Copyright © 2024 Chaloon - Pokémon est une marque déposée de Nintendo et The Pokémon Company. Ce contenu est realisé par des fans pour des fans.
            {" "}
          </span>
          <br />
        </Footer>
      </div>
    </>
  );

}

export default Login
