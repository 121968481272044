import React from 'react';
import EffectiveIcon from '../EffectiveIcon';
import RareCandy from '../../assets/images/rare-candy.png';
import PixelXP from '../../assets/images/xpPixel.png';

interface TimelineElementProps {
    mode: "begin" | "item" | "end" | "victory" | "defeat" | "argent" | "exp" | "objet" | "bet" | "custom";
    key?: string;
    attacker?: string;
    defenser?: string;
    move?: string;
    damage?: number;
    color?: string;
    type?: number;
    money?: number;
    exp?: number;
    efficientMultiplier?: number;
    typeFight?: string;
    message?: string;
}

const TimelineElement: React.FC<TimelineElementProps> = ({ message, mode, key, attacker, defenser, move, damage, color, type = 999, efficientMultiplier = 0, money = 0, exp = 0, typeFight }) => {
    const BeginTimeline = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', border: '2px solid black', background: '#fffde9', margin: '10px', fontWeight: 'bolder', borderRadius: '5px' }}>
                <span style={{ margin: '3px' }}>Début du combat {TypeFight()}</span>
            </div>
        );
    }

    const TypeFight = () => {
        switch (typeFight) {
            case 'CASINO':
                return 'contre la Team Casino';
            case 'ROCKET':
                return 'contre la Team Rocket';
            default:
                return '';
        }
    }

    const MoneyTimeline = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', border: '2px solid black', background: '#fffde9', margin: '10px', fontWeight: 'bolder', borderRadius: '5px' }}>
                <span style={{ margin: '3px' }}>Vous avez obtenu des bonbons</span>
            </div>
        );
    }

    const ExpTimeline = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', border: '2px solid black', background: '#fffde9', margin: '10px', fontWeight: 'bolder', borderRadius: '5px' }}>
                <span style={{ margin: '3px' }}>Vous avez gagné de l'expérience</span>
            </div>
        );
    }

    const ObjetTimeline = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', border: '2px solid black', background: '#fffde9', margin: '10px', fontWeight: 'bolder', borderRadius: '5px' }}>
                <span style={{ margin: '3px' }}>Vous avez obtenu un objet</span>
            </div>
        );
    }

    const ItemTimeline = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', border: '2px solid black', background: '#fffde9', margin: '10px', fontWeight: 'bolder', borderRadius: '5px', padding: '10px', whiteSpace: 'pre-line' }}>
                <div style={{ flex: 1, minWidth: '230px' }}>
                    <b style={{ color: color }}>{attacker}</b> lance <b>{move}</b>
                </div>
                <div style={{ flex: 1, textAlign: 'right', marginRight: '5px' }}>
                    <span>{defenser}</span><span style={{ color: 'red' }}> -{damage} PV</span>
                </div>
                <EffectiveIcon spellType={type} efficientMultiplier={efficientMultiplier} />
            </div>

        );
    }

    const EndTimeline = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', border: '2px solid black', background: '#fffde9', margin: '10px', fontWeight: 'bolder', borderRadius: '5px' }}>
                <span style={{ margin: '3px' }}>Fin du combat</span>
            </div>
        );
    }

    const VictoryTimeline = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', border: '2px solid black', background: '#fffde9', margin: '10px', fontWeight: 'bolder', borderRadius: '5px' }}>
                <span style={{ margin: '3px', color: 'green' }}>
                    Victoire
                </span>
                <span> | +{money}
                    <img src={RareCandy} alt="money" />
                    |
                    +{exp}
                    <img src={PixelXP} style={{ width: "25px", height: "auto", marginLeft: '5px' }} alt="exp" />
                </span>
            </div>
        );
    }

    const DefeatTimeline = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', border: '2px solid black', background: '#fffde9', margin: '10px', fontWeight: 'bolder', borderRadius: '5px' }}>
                <span style={{ margin: '3px', color: 'red' }}>Défaite</span>
            </div>
        );
    }

    const BetTimeline = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', border: '2px solid black', background: '#fffde9', margin: '10px', fontWeight: 'bolder', borderRadius: '5px' }}>
                <span style={{ margin: '3px' }}>Vous avez misé {money} <img src={RareCandy} alt="money" /></span>
            </div>
        );
    }

    const CustomTimeline = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', border: '2px solid black', background: '#fffde9', margin: '10px', fontWeight: 'bolder', borderRadius: '5px' }}>
                <span style={{ margin: '3px' }}>{message}</span>
            </div>
        );
    }

    let html = null;

    switch (mode) {
        case "begin":
            html = BeginTimeline();
            break;
        case "item":
            html = ItemTimeline();
            break;
        case "end":
            html = EndTimeline();
            break;
        case "victory":
            html = VictoryTimeline();
            break;
        case "defeat":
            html = DefeatTimeline();
            break;
        case "argent":
            html = MoneyTimeline();
            break;
        case "exp":
            html = ExpTimeline();
            break;
        case "objet":
            html = ObjetTimeline();
            break;
        case "bet":
            html = BetTimeline();
            break;
        case "custom":
            html = CustomTimeline();
            break;
        default:
            break;
    }

    return (
        <div>
            {html}
        </div>
    );
}

export default TimelineElement;
