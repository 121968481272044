import React, { useState } from 'react';
import PillsButton from './PillsButton';
import { Modal, Input, Tooltip, Badge, Card, Rate, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserPokemon from '../../interface/UserPokemon';
import { getStats, UserPokemonService } from '../../services/UserPokemonService';
import { actionCreators, State } from '../../state';
import { HeartIcon } from '../SVGIcons';
import FlatModalButton from './FlatModalButton';
import BoostPanel from './BoostPanel';
import { set } from 'mongoose';

interface BoostButtonProps {
    icon: React.ReactNode; // Accepte n'importe quel élément React comme icône
    style?: React.CSSProperties; // Styles supplémentaires pour le bouton
    iconPosition?: 'left' | 'right';
    label: string;
    userProfile: any;
    updateUser: (userProfile: any) => void;
}

interface Boost {
    item_ID: number;
    item_name: string;
    description: string;
    effect: [string, number];
    quantity: number;
    const: string;
    target: string;
}

const BoostButton: React.FC<BoostButtonProps> = ({ label, icon, style, iconPosition = 'left', userProfile, updateUser }) => {

    const [modalVisible, setModalVisible] = useState(false);
    const [selectedBoost, setSelectedBoost] = useState<Boost | null>(null);
    const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
    const [searchText, setSearchText] = useState('');
    const [inItemSelection, setInItemSelection] = useState(true);

    const userPokemonService = new UserPokemonService();
    const dispatch = useDispatch()

    const { setPokemonPrincipal } = bindActionCreators(actionCreators, dispatch);
    const userPokemonWithStats: any = useSelector((state: State) => {
        return state.userPokemon
    })

    const handleCardClick = (itemId: number) => {
        setSelectedBoost(userProfile.inventory.find((item: any) => item.item_ID === itemId));
        // Check if stats from the selected item are already maxed
        const selectedBoostItem = userProfile.inventory.find((item: any) => item.item_ID === itemId);
        const stat: keyof UserPokemon = selectedBoostItem.effect[0];
        const currentStat = userPokemonWithStats[stat];
        // Check if IV/EV is already maxed or if the sum of EVs is already maxed
        if (selectedBoostItem.const === 'stat-boosts' && currentStat >= 31) {
            message.error('La statistique est déjà maximale');
            setSelectedItemId(null);
            return;
        } else if (selectedBoostItem.const === 'vitamins' && (currentStat >= 252 || Object.keys(userPokemonWithStats).filter(key => key.startsWith('ev_')).reduce((sum, key) => sum + userPokemonWithStats[key], 0) >= 510)) {
            message.error('La statistique est déjà maximale');
            setSelectedItemId(null);
            return;
        } else {
            setSelectedItemId(itemId);
        }
    };

    const handleOk = async () => {
        if (selectedBoost) {
            if (selectedBoost.const === 'stat-boosts') {
                await userPokemonService.addIV(selectedBoost).then((res) => {
                    if (res.success) {
                        updateUser(res.user);
                        const pokemon = getStats(res.pokemon, res.user);
                        setPokemonPrincipal(pokemon);
                    }
                });
            } else if (selectedBoost.const === 'vitamins') {
                await userPokemonService.addEV(selectedBoost).then((res) => {
                    if (res.success) {
                        updateUser(res.user);
                        const pokemon = getStats(res.pokemon, res.user);
                        setPokemonPrincipal(pokemon);
                    }
                });
            }
            //setModalVisible(false);
            setSelectedItemId(null);
        }
    };

    const handleCancel = () => {
        setSelectedItemId(null);
        setModalVisible(false);
    };

    const handleOpenPopup = () => {
        setInItemSelection(true);
        setModalVisible(true);
    };

    const createImageSrc = (item: any) => {
        if (!item.img.data) return
        const buffer = Buffer.from(item.img.data)
        const base64String = buffer.toString('base64')
        const src = `data:images/png;base64,${base64String}`
        return src
    }

    const filteredInventory = userProfile.inventory
        .filter((item: any) => {
            return (item.const === 'stat-boosts' || item.const === 'vitamins') && item.quantity > 0 && item.item_name.toLowerCase().includes(searchText.toLowerCase())
        });

    return (
        <>
            <PillsButton
                style={style}
                label={label}
                icon={icon}
                iconPosition={iconPosition}
                onClick={handleOpenPopup}
            />
            <Modal
                title="Choisissez votre objet de boost"
                open={modalVisible}
                onCancel={handleCancel}
                footer={[
                    <FlatModalButton
                        key="back"
                        label="Annuler"
                        onClick={handleCancel}
                    />,
                    <FlatModalButton
                        key="go"
                        label="Utiliser"
                        onClick={handleOk}
                        disabled={!selectedItemId}
                    />]}
                okButtonProps={{ disabled: !selectedItemId }}
            >
                {inItemSelection && (<>
                    <Input.Search
                        placeholder="Nom de l'objet"
                        onChange={(e) => setSearchText(e.target.value)}
                        style={{ marginBottom: '15px' }}
                        size='large'
                    />

                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px', textAlign: 'center', justifyContent: 'center', maxHeight: '35vh', overflowY: 'auto' }}>

                        {filteredInventory.map((item: any) => (
                            <div
                                key={item.item_name}
                                style={{
                                    marginTop: '10px',
                                    border: selectedItemId === item.item_ID ? '3px solid rgb(255, 150, 123)' : '3px solid transparent',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleCardClick(item.item_ID)}
                            >
                                <Tooltip placement="top" title={item.description}>
                                    <Badge count={item.quantity} style={{ backgroundColor: '#02133b' }} overflowCount={99}>
                                        <Card style={{ width: 120, backgroundColor: '#e8eddc', borderRadius: '3px' }} size='small'>
                                            <div style={{ textAlign: 'center', fontWeight: '600' }}>{item.item_name}</div>
                                            <img src={createImageSrc(item)} alt={item.item_name} style={{ width: 'auto', height: '30px', objectFit: 'cover', margin: 'auto', display: 'block' }} />
                                            <BoostPanel userPokemonWithStats={userPokemonWithStats} item={item} userProfile={userProfile} />
                                        </Card>
                                    </Badge>
                                </Tooltip>
                            </div>
                        ))
                        }
                    </div> </>)}
            </Modal>
        </>
    );
};

export default BoostButton;
