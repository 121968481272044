import { useState } from "react";
import RareCandy from '../../assets/images/rare-candy.png';
import './ShopSection.css';
import OverlayShop from "./OverlayShop";
import backgroundImageUrl from '../../assets/images/background-pokedex.png';
import { useSelector } from "react-redux";
import { State } from "../../state";
import NavigationBar from "./NavigationBar";
import PokedexSection from "./PokedexSection";

type PokedexMenuProps = {
    updateUser: (userProfile: any) => void;
    userProfile: any;
};

const PokedexMenu: React.FC<PokedexMenuProps> = ({ userProfile, updateUser }) => {

    const [dialogCommand, setDialogCommand] = useState<any>(null);
    const [, setDialogType] = useState<string | number | null>(null);

    const userPokemonWithStats = useSelector((state: State) => state.userPokemon);
    // Fonction pour définir la commande et le type de dialogue
    const handleDialogCommand = (command: any) => {
        setDialogCommand(command);
        setDialogType(command.type ?? null);
    };

    const centerStyle: React.CSSProperties = {
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        zIndex: 10,
    };

    return (
        <>
            <OverlayShop
                backgroundImage={backgroundImageUrl}
                overlayImage={''}
                style={{ marginBottom: '15px', height:'150px' }}
                money={userProfile.money}
                dna={userProfile.adnMoney}
                dialogObject={{
                    npcName: userProfile.username,
                    text: dialogCommand ? <> Oh, <b>{new Intl.NumberFormat().format(Number(dialogCommand))}</b><img src={RareCandy} alt="money" /> !</> : <>Texte par défaut</>,
                    isVisible: dialogCommand !== null,
                    hideAfter: 10000,
                    onHideAfter: () => setDialogCommand(null)
                }}
                lives={userPokemonWithStats.life}
            />
            <PokedexSection updateUser={updateUser} userProfile={userProfile}/>
            <NavigationBar userProfile={userProfile} updateUser={updateUser} style={centerStyle} onButtonMonneyClick={handleDialogCommand} />
        </>
    );
}

export default PokedexMenu;