import { Action } from '../actions/index';
import { ActionType } from '../action-types';
import User from '../../interface/User';
import { Schema } from 'mongoose';

const initialState : User = {
  userId: '',
  username: '',
  discriminator: '',
  avatar: '',
  email: '',
  money: 0,
  pokemon_principal_id: new Schema.Types.ObjectId(''),
  last_pokemon: new Schema.Types.ObjectId(''),
  nb_pokemon_collection: 0,
  higher_pokemon_level: 0,
  last_payment: new Date(),
  _id: 'undefined',
  inventory: [],
  isNew: false,
  enabled: false,
  equipments: []
}

const userReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.SET_USER:
      return action.payload;
    case ActionType.REMOVE_USER:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
