import React, { useEffect, useState } from 'react';
import '../../assets/styles/InstallButton.css';
import FlatButton from './FlatButton';
import { DownloadOutlined, CloseOutlined } from '@ant-design/icons';
import { ShareIcon } from '../SVGIcons';

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}
interface BeforeInstallPromptEvent extends Event {
  readonly platforms: ReadonlyArray<string>;
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed',
    platform: string
  }>;
  prompt(): Promise<void>;
}

const InstallButton: React.FC = () => {
  const [showInstallButton, setShowInstallButton] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);
  const [userAgent, setUserAgent] = useState<string>('');

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: Event) => {
      event.preventDefault();
      const beforeInstallPromptEvent = event as BeforeInstallPromptEvent;
      setShowInstallButton(true);
      setDeferredPrompt(beforeInstallPromptEvent);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt as EventListener);

    // Vérifie si l'application est déjà installée en mode standalone
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    setShowInstallButton(!isStandalone);

    // Déterminer les instructions en fonction du navigateur
    setUserAgent(window.navigator.userAgent);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt as EventListener);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: { outcome: 'accepted' | 'dismissed' }) => {
        if (choiceResult.outcome === 'accepted') {
          console.log("L'utilisateur a installé l'application");
          setShowInstallButton(false);
        } else {
          console.log("L'utilisateur a refusé l'installation de l'application");
        }
      });
    }
  };

  const handleCloseClick = () => {
    setShowInstallButton(false);
  };

  return (
    <>
      {showInstallButton && (
        <div className="install-button-container">
          <div className="install-banner">
            <p>{(/iPad|iPhone|iPod/.test(userAgent)) ?
              <span>
                Pour installer l'application sur iOS, appuyez sur <ShareIcon style={{fontSize:'25px'}}/> en bas de l'écran et sélectionnez "Sur l'écran d'accueil".
              </span>
              :
              "Pour une meilleure expérience, il est recommandé d'ajouter l'application à votre écran d'accueil."
            }
            </p>
            <br />
            {(/iPad|iPhone|iPod/.test(userAgent)) ?
              null
              :
              <FlatButton color='#fd9779' onClick={handleInstallClick} label='Installer' icon={<DownloadOutlined />} style={{ marginBottom: '20px', marginRight: '15px' }} />
            }
            <FlatButton color='#fffee5' textColor='#000000' onClick={handleCloseClick} label='' icon={<CloseOutlined />} style={{marginBottom:'15px'}} />
          </div>
        </div>
      )}
    </>
  );
};

export default InstallButton;
