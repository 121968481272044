import React, { useEffect, useState } from 'react';
import CircleButton from './CircleButton';
import { HomeIcon, ShopIcon, WalkIcon } from '../SVGIcons';
import RareCandy from '../../assets/images/rare-candy.png';
import PillsButton from './PillsButton';
import { TaskService } from '../../services/TaskService';
import { useNavigate } from 'react-router-dom';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

interface NavigationBarProps {
    userProfile: any;
    updateUser: (userProfile: any) => void;
    style?: React.CSSProperties;
    onButtonMonneyClick: (command: string) => void;
    battleMode?: boolean;
    autoBattleClick?: () => void;
    speedClick?: () => void;
    speed?: number;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ userProfile, updateUser, style, onButtonMonneyClick, battleMode = false, autoBattleClick = () => {}, speedClick = () => {}, speed = 1 }) => {

    const taskService = new TaskService()

    const navigate = useNavigate();

    const [canGetPaid, setCanGetPaid] = useState(false);
    const [autoBattle, setAutoBattle] = useState(false);

    useEffect(() => {
        // Fonction pour vérifier et mettre à jour l'état
        const updateCanGetPaid = () => {
            setCanGetPaid(checkDate(userProfile));
        };

        // Appeler immédiatement pour initialiser
        updateCanGetPaid();

        // Configurer l'intervalle
        const interval = setInterval(updateCanGetPaid, 10000); // Exemple : toutes les minutes

        // Nettoyer l'intervalle lors du démontage du composant
        return () => clearInterval(interval);
    }, [userProfile]); // Dépendances de l'effet


    const handleButtonClick = (destination: any) => {
        switch (destination) {
            case 'home':
                navigate('/');
                break;
            case 'shop':
                navigate('/shop');
                break;
            case 'walk':
                navigate('/battle-menu');
                break;
            default:
                break;
        }
    };

    const getPaid = async (userLocal: any) => {
        if (checkDate(userLocal)) {
            return await taskService.bePaid().then((res) => {
                if (res.success) {
                    updateUser(res.user);
                    onButtonMonneyClick(res.amountPaid);
                }
            })
        }
    }

    const checkDate = (userLocal: any) => {
        const targetDate = new Date(userLocal.last_payment);
        const currentTime = new Date();
        const timeDifferenceInMilliseconds = currentTime.getTime() - targetDate.getTime();
        const timeDifferenceInMinutes = timeDifferenceInMilliseconds / (1000 * 60);
        return timeDifferenceInMinutes >= 10;
    }

    const changeAutoBattle = () => {
        setAutoBattle(!autoBattle);
        autoBattleClick();
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '0px',
            ...style,
        }}>
            <CircleButton key='home' icon={<HomeIcon />} color="#fffde9" iconColor='black' onClick={() => handleButtonClick('home')} style={{ margin: '10px' }} />
            { !battleMode && <CircleButton key='shop' icon={<ShopIcon style={{ marginRight: '3px' }} />} color="#fffde9" iconColor='black' onClick={() => handleButtonClick('shop')} style={{ margin: '10px' }} />}
            <CircleButton key='walk' icon={<WalkIcon style={{ marginRight: '3px' }} />} color="#fffde9" iconColor='black' onClick={() => handleButtonClick('walk')} style={{ margin: '10px' }} />
            {!battleMode &&
                <PillsButton
                    key='getmoney'
                    icon={
                        <img
                            src={RareCandy}
                            alt='money'
                            style={{ width: '50px', marginLeft: '5px' }}
                            className={`block-centered ${canGetPaid ? 'hithere' : ''}`}
                        />
                    }
                    onClick={() => canGetPaid && getPaid(userProfile)}
                    style={{ margin: '10px', height: '45px' }}
                    label={canGetPaid ? 'Récupérer' : ''}
                />}
            {userProfile.inventory.find((item: any) => item.item_ID === 9999) && battleMode &&
                <PillsButton
                    key='autobattle'
                    icon={autoBattle ? <CheckOutlined /> : <CloseOutlined />}
                    onClick={() => changeAutoBattle()}
                    style={{ margin: '10px', height: '45px' }}
                    label={'Auto'}
                    iconPosition='right'
                />}
            {userProfile.inventory.find((item: any) => item.item_ID === 9998 || item.item_ID === 9997 || item.item_ID === 9996) && battleMode &&
                <CircleButton key='speedup' icon={<span>x{speed}</span>} color="#fffde9" iconColor='black' onClick={() => speedClick()} style={{ margin: '10px' }} />    
            }
        </div>
    );
};

export default NavigationBar;
