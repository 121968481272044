import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';
import './../assets/styles/audioplayer.css';

interface AudioPlayerProps {
    audioSrc: string;
    playOnLoad: boolean;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ audioSrc, playOnLoad }) => {
    const [musicSrc] = useState(audioSrc);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [isPlaying, setIsPlaying] = useState(playOnLoad);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.src = musicSrc;
            audioRef.current.volume = 0.25;
        }
    }, [musicSrc]);

    useEffect(() => {
        if (audioRef.current) {
            const currentAudioRef = audioRef.current;
            audioRef.current.play();
            currentAudioRef.addEventListener('loadedmetadata', handleLoadedMetadata);
            return () => {
                currentAudioRef.removeEventListener('loadedmetadata', handleLoadedMetadata);
            };
        }
    }, [audioRef]);

    const togglePlay = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleLoadedMetadata = () => {
        if (audioRef.current) {
            const duration = audioRef.current.duration;
            const randomTime = Math.random() * duration;
            audioRef.current.currentTime = randomTime;
            if (playOnLoad) {
                audioRef.current.play();
                audioRef.current.play();
            } else {
                audioRef.current.pause();
            }
            setIsPlaying(playOnLoad);
        }
    };

    return (
        <div className="audio-player">
            <div className="audio-controls">
                <div className="play-pause-icon" onClick={togglePlay}>
                    {isPlaying ? <PauseOutlined /> : <CaretRightOutlined />}
                </div>
            </div>
            <audio ref={audioRef} src={audioSrc} loop />
        </div>
    );
};

export default AudioPlayer;
