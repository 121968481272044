import React, { CSSProperties } from 'react';
import './OverlayImageStyles.css';
import RetroTextBox from './RetroTextBox';
import HealthBar from './HealthBar';
import NameBar from './NameBar';
import trainerRocket from '../../assets/images/trainers/TrainerRocket.png';
import trainerCasino from '../../assets/images/trainers/TrainerCasino.png';

interface OverlayBattleProps {
  backgroundImage: string; // URL de l'image de fond
  userPokemonImage: string; // URL de l'image à superposer
  enemyImage: string; // URL de l'image à superposer
  enemyHP: number;
  enemyHPMax: number;
  userPokemonHP: number;
  userPokemonHPMax: number;
  userPokemonName: string;
  enemyPokemonName: string;
  userPokemonLevel: number;
  enemyPokemonLevel: number;
  style?: React.CSSProperties
  dialogObject?: {
    npcName?: string;
    text: any;
    isVisible: boolean;
    hideAfter?: number; // Temps en millisecondes
    onHideAfter?: () => void;
  },
  typeFight: string;
  formatHP?: string;
  userPokemonImageClassName: any;
  enemyPokemonImageClassName: any;
}

const OverlayBattle: React.FC<OverlayBattleProps> = ({ userPokemonImageClassName, enemyPokemonImageClassName, formatHP, dialogObject = { onHideAfter: () => {}, npcName: 'Pr. Chen', text: 'Salut !', isVisible: false, hideAfter: 10000 }, backgroundImage, userPokemonImage, style, enemyHP, enemyHPMax, userPokemonHP, userPokemonHPMax, enemyImage, userPokemonName, userPokemonLevel, enemyPokemonName, enemyPokemonLevel, typeFight }) => {

  const imageTrainer = () => {
    if (typeFight.indexOf('CASINO') !== -1) {
      return trainerCasino;
    } else if (typeFight.indexOf('ROCKET') !== -1) {
      return trainerRocket;
    } else
      return '';
  }

  const styleTrainer = (): CSSProperties => {
    if (typeFight.indexOf('CASINO') !== -1) {
      return {
        position: 'absolute',
        bottom: '10%',
        right: '5%',
        width: 'auto', // La largeur est 'auto' pour éviter la déformation
        height: '55%', // Ajustez la hauteur pour mieux adapter l'image dans son conteneur
        filter: enemyHP <= 0 ? 'grayscale(100%)' : undefined // Applique un filtre gris si l'ennemi est vaincu
      };
    } else if (typeFight.indexOf('ROCKET') !== -1) {
      return {
        position: 'absolute',
        bottom: '10%',
        right: '5%',
        width: 'auto', // La largeur est 'auto' pour éviter la déformation
        height: '45%', // Ajustez la hauteur pour mieux adapter l'image dans son conteneur
        filter: enemyHP <= 0 ? 'grayscale(100%)' : undefined // Applique un filtre gris si l'ennemi est vaincu
      };
    } else {
      return {
        display: 'none'
      };
    }
  };

  return (
    <div style={{
      position: 'relative',
      width: '100%',
      paddingTop: '50%',
      height: '0',
      overflow: 'hidden',
      borderColor: 'black',
      borderWidth: '2px',
      borderStyle: 'solid',
      ...style
    }}>
      <img
        src={backgroundImage}
        alt="Background"
        style={{
          position: 'absolute',
          bottom: '-17%', // Aligner l'image de fond vers le bas du conteneur
          left: '0',
          width: '100%', // Assurez-vous que l'image couvre le conteneur
          height: 'auto', // Hauteur automatique pour éviter la déformation
          objectFit: 'cover', // Couvre le conteneur sans déformation
          objectPosition: 'bottom', // Assurez-vous que le bas de l'image est visible
        }}
      />
      <img
        src={userPokemonImage}
        alt="Pokémon partenaire"
        style={{
          position: 'absolute',
          bottom: '14%',
          left: '18%',
          width: 'auto', // Ajustez la largeur à 'auto' pour éviter la déformation
          height: '45%', // Ajustez la hauteur pour que le Pokémon s'intègre mieux
          filter: userPokemonHP <= 0 ? 'grayscale(100%)' : undefined,
        }}
        className={`${userPokemonImageClassName} breathe-animation-battle-ally`}
      />
      <img
        src={enemyImage}
        alt="Pokémon adversaire"
        style={{
          position: 'absolute',
          bottom: '14%',
          right: '15%',
          width: 'auto', // Ajustez la largeur à 'auto' pour éviter la déformation
          height: '45%', // Ajustez la hauteur pour que le Pokémon s'intègre mieux
          filter: enemyHP <= 0 ? 'grayscale(100%)' : undefined
        }}
        className={`${enemyPokemonImageClassName} breathe-animation-battle`}
      />
      {imageTrainer() === '' ? <></> :
        <img
          src={imageTrainer()}
          alt="Trainer adversaire"
          style={styleTrainer()}
          className='breathe-animation-battle'
        />
      }
      <div className="pokemon-health-bars">
        <HealthBar
          hp={userPokemonHP}
          maxHp={userPokemonHPMax}
          isEnemy={false}
        />

        <HealthBar
          hp={enemyHP}
          maxHp={enemyHPMax}
          isEnemy={true}
          isPercent={formatHP === 'percent'}
        />
      </div>
      <div className="pokemon-name-bars">
        <NameBar name={userPokemonName} level={userPokemonLevel} />
        <NameBar name={enemyPokemonName} level={enemyPokemonLevel} />
      </div>
      <RetroTextBox
        npcName={dialogObject.npcName}
        text={dialogObject.text}
        isVisible={dialogObject.isVisible}
        hideAfter={dialogObject.hideAfter} // Masquer après 5000 millisecondes (5 secondes)
        onHideAfter={dialogObject.onHideAfter}
      />
    </div>
  );
};

export default OverlayBattle;
