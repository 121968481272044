import React, { useEffect, useState } from 'react';
import OverlayShop from './OverlayShop';
import backgroundImageUrl from '../../assets/images/background-buildings.png';
import NavigationBar from './NavigationBar';
import RareCandy from '../../assets/images/rare-candy.png';
import imageShop from '../../assets/images/icnBuildings/mart.png';
import imageDojo from '../../assets/images/icnBuildings/dojo.png';
import imageLabo from '../../assets/images/icnBuildings/labo.png';
import imageTechno from '../../assets/images/icnBuildings/techno.png';
import { ShopItemService } from '../../services/ShopItemService';
import { BuildingService } from '../../services/BuildingService';
import { InfoCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import '../../assets/styles/building.css';
import { Button, Card, Col, Modal, Row } from 'antd';
import { DNAIcon } from '../SVGIcons';
import { useSelector } from 'react-redux';
import { State } from '../../state';

const buildingImageShop = imageShop;
const buildingImageDojo = imageDojo;
const buildingImageLabo = imageLabo;
const buildingImageTechno = imageTechno;
let shopItemsLocal: any = []
let buildingLevelsLocal: any = []

type BuildingMenuProps = {
    updateUser: (userProfile: any) => void;
    userProfile: any;
}

const BuildingMenu: React.FC<BuildingMenuProps> = ({ userProfile, updateUser }) => {

    const userPokemonWithStats = useSelector((state: State) => state.userPokemon);

    const [dialogCommand, setDialogCommand] = useState<any>(null);
    const [, setDialogType] = useState<string | number | null>(null);

    // Fonction pour définir la commande et le type de dialogue
    const handleDialogCommand = (command: any) => {
        setDialogCommand(command);
        setDialogType(command.type ?? null);
    };

    const centerStyle: React.CSSProperties = {
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        zIndex: 10,
    };

    const buildingService = new BuildingService();
    const shopItemService = new ShopItemService();

    // État pour stocker le niveau actuel de chaque bâtiment
    const [buildingLevels, setBuildingLevels] = useState<any>([]);
    const [countdowns, setCountdowns] = useState<{ [key: string]: string }>({});
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedBuildingType, setSelectedBuildingType] = useState('');

    useEffect(() => {
        const intervalId = setInterval(() => {
            const updatedCountdowns: { [key: string]: string } = {};
            buildingLevels.forEach((building: any) => {
                const countdown = calculTimeBeforeNextUpgrade(building);
                updatedCountdowns[building._id] = countdown;
            });

            setCountdowns(updatedCountdowns);
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [buildingLevels]);

    const getFormattedTime = (seconds: number) => {
        if (seconds < 60) {
            return `${seconds} s`;
        } else if (seconds < 3600) {
            const minutes = Math.round(seconds / 60);
            return `${minutes} min`;
        } else if (seconds < 86400) {
            const hours = Math.round(seconds / 3600);
            return `${hours} h`;
        } else {
            const days = Math.round(seconds / 86400);
            return `${days} jours`;
        }
    };

    const calculTimeBeforeNextUpgrade = (building: any) => {
        const timeBeforeNextLevel = new Date(building.timeBeforeNextLevel);
        const now = new Date();
        const timeBeforeNextBossFight = timeBeforeNextLevel.getTime() - now.getTime();
        const hours = Math.floor((timeBeforeNextBossFight % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeBeforeNextBossFight % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeBeforeNextBossFight % (1000 * 60)) / 1000);
        if (timeBeforeNextBossFight > 0) {
            return `${hours}h ${minutes}m ${seconds}s`;
        } else
            return `Amélioration disponible !`;
    }

    // Fonction pour améliorer un bâtiment
    const upgradeBuilding = async (buildingId: string) => {
        await buildingService.upgradeBuilding(buildingId).then((res) => {
            if (res.success) {
                const buildList = res.buildList;
                let buildingDatas: any = [];
                buildList.forEach((building: any) => {
                    const baseCost = building.buildingDetails.baseCost;
                    const baseTime = building.buildingDetails.baseTime;
                    const level = building.userBuilding.level;
                    const cost = eval(building.buildingDetails.costFormula);
                    const time = eval(building.buildingDetails.timeFormula);

                    let buildingObject = {
                        _id: building.buildingDetails._id,
                        name: building.buildingDetails.name,
                        type: building.buildingDetails.type,
                        level: building.userBuilding.level,
                        cost: cost,
                        time: time,
                        timeBeforeNextLevel: building.userBuilding.timeBeforeNextLevel || null,
                    }
                    buildingDatas.push(buildingObject);
                });
                setBuildingLevels(buildingDatas);
                updateUser(res.user);
            }
        });
    };

    // Effet pour gérer la mise à jour de l'interface utilisateur
    useEffect(() => {
        if (buildingLevelsLocal !== buildingLevels) {
            getBuildings();
            defineShopItems();
        }
    }, []);

    const defineShopItems = async () => {
        await shopItemService.getAllShopItems().then((data) => {
            if (shopItemsLocal !== data) {
                shopItemsLocal = data;
            }
        });
    }

    const getBuildings = async () => {
        await buildingService.getBuildingsForUser().then((res) => {
            if (res) {
                let buildingDatas: any = [];
                res.forEach((building: any) => {
                    const baseCost = building.buildingDetails.baseCost;
                    const baseTime = building.buildingDetails.baseTime;
                    const level = building.userBuilding.level;
                    const cost = eval(building.buildingDetails.costFormula);
                    const time = eval(building.buildingDetails.timeFormula);

                    let buildingObject = {
                        _id: building.buildingDetails._id,
                        name: building.buildingDetails.name,
                        type: building.buildingDetails.type,
                        level: building.userBuilding.level,
                        cost: cost,
                        time: time,
                        timeBeforeNextLevel: building.userBuilding.timeBeforeNextLevel || null,
                    }
                    buildingDatas.push(buildingObject);
                });
                setBuildingLevels(buildingDatas);
                buildingLevelsLocal = buildingDatas;
            }
        });
    }

    const handleModalToggle = (buildingType: string) => {
        setSelectedBuildingType(buildingType);
        setModalVisible(!modalVisible);
    };

    const generateTimeline = () => {
        const currentBuildingLevels = buildingLevels.find((building: any) => building.type === selectedBuildingType);

        const buildingShopItems = shopItemsLocal
            .filter((item: any) => item.buildingType === selectedBuildingType)
            .sort((a: any, b: any) => a.buildingLevel - b.buildingLevel);

        const groupedItems = buildingShopItems.reduce((acc: any, item: any) => {
            const level = item.buildingLevel;
            if (!acc[level]) {
                acc[level] = [];
            }
            acc[level].push(item);
            return acc;
        }, {});

        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {Object.keys(groupedItems).map((level, index, levels) => (
                    <React.Fragment key={level}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            {groupedItems[level].map((item: any) => (
                                <div key={item._id} style={{ margin: '0 10px', textAlign: 'center' }}>
                                    <p className='timeline-item-name'>
                                        {item.item.item_name}
                                        <img src={createImageItem(item)} alt={item.item.item_name} style={{ maxWidth: '50px', marginLeft: '5px' }} />
                                    </p>
                                </div>
                            ))}
                        </div>
                        {currentBuildingLevels.level >= parseInt(level) ? (
                            <CheckCircleOutlined style={{ color: 'green', margin: '5px 0', fontSize: '22px' }} />
                        ) : (
                            <p style={{ margin: '5px 0' }}><b>Disponible au niveau {level}</b></p>
                        )}
                        {index < levels.length - 1 && (
                            <div style={{ width: '2px', height: '20px', backgroundColor: 'black', margin: '10px 0' }}></div>
                        )}
                    </React.Fragment>
                ))}
            </div>
        );
    };

    const createImageItem = (item: any) => {
        if (!item) return '';

        let src = '';
        const buffer = Buffer.from(item.item.img.data.data);
        if (typeof item.item.img.data.data === 'string') {
            src = `data:image/png;base64,${buffer}`
        } else {
            const base64String = buffer.toString('base64');
            src = `data:image/png;base64,${base64String}`;
        }

        return src;
    };

    return (
        <>
            <OverlayShop
                backgroundImage={backgroundImageUrl}
                overlayImage={''}
                style={{ marginBottom: '15px' }}
                money={userProfile.money}
                dna={userProfile.adnMoney}
                dialogObject={{
                    npcName: dialogCommand ? userProfile.username : 'Pr. Gamble',
                    text: dialogCommand ? <> Oh, <b>{new Intl.NumberFormat().format(Number(dialogCommand))}</b><img src={RareCandy} alt="money" /> !</> : <><span>Utilisez de l'ADN pour améliorer les bâtiments. L'ADN s'obtient en transmutant les Pokémons</span></>,
                    isVisible: true || dialogCommand !== null,
                    hideAfter: 15000,
                    onHideAfter: () => setDialogCommand(null)
                }}
                lives={userPokemonWithStats.life}
            />
            <div className="shop-section-card">
                <div className="shop-section" style={{ gap: '0.5em' }}>
                    {buildingLevels.map((building: any) => {
                        return (
                            <Card style={{
                                display: 'flex',
                                backgroundColor: '#f8f8f8',
                                borderStyle: 'solid',
                                borderColor: 'black',
                                borderWidth: '2px',
                                boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02)',
                                cursor: 'pointer',
                                marginRight: 'auto',
                                marginLeft: 'auto',
                                width: 'calc(100% - 20px)', // Prend toute la largeur moins 20px pour l'espace
                            }}>
                                <Row gutter={16} style={{ width: '100%' }}>
                                    <Col span={8}> {/* Augmentez le span si vous voulez que l'image soit plus grande */}
                                        <img src={building.type === 'shop' ? buildingImageShop : building.type === 'dojo' ? buildingImageDojo : building.type === 'experimental' ? buildingImageTechno : buildingImageLabo} alt={building.name} style={{
                                            width: '100%', // L'image prend 100% de la largeur de Col
                                            height: 'auto', // Ajuste la hauteur pour maintenir les proportions
                                            objectFit: 'fill', // Ajuste l'image pour couvrir l'espace disponible
                                            borderRadius: '5px', // Applique un arrondi aux coins de l'image
                                            minWidth: '70px', // Largeur maximale de l'image
                                            maxWidth: '90px', // Largeur maximale de l'image

                                        }} />
                                    </Col>
                                    <Col span={15}>
                                        <div style={{ marginTop: '0.5em', display: 'grid', fontWeight: '600' }}>
                                            <span>{building.name} - Niveau : {building.level} <InfoCircleOutlined onClick={() => handleModalToggle(building.type)} style={{ cursor: 'pointer' }} /></span>
                                            {new Date(building.timeBeforeNextLevel).getTime() < new Date().getTime() ? (
                                                <>
                                                    <span>Coût : {building.cost} <DNAIcon /></span>
                                                    <span>Temps avant prochaine amélioration : {getFormattedTime(building.time)}</span>
                                                </>) : (
                                                <>
                                                    <p>Amélioration suivante dans : {countdowns[building._id]}</p>
                                                </>
                                            )}
                                            <Button type="primary"
                                                style={{
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    backgroundColor: new Date(building.timeBeforeNextLevel).getTime() > new Date().getTime() || building.cost > userProfile.adnMoney ? '#dfdfdf' : '#fd9779', // Couleur de fond du bouton
                                                    borderColor: 'black', // Couleur de la bordure du bouton
                                                    width: 'auto', // Largeur fixe à 80% de la taille de la card
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    borderRadius: '15px',
                                                    borderStyle: 'solid',
                                                    borderWidth: '2px',
                                                    borderBottomWidth: '4px',
                                                    marginTop: '0.5em',
                                                    maxWidth: '120px',
                                                }}
                                                disabled={new Date(building.timeBeforeNextLevel).getTime() > new Date().getTime() || building.cost > userProfile.adnMoney}
                                                onClick={() => upgradeBuilding(building._id)}>
                                                Améliorer
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        )
                    })
                    }
                </div>
            </div>
            <NavigationBar userProfile={userProfile} updateUser={updateUser} style={centerStyle} onButtonMonneyClick={handleDialogCommand} />
            <Modal
                title="Améliorations disponibles"
                open={modalVisible}
                onCancel={() => handleModalToggle('')}
                footer={null}
            >
                <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                    {generateTimeline()}
                </div>
            </Modal>
        </>
    )
};

export default BuildingMenu;
