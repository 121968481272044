import React from 'react';
import './OverlayImageStyles.css';
import PillsButton from './PillsButton';
import RareCandy from '../../assets/images/rare-candy.png';
import { DNAIcon } from '../SVGIcons';
import RetroTextBox from './RetroTextBox';
import PokemonLives from './PokemonLives';

interface OverlayShopProps {
  backgroundImage: string; // URL de l'image de fond
  overlayImage?: string; // URL de l'image à superposer
  style?: React.CSSProperties
  money: number;
  dna: number;
  dialogObject?: {
    npcName?: string;
    text: any;
    isVisible: boolean;
    hideAfter?: number; // Temps en millisecondes
    onHideAfter?: () => void;
  };
  lives: number;
}

const OverlayShop: React.FC<OverlayShopProps> = ({ lives, dialogObject = { onHideAfter: () => {}, npcName: 'Pr. Chen', text: 'Salut !', isVisible: false, hideAfter: 10000 }, backgroundImage, overlayImage, style, money, dna }) => {

  return (
    <div style={{
      position: 'relative',
      width: '100%',
      paddingTop: '50%',
      height: '0',
      overflow: 'hidden',
      borderColor: 'black',
      borderWidth: '2px',
      borderStyle: 'solid',
      ...style
    }}>
      <img
        src={backgroundImage}
        alt="Background"
        style={{
          position: 'absolute',
          bottom: '0', // Aligner l'image de fond vers le bas du conteneur
          left: '0',
          width: '100%', // Assurez-vous que l'image couvre le conteneur
          height: 'auto', // Hauteur automatique pour éviter la déformation
          objectFit: 'cover', // Couvre le conteneur sans déformation
          objectPosition: 'bottom', // Assurez-vous que le bas de l'image est visible
        }}
      />
      <PokemonLives lives={lives} style={{
        position: 'absolute',
        top: '1.9%',
        left: '1%',
      }} />
      {overlayImage && // Si une image à superposer est fournie}
        <img
          src={overlayImage}
          alt="Pokémon partenaire"
          style={{
            position: 'absolute',
            bottom: '5%',
            left: '40%',
            width: 'auto', // Ajustez la largeur à 'auto' pour éviter la déformation
            height: '55%', // Ajustez la hauteur pour que le Pokémon s'intègre mieux
          }}
          className='breathe-animation-shop'
        />
      }
      <RetroTextBox
        npcName={dialogObject.npcName}
        text={dialogObject.text}
        isVisible={dialogObject.isVisible}
        hideAfter={dialogObject.hideAfter} // Masquer après 5000 millisecondes (5 secondes)
        onHideAfter={dialogObject.onHideAfter}
      />
      <div style={{
        position: 'absolute',
        top: '3%',
        right: '1%',
        display: 'flex',
        flexDirection: 'row',
      }}>
        <PillsButton
          label={new Intl.NumberFormat().format(money)}
          icon={<img src={RareCandy} alt='money' />}
          onClick={() => {}}
          style={{
            marginRight: '8px', // Ajoutez une marge à droite
            borderRadius: '3px',
            backgroundColor: 'rgba(255, 253, 233,0.5)',
            border: '4px solid rgba(255, 253, 233,0.1)'
          }}
          iconPosition='right'
        />
        <PillsButton
          label={new Intl.NumberFormat().format(dna)}
          icon={<DNAIcon />}
          onClick={() => {}}
          style={{
            borderRadius: '3px',
            backgroundColor: 'rgba(255, 253, 233,0.5)',
            border: '4px solid rgba(255, 253, 233,0.1)'
          }}
          iconPosition='right'
        />
      </div>
    </div>
  );
};

export default OverlayShop;
