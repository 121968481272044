import './ShopSection.css';
import Item from "./Item";

type BagSectionProps = {
    updateUser: (userProfile: any) => void;
    userProfile: any;
};

const BagSection: React.FC<BagSectionProps> = ({ userProfile, updateUser }) => {

    const createImageItem = (item: any) => {
        if (!item) return '';
        if (!item.img) return '';
        if (!item.img.data) return '';
        if (!item.img.data.data) return '';
        
        let src = '';
        const buffer = Buffer.from(item.img.data.data);
        if (typeof item.img.data.data === 'string') {
          src = `data:image/png;base64,${buffer}`;
        } else {
          const base64String = buffer.toString('base64');
          src = `data:image/png;base64,${base64String}`;
        }
    
        return src;
      };

    return (
        <>
            <div className="shop-section-card">
                <div className="shop-section">
                    {userProfile.inventory.map((item: any) => {
                        return (
                            <Item style={{ borderColor:'black', borderWidth: '2px' }} updateUser={updateUser} itemId={item._id} type={item.const} exp={item.exp} img={createImageItem(item)} description={item.description} effect={item.effect} quantity={item.quantity} name={item.item_name} />
                        )
                    })}
                </div>
            </div>
        </>
    );
}

export default BagSection;