import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import RareCandy from '../../assets/images/rare-candy.png';
import { State, actionCreators } from '../../state';
import { WalkIcon } from '../SVGIcons';
import backgroundFight1 from './../../assets/images/background-fight-1.png';
import NavigationBar from './NavigationBar';
import OverlayBattle from './OverlayBattle';
import TimelineElement from './TimelineElement';
import { getStats, UserPokemonService } from '../../services/UserPokemonService';
import FlatButton from './FlatButton';
import OverlayLoading from './OverlayLoading';
import './BattleElement.css';
import OverlayShop from './OverlayShop';
import backgroundImageUrl from '../../assets/images/background-battlemenu.png';
import Item from './Item';

interface WalkProps {
    updateUser: (userProfile: any) => void;
    userProfile: any;
}

let fightObject: any = {
    walk: {},
    enemyImage: "",
    fightBegin: false,
    message: "",
    type: "LOADING"
}

const WalkElement: React.FC<WalkProps> = ({ updateUser, userProfile }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [timelineItems, setTimelineItems] = useState<any[]>([]); // State to hold timeline items
    const lastTimelineElementRef = useRef<HTMLDivElement>(null); // Référence pour le dernier élément de la timeline
    const [enemyHP, setEnemyHP] = useState(0);
    const [userPokemonHP, setUserPokemonHP] = useState(0);
    const [autoBattle, setAutoBattle] = useState(false);
    const MIN_SPEED = 1000;
    // Check if user had 9998, 9997 or 9996 item in inventory and add maximal value of effect[1] on variable
    let maxSpeed = userProfile.inventory.filter((item: any) => item.item_ID === 9998 || item.item_ID === 9997 || item.item_ID === 9996).reduce((acc: number, item: any) => item.effect[1], 1);

    const [battleSpeed, setBattleSpeed] = useState(MIN_SPEED / maxSpeed);

    const [fightObjectState, setFightObjectState] = useState<any>({
        walk: {},
        enemyImage: "",
        fightBegin: false,
        message: "",
        type: ""
    });

    // Ajoutez un état pour gérer si l'animation doit être jouée
    const [userPokemonIsAttacking, setUserPokemonIsAttacking] = useState(false);
    const [enemyPokemonIsAttacking, setEnemyPokemonIsAttacking] = useState(false);

    const [receivedItem, setReceivedItem] = useState({ img: '', name: '', quantity: 0, description: '', effect: null });

    // Fonction pour déclencher l'animation
    const triggerAttackAnimation = (attackerId: any) => {
        if (attackerId === userPokemonWithStats._id) {
            setUserPokemonIsAttacking(true);
            setTimeout(() => setUserPokemonIsAttacking(false), 800); // Durée de l'animation
        } else {
            setEnemyPokemonIsAttacking(true);
            setTimeout(() => setEnemyPokemonIsAttacking(false), 800); // Durée de l'animation
        }
    };

    const { setPokemonPrincipal } = bindActionCreators(actionCreators, dispatch);
    const backgroundFightTab = [backgroundFight1];
    const backgroundFight = backgroundFightTab[Math.floor(Math.random() * backgroundFightTab.length)];

    const userPokemonWithStats = useSelector((state: State) => state.userPokemon);
    const pokemonImageUrl = 'https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/202.png';
    const buffer = Buffer.from(userPokemonWithStats.img_front.data);
    const base64String = buffer.toString('base64');
    const pokemonImage = `data:images/png;base64,${base64String}` || pokemonImageUrl;

    const [dialogCommand, setDialogCommand] = useState<any>(null);

    const timelineContainerRef = useRef<HTMLDivElement>(null);

    const userPokemonService = new UserPokemonService()

    const handleDialogCommand = (command: string) => {
        setDialogCommand(command);
    };

    const launchWalkAction = async () => {
        if (battleSpeed === Infinity) {
            setBattleSpeed(MIN_SPEED / maxSpeed);
        }

        try {
            if (!fightObject.fightBegin) {
                const response = await userPokemonService.launchWalkAction();
                fightObject = { ...response, fightBegin: true };
            }

            if (fightObject.type === "ARGENT") {

                const itemImageSrc = RareCandy;
                const itemName = "Bonbons"; // Assurez-vous que le nom de l'item est disponible dans l'objet fightObject
                const itemQuantity = fightObject.amount; // Assurez-vous que la quantité est disponible
                const description = ''; // Assurez-vous que la description est disponible
                const effect = null; // Assurez-vous que l'effet est disponible

                setReceivedItem({ img: itemImageSrc, name: itemName, quantity: itemQuantity, description: description, effect: effect });
            } else if (fightObject.type === "OBJET") {
                let base64StringItem = '';
                if (fightObject.item.img || fightObject.item.img.data || fightObject.item.img.data.data) {
                    const bufferItem = Buffer.from(fightObject.item.img.data.data);
                    base64StringItem = bufferItem.toString('base64');
                } else {
                    base64StringItem = '';
                }
                const itemImageSrc = `data:image/png;base64,${base64StringItem}`;
                const itemName = fightObject.item.item_name; // Assurez-vous que le nom de l'item est disponible dans l'objet fightObject
                const itemQuantity = fightObject.amount; // Assurez-vous que la quantité est disponible
                const description = fightObject.item.description || ''; // Assurez-vous que la description est disponible
                const effect = fightObject.item.effect || null; // Assurez-vous que l'effet est disponible

                setReceivedItem({ img: itemImageSrc, name: itemName, quantity: itemQuantity, description: description, effect: effect });
            }

            setTimeout(() => {
                if (fightObject.type === "MORT") {
                    setTimeout(() => {
                        navigate('/');
                    }, 2000);
                }
                else if (fightObject.type === "ARGENT" || fightObject.type === "OBJET") {
                    updateUser(fightObject.user);
                }
                else {
                    if (fightObject.type.indexOf("COMBAT") !== -1) {
                        const base64StringEnnemy = fightObject.walk.enemyPokemon.img_front.data.toString('base64');
                        setFightObjectState({
                            walk: fightObject.walk,
                            enemyImage: `data:images/png;base64,${base64StringEnnemy}`,
                            fightBegin: true,
                            message: fightObject.message,
                            type: fightObject.type
                        });
                        setUserPokemonHP(userPokemonWithStats.stats.hp);
                        setEnemyHP(fightObject.walk.enemyPokemon.stats.hp);
                        setTimelineItems(() => []);
                        // Generate timeline items with delay
                        fightObject.walk.sequence.forEach((item: any, index: number) => {
                            const delay = (index + 1) * battleSpeed; // Delay in milliseconds (1 second delay between items)

                            setTimeout(async () => {
                                const color = item.attacker._id === userPokemonWithStats._id ? 'green' : 'red';
                                const attacker = item.attacker.pokemon_name;
                                const move = item.move.move_name;
                                const defenser = item.defenser.pokemon_name;
                                const damage = item.damage;

                                updateHP(item.defenser._id, item.damage);

                                let timelineItem = (
                                    <div ref={lastTimelineElementRef} key={index}>
                                        <TimelineElement mode='item' key={index + "" + item.move.move_ID} color={color} attacker={attacker} defenser={defenser} move={move} damage={damage} type={item.move.type} efficientMultiplier={item.efficientMultiplier} />
                                    </div>
                                );

                                triggerAttackAnimation(item.attacker._id);

                                setTimelineItems(prevItems => [...prevItems, timelineItem]);
                                if (index === fightObject.walk.sequence.length - 1) {
                                    endWalk(fightObject.walk);
                                }
                            }, delay);
                        });
                    }
                }
            }, 2000);
        } catch (error) {
            throw error;
        }
    };

    const newWalkAction = async (again: any) => {
        setActionLaunched(false);
        if (again) {
            fightObject = {
                walk: {},
                enemyImage: "",
                fightBegin: false,
                message: "",
                type: ""
            };
            navigate('/walk');
        } else {
            navigate('/battle-menu');
            fightObject = {
                walk: {},
                enemyImage: "",
                fightBegin: false,
                message: "",
                type: ""
            };
        }
    }

    const endWalk = async (walk: any) => {
        try {
            setActionLaunched(false);
            const walkDone = { ...walk, fightState: "Done" };
            const response = await userPokemonService.checkEndWalkAction(walkDone);
            if (response) {
                const pokemon = getStats(response.userPokemon, response.user);
                setPokemonPrincipal(pokemon);
                if ('user' in response) {
                    updateUser(response.user);
                }
            }
            // If autoBattle, wait 500ms and launch another walk action
            if (autoBattle) {
                const pokemonLife = fightObject.walk.userPokemon.life;
                const hasWonOrSpecialCondition = fightObject.walk.victory || fightObject.type === "CASINO" || fightObject.type === "ARGENT" || fightObject.type === "OBJET";

                if (pokemonLife > 1 || (pokemonLife > 0 && hasWonOrSpecialCondition)) {
                    setTimeout(() => {
                        newWalkAction(true);
                    }, 500);
                }
            }

        } catch (error) {

        }
    }

    const actionWalk = async (again: boolean) => {
        fightObject = {
            walk: {},
            enemyImage: "",
            fightBegin: false,
            message: "",
            type: ""
        };
        if (again) {
            navigate('/walk');
        } else {
            navigate('/battle-menu');
        }
    }

    const updateHP = (defenderId: any, damage: number) => {
        // Check if defenderId or fightObjectState.walk.enemyPokemon._id exists
        if (!defenderId || !fightObject.walk.enemyPokemon)
            return;
        // Update enemy's Pokemon HP
        if (defenderId === fightObject.walk.enemyPokemon._id) {
            setEnemyHP((prevHP: any) => Math.max(0, prevHP - damage));
        }

        // Update user's Pokemon HP
        if (defenderId === userPokemonWithStats._id) {
            setUserPokemonHP(prevHP => Math.max(0, prevHP - damage));
        }
    };

    const [actionLaunched, setActionLaunched] = useState(false);

    useEffect(() => {
        if (fightObject.fightBegin && fightObjectState.type === "") {
            fightObject = {
                walk: {},
                enemyImage: "",
                fightBegin: false,
                message: "",
                type: "LOADING"
            }
        }
        
        if (!fightObject.fightBegin && !actionLaunched) {
            launchWalkAction();
            setActionLaunched(true); // Empêche les appels futurs jusqu'à ce que les conditions changent
        }
    }, [fightObject.fightBegin]); // Supprimez actionLaunched des dépendances pour éviter les déclenchements répétitifs

    const centerStyle: React.CSSProperties = {
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        zIndex: 10,
    };

    const endElementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const container = timelineContainerRef.current;
        const lastItem = container?.lastChild as HTMLElement;

        if (lastItem) {
            lastItem.scrollIntoView({ behavior: "smooth" });
        }
        //lastTimelineElementRef.current?.scrollIntoView({ behavior: "smooth" });
        // Check if sequence in walk exist
        if (!fightObjectState.walk.sequence)
            return;

        if (fightObjectState.walk.sequence.length > 0 && fightObjectState.walk.sequence.length === timelineItems.length) {
            if (endElementRef.current) {
                endElementRef.current.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [timelineItems, fightObjectState.walk.sequence]); // Dépendances incluant la longueur de la séquence de combat

    const timelineElements = timelineItems.map((item, index) => {
        // Si l'élément est le dernier de la liste, attachez la référence
        if (index === timelineItems.length - 1) {
            return React.cloneElement(item, { ref: lastTimelineElementRef });
        }
        return item;
    });

    const autoBattleClick = () => {
        setAutoBattle(!autoBattle);
    }

    const speedClick = () => {
        if (battleSpeed === MIN_SPEED) {
            setBattleSpeed(MIN_SPEED / maxSpeed);
        } else {
            setBattleSpeed(MIN_SPEED);
        }
    }

    const createImageSrc = (item: any) => {
        console.log(item)
        if (!item) return ''
        if (!item.img) return '' 
        if (!item.img.data) return ''
        const buffer = Buffer.from(item.img.data)
        const base64String = buffer.toString('base64')
        const src = `data:images/png;base64,${base64String}`
        return src
      }

    return (
        <>
            {fightObjectState.fightBegin && fightObject.type.indexOf("COMBAT") !== -1 &&
                <>
                    <OverlayBattle
                        backgroundImage={backgroundFight}
                        userPokemonImage={pokemonImage}
                        dialogObject={{
                            npcName: userPokemonWithStats.pokemon_name,
                            text: dialogCommand ? <> J'ai volé à {fightObjectState.walk.enemyPokemon.pokemon_name} <b>{new Intl.NumberFormat().format(Number(dialogCommand))}</b><img src={RareCandy} alt="money" /> !</> : <>Texte par défaut</>,
                            isVisible: dialogCommand !== null,
                            hideAfter: 10000,
                            onHideAfter: () => setDialogCommand(null)
                        }}
                        enemyImage={fightObjectState.enemyImage}
                        enemyHP={enemyHP}
                        enemyHPMax={fightObjectState.walk.enemyPokemon.stats.hp}
                        userPokemonHP={userPokemonHP}
                        userPokemonHPMax={userPokemonWithStats.stats.hp}
                        userPokemonName={userPokemonWithStats.pokemon_name}
                        userPokemonLevel={userPokemonWithStats.stats.level}
                        enemyPokemonName={fightObjectState.walk.enemyPokemon.pokemon_name}
                        enemyPokemonLevel={fightObjectState.walk.enemyPokemon.stats.level}
                        typeFight={fightObjectState.type}
                        userPokemonImageClassName={userPokemonIsAttacking ? 'pokemon-attack-animation' : ''}
                        enemyPokemonImageClassName={enemyPokemonIsAttacking ? 'pokemon-attack-animation-enemy' : ''}
                    />
                    <div className="row-history" ref={timelineContainerRef}>
                        <TimelineElement mode='begin' typeFight={fightObject.type.indexOf('CASINO') > 0 ? 'CASINO' : fightObject.type.indexOf('ROCKET') > 0 ? 'ROCKET' : 'NORMAL'} />
                        {fightObject.walk.stake > 0 && <TimelineElement mode='bet' money={fightObject.walk.stake} />}
                        {timelineElements}
                        {fightObjectState.walk.sequence.length === timelineItems.length &&
                            (
                                <>
                                    {fightObjectState.walk.victory ?
                                        <TimelineElement mode='victory' money={fightObjectState.walk.gainMoney} exp={fightObjectState.walk.gainExp} />
                                        :
                                        <TimelineElement mode='defeat' />
                                    }
                                    <div ref={endElementRef}>
                                        <TimelineElement mode='end' />
                                    </div>
                                </>
                            )}
                    </div>
                    {fightObjectState.walk.sequence.length === timelineItems.length &&
                        <div style={{ display: 'flex', justifyContent: 'center', position: 'fixed', bottom: '90px', width: '100%', margin: 'auto', left: '0', right: '0' }}>
                            {(fightObject.walk.userPokemon.life > 1 || (fightObject.walk.userPokemon.life > 0 && fightObject.walk.victory)) ?
                                <FlatButton color='#548bfc' icon={<WalkIcon />} onClick={() => actionWalk(true)} label={'Lancer un autre combat'} style={{ margin: '5px' }} />
                                :
                                <></>
                            }
                            <FlatButton color='#fd9779' onClick={() => actionWalk(false)} label={'Quitter'} style={{ margin: '5px' }} />
                        </div>
                    }
                </>
            }
            {(fightObject.type === "ARGENT" || fightObject.type === "OBJET") &&
                <>
                    <OverlayShop
                        backgroundImage={backgroundImageUrl}
                        overlayImage={''}
                        style={{ marginBottom: '15px' }}
                        money={userProfile.money}
                        dna={userProfile.adnMoney}
                        dialogObject={{
                            npcName: userProfile.username,
                            text: dialogCommand ? <> Oh, <b>{new Intl.NumberFormat().format(Number(dialogCommand))}</b><img src={RareCandy} alt="money" /> !</> : <>Texte par défaut</>,
                            isVisible: dialogCommand !== null,
                            hideAfter: 10000,
                            onHideAfter: () => setDialogCommand(null)
                        }}
                        lives={userPokemonWithStats.life}
                    />
                    <div className="row-history" ref={timelineContainerRef}>
                        <TimelineElement mode={fightObject.type.toLowerCase()} />
                        <Item
                            name={receivedItem.name}
                            img={receivedItem.img}
                            quantity={receivedItem.quantity}
                            description={receivedItem.description}
                            effect={receivedItem.effect}
                            updateUser={updateUser}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', position: 'fixed', bottom: '90px', width: '100%', margin: 'auto', left: '0', right: '0' }}>
                        <FlatButton color='#548bfc' icon={<WalkIcon />} onClick={() => newWalkAction(true)} label={'Lancer un autre combat'} style={{ margin: '5px' }} />
                        <FlatButton color='#fd9779' onClick={() => newWalkAction(false)} label={'Quitter'} style={{ margin: '5px' }} />
                    </div>
                </>
            }
            {!fightObject.fightBegin &&
                <OverlayLoading
                    backgroundImage={0 + (Math.random() * 100) > 50 ? "images/loading1.gif" : "images/loading2.gif"}
                    dialogObject={{
                        npcName: userPokemonWithStats.pokemon_name,
                        text: dialogCommand ? <> Tiens, il y avait <b>{new Intl.NumberFormat().format(Number(dialogCommand))}</b><img src={RareCandy} alt="money" /> ici !</> : <>Texte par défaut</>,
                        isVisible: dialogCommand !== null,
                        hideAfter: 10000,
                        onHideAfter: () => setDialogCommand(null)
                    }}
                />
            }
            <NavigationBar updateUser={updateUser} onButtonMonneyClick={handleDialogCommand} userProfile={userProfile} style={centerStyle} battleMode autoBattleClick={autoBattleClick} speedClick={speedClick} speed={battleSpeed === MIN_SPEED ? MIN_SPEED / 1000 : MIN_SPEED * maxSpeed / 1000} />
        </>
    );
};

export default WalkElement;
