import { Dispatch } from "react"
import User from "../../interface/User"
import { SetUserAction, SetPokemonPrincipalAction, RemoveUserAction, SetBossFightsAction, SetBreedingAction, ResetBreedingAction, SetBuildingAction, SetPokeEggsAction, SetPokeballsAction, SetSuperballsAction, SetHyperballsAction } from "../actions/index"
import { ActionType } from "../action-types"
import UserPokemon from "../../interface/UserPokemon"

export const setUser = (user: User) => {
    return (dispatch : Dispatch<SetUserAction>) => {
        dispatch({
            type: ActionType.SET_USER,
            payload: user
        })
    }
}

export const removeUser = () => {
    return (dispatch : Dispatch<RemoveUserAction>) => {
        dispatch({
            type: ActionType.REMOVE_USER
        })
    }
}

export const setPokemonPrincipal = (pokemonPrincipal: UserPokemon) => {
    return (dispatch : Dispatch<SetPokemonPrincipalAction>) => {
        dispatch({
            type: ActionType.SET_POKEMON_PRINCIPAL,
            payload: pokemonPrincipal
        })
    }
}

export const setBossFights = (bossFight: any) => {
    return (dispatch : Dispatch<SetBossFightsAction>) => {
        dispatch({
            type: ActionType.SET_BOSS_FIGHTS,
            payload: bossFight
        })
    }
}

export const setBreeding = (breeding: any) => {
    return (dispatch : Dispatch<SetBreedingAction>) => {
        dispatch({
            type: ActionType.SET_BREEDING,
            payload: breeding
        })
    }
}

export const resetBreeding = () => {
    return (dispatch : Dispatch<ResetBreedingAction>) => {
        dispatch({
            type: ActionType.RESET_BREEDING
        })
    }
}

export const setBuilding = (building: any) => {
    return (dispatch : Dispatch<SetBuildingAction>) => {
        dispatch({
            type: ActionType.SET_BUILDING,
            payload: building
        })
    }
}

export const setPokeEggs = (pokeEggs: any) => {
    return (dispatch : Dispatch<SetPokeEggsAction>) => {
        dispatch({
            type: ActionType.SET_POKEEGGS,
            payload: pokeEggs
        })
    }
}

export const setPokeballs = (pokeballs: any) => {
    return (dispatch : Dispatch<SetPokeballsAction>) => {
        dispatch({
            type: ActionType.SET_POKEBALLS,
            payload: pokeballs
        })
    }
}

export const setSuperballs = (superballs: any) => {
    return (dispatch : Dispatch<SetSuperballsAction>) => {
        dispatch({
            type: ActionType.SET_SUPERBALLS,
            payload: superballs
        })
    }
}

export const setHyperballs = (hyperballs: any) => {
    return (dispatch : Dispatch<SetHyperballsAction>) => {
        dispatch({
            type: ActionType.SET_HYPERBALLS,
            payload: hyperballs
        })
    }
}