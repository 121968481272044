import UserPokemon from "../interface/UserPokemon";
import { API_URL } from "./EndpointAPI";
import { setCookie, getCookie } from 'react-use-cookie';

export const getStats = (pokemon: UserPokemon, user: any) => {
    let uPokemon: UserPokemon = { ...pokemon, stats: { ...pokemon.stats } };
    const level = Math.floor(Math.pow(pokemon.experience, 1 / 3));
    const rankBoost = (pokemon.rank * 0.075) + 1;
    uPokemon.stats.level = level;
    uPokemon.stats.hp = Math.round((((pokemon.iv_hp + 2 * pokemon.base_hp + (pokemon.ev_hp / 4)) * level / 100) + level + 10) * rankBoost);
    uPokemon.stats.attack = Math.round((((pokemon.iv_attack + 2 * pokemon.base_attack + (pokemon.ev_attack / 4)) * level / 100) + 5) * rankBoost);
    uPokemon.stats.defense = Math.round((((pokemon.iv_defense + 2 * pokemon.base_defense + (pokemon.ev_defense / 4)) * level / 100) + 5) * rankBoost);
    uPokemon.stats.speed = Math.round((((pokemon.iv_speed + 2 * pokemon.base_speed + (pokemon.ev_speed / 4)) * level / 100) + 5) * rankBoost);
    uPokemon.stats.specialAttack = Math.round((((pokemon.iv_special_attack + 2 * pokemon.base_special_attack + (pokemon.ev_special_attack / 4)) * level / 100) + 5) * rankBoost);
    uPokemon.stats.specialDefense = Math.round((((pokemon.iv_special_defense + 2 * pokemon.base_special_defense + (pokemon.ev_special_defense / 4)) * level / 100) + 5) * rankBoost);
    uPokemon.date = new Date();
    if (user.equipments.length > 0) {
        uPokemon = addEquipmentsBoost(user, uPokemon);
    }
    return uPokemon;
};

export const addEquipmentsBoost = (user: any, userPokemon: any) => {
    let equipmentBonus: any = { hp: 0, attack: 0, defense: 0, special_attack: 0, special_defense: 0, speed: 0 };
    user.equipments.forEach(async (equipment: any) => {
        if (equipment.effect) {
            equipment.effect.forEach((effect: any, index: any) => {
                if (index % 2 === 0) {
                    if (index % 2 === 0) {
                        equipmentBonus[effect] += equipment.effect[index + 1];
                    }
                }
            });
        }
    });

    userPokemon.stats.hp += equipmentBonus.hp;
    userPokemon.stats.attack += equipmentBonus.attack;
    userPokemon.stats.defense += equipmentBonus.defense;
    userPokemon.stats.specialAttack += equipmentBonus.special_attack;
    userPokemon.stats.specialDefense += equipmentBonus.special_defense;
    userPokemon.stats.speed += equipmentBonus.speed;

    return userPokemon;
}

export const calculTransmuteADN = (pokemon: UserPokemon) => {
    return Math.round(pokemon.base_experience / 10) * (pokemon.rank + 1);
}

export class UserPokemonService {
    private redirect() {
        setCookie('pokeascenttoken', '', { days: 0 });
        window.location.href = `/`;
    }

    public async getUserPokemon(pokemonId: any): Promise<any> {
        const token = getCookie('pokeascenttoken');
        const response = await fetch(`${API_URL}/user/pokemon/${pokemonId}`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}` } });
        const json = await response.json();
        if ('error' in json && json.enabled) {
            this.redirect();
        }
        return json;
    }

    public async changeUserPokemonMove(pokemon: any, move: Number): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/user/pokemon/move`, { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ pokemon, move }) })
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async launchWalkAction(enemyPokemonPreset?: any): Promise<any> {
        const token = getCookie('pokeascenttoken');
        const config: RequestInit = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        if (enemyPokemonPreset) {
            config.body = JSON.stringify({ enemyPokemonPreset });
        }

        const response = await fetch(`${API_URL}/walk`, config);
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async walkWithChoose(): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/walk/choose`, { method: 'POST', headers: { 'Authorization': `Bearer ${token}` } })
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async checkEndWalkAction(walk: any): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/walk/end`, { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ walk }) })
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async getCollection(): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/user/pokemon/collection`, { method: 'POST', headers: { 'Authorization': `Bearer ${token}` } })
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async getPokemonsInStock(): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/user/pokemon/stock`, { method: 'POST', headers: { 'Authorization': `Bearer ${token}` } })
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async addLifeWithObject(pokemon: any, item: any): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/user/pokemon/addLife`, { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ pokemon, item }) })
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async addIV(item: any): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/user/pokemon/boost-iv`, { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ item }) })
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async addEV(item: any): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/user/pokemon/boost-ev`, { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ item }) })
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async getAllUserPokemon(userToGet: any): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/user/pokemon/all`, { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ userToGet }) })
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async getLastUserPokemon(count: number): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/user/pokemon/last/${count}`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}` } });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async rankUpPokemon(): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/user/pokemon/upgrade`, { method: 'POST', headers: { 'Authorization': `Bearer ${token}` } })
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async getDoublonPokemon(): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/user/pokemon/doublon`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}` } })
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async transmutePokemon(): Promise<any> {
        const token = getCookie('pokeascenttoken')
        const response = await fetch(`${API_URL}/user/pokemon/transmute`, { method: 'POST', headers: { 'Authorization': `Bearer ${token}` } })
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }
}