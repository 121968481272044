import React from 'react';
import { Col, Row } from 'antd';

interface NameBarProps {
    name: string;
    level: number;
}

const NameBar: React.FC<NameBarProps> = ({ name, level }) => {

    return (
        <Row className="battle-pokemon-hp">
            <Col
                span={9}
                style={{ textAlign: 'center', fontWeight: '650', margin: 'auto', marginLeft: '10px' }}
            >
                {name}
            </Col>
            { /* Level */}
            {level > 0 &&
                <Col
                    span={6}
                    style={{ textAlign: 'center', fontWeight: '650', margin: 'auto' }}
                >
                    Niv. {level}
                </Col>}
        </Row>
    );
};

export default NameBar;
