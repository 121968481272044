import React, {  } from 'react';
import './OverlayImageStyles.css';
import RetroTextBox from './RetroTextBox';
import PillsButton from './PillsButton';
import RareCandy from '../../assets/images/rare-candy.png';
import { DNAIcon } from '../SVGIcons';

interface OverlayBreedingProps {
  backgroundImage: string; // URL de l'image de fond
  pokemonBreeding1Image: string; // URL de l'image à superposer
  pokemonBreeding2Image: string; // URL de l'image à superposer
  style?: React.CSSProperties
  dialogObject?: {
    npcName?: string;
    text: any;
    isVisible: boolean;
    hideAfter?: number; // Temps en millisecondes
    onHideAfter?: () => void;
  },
  pokemonBreeding1ImgClassName: any;
  pokemonBreeding2ImgClassName: any;
  money: number;
  dna: number;
}

const OverlayBreeding: React.FC<OverlayBreedingProps> = ({ money, dna, pokemonBreeding1ImgClassName, pokemonBreeding2ImgClassName, dialogObject = { onHideAfter: () => {}, npcName: 'Pr. Chen', text: 'Salut !', isVisible: false, hideAfter: 10000 }, backgroundImage, pokemonBreeding1Image, style, pokemonBreeding2Image }) => {

  return (
    <div style={{
      position: 'relative',
      width: '100%',
      paddingTop: '50%',
      height: '0',
      overflow: 'hidden',
      borderColor: 'black',
      borderWidth: '2px',
      borderStyle: 'solid',
      ...style
    }}>
      <img
        src={backgroundImage}
        alt="Background"
        style={{
          position: 'absolute',
          bottom: '-12%', // Aligner l'image de fond vers le bas du conteneur
          left: '0',
          width: '100%', // Assurez-vous que l'image couvre le conteneur
          height: 'auto', // Hauteur automatique pour éviter la déformation
          objectFit: 'cover', // Couvre le conteneur sans déformation
          objectPosition: 'bottom', // Assurez-vous que le bas de l'image est visible
        }}
      />
      {pokemonBreeding1Image &&
        <img
          src={pokemonBreeding1Image}
          alt="Pokémon 1"
          className="hop-animation-flip"
          style={{
            position: 'absolute',
            bottom: '8%',
            left: '18%',
            width: 'auto',
            height: '30%',
            //animationDelay: `${2 + Math.random() * 8}s`, // Délai aléatoire entre 2 et 10 secondes
            transform: 'scaleX(-1)', // Retourner l'image horizontalement
          }}
        />}
      {pokemonBreeding2Image &&
        <img
          src={pokemonBreeding2Image}
          alt="Pokémon 2"
          className="hop-animation"
          style={{
            position: 'absolute',
            bottom: '8%',
            right: '42%',
            width: 'auto',
            height: '30%',
            //animationDelay: `${2 + Math.random() * 8}s`, // Délai aléatoire entre 2 et 10 secondes
          }}
        />}
      <RetroTextBox
        npcName={dialogObject.npcName}
        text={dialogObject.text}
        isVisible={dialogObject.isVisible}
        hideAfter={dialogObject.hideAfter} // Masquer après 5000 millisecondes (5 secondes)
        onHideAfter={dialogObject.onHideAfter}
      />
      <div style={{
        position: 'absolute',
        top: '3%',
        right: '1%',
        display: 'flex',
        flexDirection: 'row',
      }}>
        <PillsButton
          label={new Intl.NumberFormat().format(money)}
          icon={<img src={RareCandy} alt='money' />}
          onClick={() => {}}
          style={{
            marginRight: '8px', // Ajoutez une marge à droite
            borderRadius: '3px',
            backgroundColor: 'rgba(255, 253, 233,0.5)',
            border: '4px solid rgba(255, 253, 233,0.1)'
          }}
          iconPosition='right'
        />
        <PillsButton
          label={new Intl.NumberFormat().format(dna)}
          icon={<DNAIcon />}
          onClick={() => {}}
          style={{
            borderRadius: '3px',
            backgroundColor: 'rgba(255, 253, 233,0.5)',
            border: '4px solid rgba(255, 253, 233,0.1)'
          }}
          iconPosition='right'
        />
      </div>
    </div>
  );
};

export default OverlayBreeding;
