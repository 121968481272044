import { AlertFilled, BellFilled, BugFilled, GiftFilled, HeartFilled, InfoCircleFilled, UserAddOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Badge, Dropdown, MenuProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { NotificationService } from '../services/NotificationService';

type NotificationMenuProps = {
    updateUser: (userProfile: any) => void;
    userProfile: any;
}

const NotificationMenu: React.FC<NotificationMenuProps> = () => {
    const [items, setItems] = useState<any[]>([]) as any[];
    const notificationService = new NotificationService();

    useEffect(() => {
        getNotification();

        const interval = setInterval(() => {
            getNotification();
        }, 60000); // Exécute la requête toutes les 60 secondes (ajustez l'intervalle selon vos besoins)

        // Nettoyez l'intervalle lorsque le composant est démonté
        return () => clearInterval(interval);
    }, [])

    const createImageItem = (img: any) => {
        if (!img.data) return '';

        let src = '';
        const buffer = Buffer.from(img.data);
        if (typeof img.data === 'string') {
            src = `data:image/png;base64,${buffer}`
        } else {
            const base64String = buffer.toString('base64');
            src = `data:image/png;base64,${base64String}`;
        }

        return src;
    };


    const getNotification = async () => {
        await notificationService.getNotifications().then((data) => {
            if (data.success) {
                const itemsMenu: MenuProps['items'] = data.notifications.map((notification: any, index: any) => {
                    let notificationType = <InfoCircleFilled style={{ marginRight: '9px', color: 'blue' }} />;
                    let itemSrc = null;
                    switch (notification.type) {
                        case 'BUG': notificationType = <BugFilled style={{ marginRight: '9px' }} />; break;
                        case 'GIFT': notificationType = <GiftFilled style={{ marginRight: '9px' }} />; break;
                        case 'BABY': notificationType = <HeartFilled style={{ marginRight: '9px', color: 'red' }} />; break;
                        case 'ALERT': notificationType = <AlertFilled style={{ marginRight: '9px', color: 'darkred' }} />; break;
                        case 'FRIEND_REQUEST': notificationType = <UserAddOutlined style={{ marginRight: '9px', color: 'darkblue' }} />; break;
                        case 'EXCHANGE_REQUEST': notificationType = <UserSwitchOutlined style={{ marginRight: '9px' }} />; break;
                    }

                    if ('item' in notification) {
                        if (notification.item)
                            itemSrc = createImageItem(notification.item.img);
                    }

                    const dateFromMongo = new Date(notification.date);
                    const day = dateFromMongo.getDate().toString().padStart(2, '0');
                    const month = (dateFromMongo.getMonth() + 1).toString().padStart(2, '0');
                    const year = dateFromMongo.getFullYear().toString();
                    const hours = dateFromMongo.getHours().toString().padStart(2, '0');
                    const minutes = dateFromMongo.getMinutes().toString().padStart(2, '0');

                    let link = notification.link;
                    if (link && !link.startsWith('http://') && !link.startsWith('https://') && !link.startsWith('/')) {
                        link = `http://${link}`;
                    }

                    return ({
                        label: <a rel='noreferrer' target={link && !link.startsWith('/') ? '_blank' : ''} href={link || '#'} style={notification.newNotification ? { fontWeight: 'bold' } : {}}>
                            <div>
                                <div>{notificationType}{notification.description}{itemSrc ? <img src={itemSrc} alt={notification.item.item_name} /> : ''}</div>
                                <div style={{ fontSize: '12px', fontStyle: 'italic', justifyContent: 'right' }}>{`Le ${day}/${month}/${year} à ${hours}:${minutes}`}</div>
                            </div>
                        </a>,
                        key: index.toString(),
                        isnew: notification.newNotification.toString(),
                        _id: notification._id,
                    })
                });
                setItems(itemsMenu);
            } else {
                setItems([{ label: data.message, key: '0', isnew: "false" }]);
            }
        });
    }

    const clearNotifications = () => {
        if (items) {
            if (items.filter((item: any) => item.isnew === "true").length === 0) return;

            const updatedItems = items.map((item: any) => ({
                label: item.label,
                key: item.key,
                isnew: "false",
            }));
            setItems(updatedItems);
            notificationService.clearNotifications(items);
        }
    }

    return (
        <Dropdown menu={{ items }} trigger={['click']}>
            <Badge count={items.filter((item: any) => item.isnew === "true").length} size="small">
                <BellFilled style={{ fontSize: '20px', color: '#2d3436', cursor: 'pointer', marginLeft: '5px' }} onClick={clearNotifications} />
            </Badge>
        </Dropdown>
    );
};

export default NotificationMenu;
