import { API_URL } from "./EndpointAPI";
import { setCookie, getCookie } from 'react-use-cookie';

export class LadderService {

    private token = getCookie('pokeascenttoken');

    private redirect() {
        setCookie('pokeascenttoken', '', { days: 0 });
        window.location.href = `/`;
    }

    public async getTopUsersWithHighestLevelPokemon(): Promise<any> {
        const response = await fetch(`${API_URL}/ladder/get`, { method: 'GET', headers: { 'Authorization': `Bearer ${this.token}` } });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }

    public async getHighestLevelPokemonByUsers(): Promise<any> {
        const response = await fetch(`${API_URL}/ladder/highest`, { method: 'GET', headers: { 'Authorization': `Bearer ${this.token}` } });
        const json = await response.json();
        if ('error' in json) {
            this.redirect();
        }
        return json;
    }
}