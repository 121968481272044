import { MinusOutlined, PlusOutlined, ShopFilled, ExperimentFilled, BankFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import RareCandy from '../../assets/images/rare-candy.png';
import { ShopItemService } from "../../services/ShopItemService";
import { SwordSimpleIcon } from "../SVGIcons";
import CircleButton from "./CircleButton";
import FlatButton from "./FlatButton";
import ShopItem from "./ShopItem";
import './ShopSection.css';
import { useSelector } from "react-redux";
import { State } from "../../state";

type ShopSectionProps = {
    updateUser: (userProfile: any) => void;
    userProfile: any;
    onBuy: ({ type, shopItem, numberItem }: any) => void;
};

let itemCount = 0;
let previusItem: any = "";
let shopItemsLocal: any = []

const ShopSection: React.FC<ShopSectionProps> = ({ userProfile, updateUser, onBuy }) => {

    const shopItemService = new ShopItemService();
    const [achatEnCours, setAchatEnCours] = useState<boolean>(false);
    const [, setShopItems] = useState<any>([])
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [numberItem, setNumberItem] = useState<number>(1);
    const [category, setCategory] = useState<any>(null);

    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

    const userPokemonWithStats = useSelector((state: State) => state.userPokemon);

    const startIncrement = () => {
        if (!intervalId) {
            const id = setInterval(() => {
                if (numberItem + 1 >= Math.floor(userProfile.money / selectedItem.cost)) {
                    setNumberItem(prev => Math.min(prev + 1, 999));
                } else {
                    setNumberItem(prev => Math.min(prev + 1, Math.floor(userProfile.money / selectedItem.cost)));
                }
            }, 100); // Changer le nombre toutes les 100ms
            setIntervalId(id);
        }
    };

    const startDecrement = () => {
        if (!intervalId) {
            const id = setInterval(() => {
                setNumberItem(prev => Math.max(prev - 1, 1));
            }, 100); // Changer le nombre toutes les 100ms
            setIntervalId(id);
        }
    };

    const stopChange = () => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
    };

    const handleCardClick = (item: any) => {
        setSelectedItem(item);
        setNumberItem(1);
    };

    const buyItem = async (shopitem: any, quantity: any) => {
        if (achatEnCours) {
            // Empêcher l'achat pendant qu'un achat est en cours
            return;
        }

        setAchatEnCours(true);

        if (previusItem === shopitem.item.item_name) {
            itemCount = itemCount + quantity;
        } else {
            itemCount = quantity;
            previusItem = shopitem.item.item_name;
        }
        const rtn = await shopItemService.buyNonUserShopItem(shopitem, quantity, userProfile);

        if (rtn.success) {
            onBuy({ type: 'SHOP', selectedItem, numberItem });
            updateUser(rtn.user);
            setSelectedItem(null);
        }

        setTimeout(() => {
            setAchatEnCours(false);
        }, 100);
    };

    const defineShopItems = async () => {
        const data = await shopItemService.getShopItems();
        if (shopItemsLocal !== data) {
            shopItemsLocal = data;
            const itemsToRemove = [9999, 9998, 9997, 9996];
    
            for (const itemId of itemsToRemove) {
                const foundIndex = userProfile.inventory.findIndex((item : any) => item.item_ID === itemId);
                if (foundIndex !== -1) {
                    const indexShop = shopItemsLocal.findIndex((item : any) => item.item.item_ID === itemId);
                    if (indexShop !== -1) {
                        shopItemsLocal.splice(indexShop, 1);
                    }
                }
            }
    
            setShopItems(data);
        }
    };
    

    useEffect(() => {
        defineShopItems();
    }, [])

    const filterShopItems = (category: any) => {
        setCategory(category);
    }

    const classicButton: React.CSSProperties = {
        position: 'absolute',
        top: 0,
        right: 0,
        transform: 'translate(10%, -10%)',
        fontWeight: '600',
        backgroundColor: '#ff967b',
        color: '#fffee5'
    };

    // Function to check if user dont have balls in inventory and pokemon is dead, user can buy if item is Egg
    const poorUserPromotion = (item: any) => {
        if (!item) return false;
        if (item.item.item_ID === 483) {
            const numberBallItems = userProfile.inventory.filter((item: any) => item.const === 'balls').length;
            if (numberBallItems === 0 && userPokemonWithStats.life === 0 && userProfile.money < 125) {
                return true;
            }
        }
        return false;
    }

    return (
        <>
            <div className="shop-section-card">
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    padding: '15px 5px',
                }}>
                    <CircleButton icon={
                        <ExperimentFilled />
                    }
                        color={'#fd9779'}
                        onClick={() => filterShopItems("labo")}
                        // Style clasicButton + left: 50px
                        style={classicButton}
                    />
                    <CircleButton icon={
                        <BankFilled />
                    }
                        color={'#f05b7d'}
                        onClick={() => filterShopItems("dojo")}
                        style={{ ...classicButton, right: '55px' }}
                    />
                    <CircleButton icon={
                        <ShopFilled />
                    }
                        color={'#f05b7d'}
                        onClick={() => filterShopItems("shop")}
                        style={{ ...classicButton, right: '110px' }}
                    />
                    <CircleButton icon={
                        <SwordSimpleIcon />
                    }
                        color={'#f05b7d'}
                        onClick={() => filterShopItems("")}
                        style={{ ...classicButton, right: '165px' }}
                    />
                </div>
                <div className="shop-section">
                    {/* Tri par prix */}
                    {shopItemsLocal.filter((shopItem: any) => category ? shopItem.buildingType === category : shopItem).sort((a: any, b: any) => a.cost - b.cost).map((shopitem: any) => {
                        return (
                            <ShopItem
                                style={{ borderColor: selectedItem === shopitem ? '#ff967b' : 'black', borderWidth: selectedItem === shopitem ? '3px' : '2px' }}
                                shopItem={{ ...shopitem, displayPrice: poorUserPromotion(shopitem) ? 0 : selectedItem === shopitem ? numberItem * shopitem.cost : shopitem.cost, cost: poorUserPromotion(shopitem) ? 0 : shopitem.cost }}
                                onBuy={() => { }}
                                canBuy={shopitem.cost < userProfile.money || poorUserPromotion(shopitem)}
                                onClick={() => handleCardClick(shopitem)}
                                userProfile={userProfile}
                            />
                        )
                    })}
                </div>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                padding: '5px 5px 5px 5px',
            }}>
                <div
                    onMouseDown={startDecrement}
                    onTouchStart={startDecrement} // Ajouté pour le support tactile
                    onMouseUp={stopChange}
                    onTouchEnd={stopChange} // Ajouté pour le support tactile
                    onMouseLeave={stopChange}
                    style={{ display: 'inline-block', marginRight: '10px' }}
                >
                    <FlatButton
                        color='#FEC569'
                        disabled={!selectedItem || numberItem === 1}
                        label=""
                        icon={<MinusOutlined />}
                        isAnimated={false} onClick={() => setNumberItem(Math.max(numberItem - 1, 1))}
                    />
                </div>
                <FlatButton
                    color='#548bfc'
                    onClick={() => buyItem(selectedItem, numberItem)} // Vous pouvez gérer l'achat ici si nécessaire
                    disabled={poorUserPromotion(selectedItem) ? false : !selectedItem || selectedItem.cost * numberItem > userProfile.money}
                    label={`Acheter ${numberItem}`}
                    icon={<img src={RareCandy} alt='money' />}
                    iconPosition="left" // or "right"
                />
                <div
                    onMouseDown={startIncrement}
                    onTouchStart={startIncrement} // Ajouté pour le support tactile
                    onMouseUp={stopChange}
                    onTouchEnd={stopChange} // Ajouté pour le support tactile
                    onMouseLeave={stopChange}
                    style={{ display: 'inline-block', marginLeft: '10px' }}
                >
                    <FlatButton
                        color='#FEC569'
                        disabled={!selectedItem || numberItem >= Math.floor(userProfile.money / selectedItem.cost)}
                        label=""
                        icon={<PlusOutlined />}
                        isAnimated={false} onClick={() => setNumberItem(Math.min(numberItem + 1, 999))} />
                </div>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                padding: '5px 5px',
            }}>
            <div
                onMouseDown={startDecrement}
                onMouseUp={stopChange}
                onMouseLeave={stopChange}
                style={{ display: 'inline-block', marginRight: '10px' }}
            >
                <FlatButton
                    color='#fd9779'
                    disabled={!selectedItem || numberItem === 1}
                    label=""
                    icon={'Min.'}
                    isAnimated={false} onClick={() => setNumberItem(1)}
                />
            </div>
            <div
                onMouseDown={startIncrement}
                onMouseUp={stopChange}
                onMouseLeave={stopChange}
                style={{ display: 'inline-block', marginLeft: '10px' }}
            >
                <FlatButton
                    color='#fd9779'
                    disabled={!selectedItem || numberItem >= Math.floor(userProfile.money / selectedItem.cost)}
                    label=""
                    icon={'Max.'}
                    isAnimated={false} onClick={() => setNumberItem(Math.floor(userProfile.money / selectedItem.cost))} />
            </div>
            </div>

        </>
    );
}

export default ShopSection;