import { ArrowRightOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { HeartIcon, ShieldIcon, ShoeIcon, SwordSimpleIcon, ThunderIcon } from './../SVGIcons';
import DiamondButton from './DiamondButton'; // Assurez-vous que le chemin est correct
import { getStats } from '../../services/UserPokemonService';

type BoostPanelProps = {
  userPokemonWithStats: any;
  item: any;
  userProfile: any;
  style?: React.CSSProperties;
};

const BoostPanel: React.FC<BoostPanelProps> = ({ userPokemonWithStats, item, userProfile, style }) => {
  const statsConfigIV = [
    { name: 'HP', icon: <HeartIcon />, key: 'iv_hp', color: 'darkred', property: 'hp' },
    { name: 'Attack', icon: <SwordSimpleIcon />, key: 'iv_attack', color: '#ffbe5c', property: 'attack' },
    { name: 'Defense', icon: <ShieldIcon />, key: 'iv_defense', color: '#fe907a', property: 'defense' },
    { name: 'Special Attack', icon: <ThunderIcon />, key: 'iv_special_attack', color: 'purple', property: 'specialAttack' },
    { name: 'Special Defense', icon: <ShieldIcon />, key: 'iv_special_defense', color: '#b16455', property: 'specialDefense' },
    { name: 'Speed', icon: <ShoeIcon />, key: 'iv_speed', color: '#5c8df3', property: 'speed' }
  ];

  const statsConfigEV = [
    { name: 'HP', icon: <HeartIcon />, key: 'ev_hp', color: 'darkred', property: 'hp' },
    { name: 'Attack', icon: <SwordSimpleIcon />, key: 'ev_attack', color: '#ffbe5c', property: 'attack' },
    { name: 'Defense', icon: <ShieldIcon />, key: 'ev_defense', color: '#fe907a', property: 'defense' },
    { name: 'Special Attack', icon: <ThunderIcon />, key: 'ev_special_attack', color: 'purple', property: 'specialAttack' },
    { name: 'Special Defense', icon: <ShieldIcon />, key: 'ev_special_defense', color: '#b16455', property: 'specialDefense' },
    { name: 'Speed', icon: <ShoeIcon />, key: 'ev_speed', color: '#5c8df3', property: 'speed' }
  ];

  // Trouver la configuration de la statistique affectée par l'item
  const affectedStatConfig = item.const === "stat-boosts" ? statsConfigIV.find(statConfig => statConfig.key === item.effect[0]) : statsConfigEV.find(statConfig => statConfig.key === item.effect[0]);

  // Création d'un nouvel objet upgradedPokemon basé sur userPokemonWithStats
  let upgradedPokemon = { ...userPokemonWithStats };

  // Vérifier si la configuration de la statistique affectée existe
  if (affectedStatConfig) {
    // Augmenter la valeur de la statistique affectée de 1
    const currentValue = upgradedPokemon[affectedStatConfig.key];
    upgradedPokemon[affectedStatConfig.key] = currentValue + item.effect[1];
    upgradedPokemon = getStats(upgradedPokemon, userProfile);
  }

  const renderStat = (statConfig: any) => {
    let isMaxed = false;
    if (item.const === "stat-boosts") {
      isMaxed = userPokemonWithStats[statConfig.key] >= 31;
    } else if (item.const === "vitamins") {
      // Si la statistique est égal à 252 ou si la somme des statistiques est égale à 510
      isMaxed = userPokemonWithStats[statConfig.key] >= 252 || Object.keys(userPokemonWithStats).filter(key => key.startsWith('ev_')).reduce((sum, key) => sum + userPokemonWithStats[key], 0) >= 510;
    }
    const oldValueIV = userPokemonWithStats[statConfig.key];
    const newValueIV = upgradedPokemon[statConfig.key]; // Supposition d'une augmentation fixe pour l'exemple

    const oldValue = userPokemonWithStats.stats[statConfig.property];
    const newValue = upgradedPokemon.stats[statConfig.property]; // Supposition d'une augmentation fixe pour l'exemple

    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px 2px 2px 2px' }}>
        <Tooltip placement="top" title={statConfig.name}>
          <DiamondButton
            icon={statConfig.icon}
            color={statConfig.color}
            iconColor='#fffbd3'
            onClick={() => {}}
          />
        </Tooltip>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          {isMaxed ? <span style={{ color: '#021239', fontWeight: 'bold' }}>MAX</span> :
            <>
              <span style={{ color: '#021239', fontWeight: 'bold', fontSize:'13px', marginRight:'2px', ...style }}>{item.const === "stat-boosts" ? 'IV |' : 'EV |'}</span>
              <span style={{ color: '#021239', fontWeight: 'bold', ...style }}>{oldValueIV}</span>
              <ArrowRightOutlined style={{ margin: '0 2px' }} />
              <span style={{ color: '#7DCE82', fontWeight: 'bold' }}>{newValueIV}</span>
            </>
          }
        </div>
        {!isMaxed &&
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
            <span style={{ color: '#021239', fontWeight: 'bold', ...style }}>{oldValue}</span>
            <ArrowRightOutlined style={{ margin: '0 2px' }} />
            <span style={{ color: '#7DCE82', fontWeight: 'bold' }}>{newValue}</span>
          </div>
        }
      </div>
    );
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div>
        {affectedStatConfig ? renderStat(affectedStatConfig) : null}
      </div>
    </div>
  );
};

export default BoostPanel;
