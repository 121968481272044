import {
    CloseOutlined,
    DatabaseFilled,
    RedoOutlined,
    UnlockFilled,
    InfoCircleOutlined,
    CaretDownFilled,
    CaretUpFilled
} from '@ant-design/icons';
import { Badge, Modal, Tag, message, notification } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import superballImage from '../../assets/images/icnBalls/great-ball.png';
import eggImage from '../../assets/images/icnBalls/mystery-egg.png';
import pokeballImage from '../../assets/images/icnBalls/poke-ball.png';
import hyperballImage from '../../assets/images/icnBalls/ultra-ball.png';
import { GatchaService } from '../../services/GatchaService';
import { TaskService } from "../../services/TaskService";
import { UserPokemonService, getStats, calculTransmuteADN } from "../../services/UserPokemonService";
import { actionCreators } from "../../state";
import { BagIcon, DNAIcon, FemaleIcon, HeartIcon, MaleIcon, ShinyIcon } from "../SVGIcons";
import CircleButton from "./CircleButton";
import FlatModalButton from "./FlatModalButton";
import PillsButton from "./PillsButton";
import './modal.css';
import { useSelector } from 'react-redux';
import PokemonItem from './PokemonItem';
import StarPiece from '../../assets/images/icnRanks/starpiece.png';

interface SubMenuProps {
    userPokemonWithStats: any;
    updateUser: (userProfile: any) => void;
    userProfile: any;
}

let lastPokemonGacha: any = null
let lastPokemonRarityGacha: any = null
let lastPokemonColorRarityGacha: any = null
let lastBallUsed: any = null
let pokemonInPokedex: boolean = false

const SubMenu: React.FC<SubMenuProps> = ({
    userPokemonWithStats,
    updateUser,
    userProfile,
}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { setPokemonPrincipal } = bindActionCreators(actionCreators, dispatch);

    const taskService = new TaskService();
    const gatchaService = new GatchaService();
    const userPokemonService = new UserPokemonService();

    const [openModal, setOpenModal] = useState(false);
    const [selectedBall, setSelectedBall] = useState({ itemId: 0, type: '', name: '', number: 0 });
    const [loading, setLoading] = useState(false);
    const [gatchaOpened, setGatchaOpened] = useState(false);
    const [transmuteLoading, setTransmuteLoading] = useState(false);
    const [partenaireLoading, setPartenaireLoading] = useState(false);
    const [oneMoreLoading, setOneMoreLoading] = useState(false);
    const [imgPkmn, setImgPkmn] = useState('');
    const [imgItem, setImgItem] = useState(pokeballImage);
    const [openOddsModal, setOpenOddsModal] = useState(false);

    const oddsPokeEgg = useSelector((state: any) => state.odds.egg);
    const oddsPokeBall = useSelector((state: any) => state.odds.pokeball);
    const oddsSuperBall = useSelector((state: any) => state.odds.superball);
    const oddsHyperBall = useSelector((state: any) => state.odds.hyperball);

    const [probabilities, setProbabilities] = useState<any>(oddsPokeEgg);
    const [pokemonItemsCollapsed, setPokemonItemsCollapsed] = useState(Array(oddsPokeEgg.length).fill(true));

    const messageTopMargin = '36px';
    const [api, contextHolder] = notification.useNotification();

    const selectOdds = (category: any) => {
        let odds = [];
        switch (category) {
            case 'POKEEGG':
                odds = oddsPokeEgg;
                break;
            case 'POKEBALL':
                odds = oddsPokeBall;
                break;
            case 'SUPERBALL':
                odds = oddsSuperBall;
                break;
            case 'HYPERBALL':
                odds = oddsHyperBall;
                break;
            default:
                break;
        }

        setProbabilities(odds);
        setPokemonItemsCollapsed(Array(probabilities.length).fill(true));
        setOpenOddsModal(true);
    }

    const determineGender = (gender: number) => {
        switch (gender) {
            case 0:
                return <FemaleIcon style={{ color: '#fdfee5' }} />;
            case 1:
                return <MaleIcon style={{ color: '#fdfee5' }} />;
            default:
                return '';
        }
    }

    const getGatcha = (user: any, category: any, numberItem: any, itemId: any) => {
        gatchaService.getGatcha(user, category, numberItem, itemId).then((response) => {
            getInfosFromCookie();
            if (!response.success) {
                handleCancel();
                api.open({
                    message: "Achat échoué",
                    description: (
                        <div style={{ display: 'inline-block' }}>
                            <p style={{ display: 'inline-block' }}>{response.message}</p>
                        </div>),
                    duration: 1.5,
                    style: {
                        width: 400,
                        marginLeft: 335 - 400,
                    }
                });
                return;
            }

            lastBallUsed = itemId;
            lastPokemonGacha = response.pokemon;
            lastPokemonRarityGacha = response.rarity;
            pokemonInPokedex = response.pokemonInPokedex;

            // Update number of ball in selectedBall state
            setSelectedBall({ itemId, type: category, name: selectedBall.name, number: selectedBall.number - 1 });

            switch (lastPokemonRarityGacha) {
                case 'NORMAL':
                    lastPokemonColorRarityGacha = '#3498db'
                    break;
                case 'RARE':
                    lastPokemonColorRarityGacha = '#f1c40f'
                    break;
                case 'EPIQUE':
                    lastPokemonColorRarityGacha = '#9b59b6'
                    break;
                case 'LEGENDAIRE':
                    lastPokemonColorRarityGacha = '#e67e22'
                    break;
                case 'DIVIN':
                    lastPokemonColorRarityGacha = '#e74c3c'
                    break;
                default:
                    lastPokemonColorRarityGacha = '#3498db'
                    break;
            }

            const buffer = Buffer.from(lastPokemonGacha.img_front.data);
            const base64String = buffer.toString('base64');
            setImgPkmn(`data:image/png;base64,${base64String}`)
            setGatchaOpened(true);
        })
    }

    const rarityTag = (rarity: string, chance: number, index: number) => {
        let color = '';
        switch (rarity) {
            case 'NORMAL':
                color = '#3498db'
                break;
            case 'RARE':
                color = '#f1c40f'
                break;
            case 'EPIQUE':
                color = '#9b59b6'
                break;
            case 'LEGENDAIRE':
                color = '#e67e22'
                break;
            case 'DIVIN':
                color = '#e74c3c'
                break;
            default:
                color = '#3498db'
                break;
        }

        return (
            <Tag style={{ maxHeight: '22px', marginBottom: '10px', fontSize: '15px', fontWeight: 600, cursor: 'pointer' }} color={color} onClick={() => togglePokemonItemsCollapsed(index)}>
                {rarity} - {chance}%
                {pokemonItemsCollapsed[index] ?
                    <CaretDownFilled style={{ marginLeft: '5px', fontSize: '17px' }} /> :
                    <CaretUpFilled style={{ marginLeft: '5px', fontSize: '17px' }} />
                }
            </Tag>
        )
    }
    const createImage = (pokemon: any) => {
        const buffer = Buffer.from(pokemon.img_front_default.data);
        const base64String = buffer.toString('base64');
        return `data:image/png;base64,${base64String}`
    }

    const handleBallClick = (itemId: number, type: string, name: string, number: number) => {
        // Assuming we need to show the modal before the actual Gatcha service call
        setSelectedBall({ itemId, type, name, number });
        setOpenModal(true);
    };

    const handleOpen = () => {
        setLoading(true);
        setTimeout(() => {
            getGatcha(userProfile, selectedBall.type, 1, selectedBall.itemId);
            setOpenModal(true);
        }, 1000)
    }
    const handleOk = () => {
        setLoading(true);
        setPartenaireLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOpenModal(false);
            setGatchaOpened(false);
            setPartenaireLoading(false);
        }, 1000)
    }

    const handleOneMore = () => {
        setGatchaOpened(false);
        setLoading(true);
        setOneMoreLoading(true);
        setTimeout(() => {
            getGatcha(userProfile, selectedBall.type, 1, selectedBall.itemId);
            setOneMoreLoading(false);
        }, 1000)
    }

    const handleCase = () => {
        if (gatchaOpened) {
            handleOk();
            setGatchaToPrincipal();
        } else {
            handleOpen();
        }
    }

    const handleCancel = () => {
        setOpenModal(false)
        setGatchaOpened(false);
        setLoading(false);
    }

    const handleTransmutation = () => {
        setLoading(true);
        setTransmuteLoading(true);
        transmutePokemon();
        setTimeout(() => {
            getInfosFromCookie(true);
            setLoading(false);
            setOpenModal(false);
            setGatchaOpened(false);
            setTransmuteLoading(false);
            if (gatchaOpened && selectedBall.number > 0) {
                setOpenModal(true);
            }
        }, 1000);
    };

    const transmutePokemon = () => {
        userPokemonService.transmutePokemon().then((response) => {
            if (!response.success) {
                return;
            }

            const content = (
                <div style={{ textAlign: 'center' }}>
                    {response.message} <b style={{ marginRight: '5px' }}>+{response.adnAmount}</b>
                    <DNAIcon />
                </div>
            );

            message.open({
                content: content,
                duration: 2,
                key: 1, // Utilisez la clé unique
                style: {
                    marginTop: messageTopMargin, // Ajustez la position verticale
                },
            });
        });
    }

    const setGatchaToPrincipal = () => {
        gatchaService.setGatchaPrincipal(userProfile).then((response) => {
            if (!response.success) {
                return;
            }
            const userPokemonWithStats = getStats(lastPokemonGacha, userProfile);
            setPokemonPrincipal(userPokemonWithStats);
        });
    }

    const getInfosFromCookie = (isTransmute?: boolean) => {
        taskService.getDiscordUserFromCookie().then(response => {
            updateUser(response);
        });
    }

    // Exemple de données pour le nombre d'objets pour chaque bouton
    const numberEgg = userProfile.inventory.find((item: any) => item.item_ID === 483)?.quantity ?? 0;
    const numberPokeball = userProfile.inventory.find((item: any) => item.item_ID === 4)?.quantity ?? 0;
    const numberSuperball = userProfile.inventory.find((item: any) => item.item_ID === 3)?.quantity ?? 0;
    const numberHyperball = userProfile.inventory.find((item: any) => item.item_ID === 2)?.quantity ?? 0;


    // Les éléments img pour chaque bouton
    const eggBalise = <img src={eggImage} alt='egg' style={{ width: '50px' }} />;
    const pokeballBalise = <img src={pokeballImage} alt='pokeball' style={{ width: '50px' }} />;
    const superballBalise = <img src={superballImage} alt='superball' style={{ width: '50px' }} />;
    const hyperballBalise = <img src={hyperballImage} alt='hyperball' style={{ width: '50px' }} />;

    // Style pour le conteneur des CircleButtons
    const circleButtonContainerStyle: React.CSSProperties = {
        position: 'relative',
        margin: '10px',
        display: 'inline-block', // Pour que le Badge se positionne correctement par rapport au CircleButton
    };

    const cardContainerStyle: React.CSSProperties = {
        position: 'relative', // Position relative pour le conteneur global
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Roboto, sans-serif',
        background: '#3c5c8a',
        borderRadius: '20px 20px 20px 20px',
        borderColor: '#black',
        borderStyle: 'solid',
        borderWidth: '2px',
        margin: '5px 10px 10px 10px',
        zIndex: 2, // Z-index supérieur pour le conteneur
    };

    const badgeStyle: React.CSSProperties = {
        backgroundColor: '#fffee5',
        color: 'black',
        fontWeight: 'bold',
        fontSize: '0.9rem',
        boxShadow: '0 0 0 2px #3c5c8a', // Ajoute un effet de halo pour le badge qui correspond à la couleur du fond
        // Ces valeurs peuvent être ajustées pour positionner le badge comme vous le souhaitez
        transform: 'translate(30%, -30%)', // Ajuste la position du badge par rapport au coin supérieur droit
    };

    const togglePokemonItemsCollapsed = (index: number) => {
        const newCollapsedState = [...pokemonItemsCollapsed];
        newCollapsedState[index] = !newCollapsedState[index];
        setPokemonItemsCollapsed(newCollapsedState);
    };

    const StarImage = () => (
        <img
            src={StarPiece}
            alt="star"
            style={{ width: '24px', height: '24px' }}
        />
    );

    const Stars = (count: number) => {
        // If count === 0, return star grayed out
        if (count === 0) {
            return <><img
                src={StarPiece}
                alt="star"
                style={{ width: '24px', height: '24px', filter: 'grayscale(100%)' }}
            /></>;
        } else {
            return <><StarImage key={count} /> <b>x{count}</b></>;
        }
    };

    return (
        <>
            <div style={cardContainerStyle}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    padding: '0 5px',
                }}>
                    <div style={circleButtonContainerStyle}>
                        <Badge style={badgeStyle} count={numberEgg} overflowCount={999}>
                            <CircleButton
                                icon={eggBalise}
                                color="#4c75b0"
                                iconColor='#fffde9'
                                borderColor='#5b8dd4'
                                onClick={() => {
                                    if (numberEgg > 0) {
                                        handleBallClick(483, 'POKEEGG', 'Oeuf', numberEgg);
                                        setImgItem(eggImage);
                                    }
                                }
                                }
                            />
                        </Badge>
                        <PillsButton label={''}
                            onClick={() => selectOdds('POKEEGG')}
                            icon={<InfoCircleOutlined />} style={{ margin: '2px', padding: '0', fontSize: '16px', minWidth: '40px', maxHeight: '20px' }}
                        />
                    </div>
                    <div style={circleButtonContainerStyle}>
                        <Badge style={badgeStyle} count={numberPokeball} overflowCount={999}>
                            <CircleButton
                                icon={pokeballBalise}
                                color="#4c75b0"
                                iconColor='#fffde9'
                                borderColor='#5b8dd4'
                                onClick={() => {
                                    if (numberPokeball > 0) {
                                        handleBallClick(4, 'POKEBALL', 'Poké Ball', numberPokeball);
                                        setImgItem(pokeballImage);
                                    }
                                }
                                }
                            />
                        </Badge>
                        <PillsButton label={''}
                            onClick={() => selectOdds('POKEBALL')}
                            icon={<InfoCircleOutlined />} style={{ margin: '2px', padding: '0', fontSize: '16px', minWidth: '40px', maxHeight: '20px' }}
                        />
                    </div>
                    <div style={circleButtonContainerStyle}>
                        <Badge style={badgeStyle} count={numberSuperball} overflowCount={999}>
                            <CircleButton
                                icon={superballBalise}
                                color="#4c75b0"
                                iconColor='#fffde9'
                                borderColor='#5b8dd4'
                                onClick={() => {
                                    if (numberSuperball > 0) {
                                        handleBallClick(3, 'SUPERBALL', 'Super Ball', numberSuperball);
                                        setImgItem(superballImage);
                                    }
                                }
                                }
                            />
                        </Badge>
                        <PillsButton label={''}
                            onClick={() => selectOdds('SUPERBALL')}
                            icon={<InfoCircleOutlined />} style={{ margin: '2px', padding: '0', fontSize: '16px', minWidth: '40px', maxHeight: '20px' }}
                        />
                    </div>
                    <div style={circleButtonContainerStyle}>
                        <Badge style={badgeStyle} count={numberHyperball} overflowCount={999}>
                            <CircleButton
                                icon={hyperballBalise}
                                color="#4c75b0"
                                iconColor='#fffde9'
                                borderColor='#5b8dd4'
                                onClick={() => {
                                    if (numberHyperball > 0) {
                                        handleBallClick(2, 'HYPERBALL', 'Hyper Ball', numberHyperball);
                                        setImgItem(hyperballImage);
                                    }
                                }
                                }
                            />
                        </Badge>
                        <PillsButton label={''}
                            onClick={() => selectOdds('HYPERBALL')}
                            icon={<InfoCircleOutlined />} style={{ margin: '2px', padding: '0', fontSize: '16px', minWidth: '40px', maxHeight: '20px' }}
                        />
                    </div>
                    <PillsButton icon={<BagIcon style={{ fontSize: '32px' }} />} onClick={() => navigate('/bag')} style={{ margin: '10px' }} label={'Sac'} />
                </div>
            </div>
            <Modal
                title={`Ouvrir ${selectedBall.name}`}
                open={openModal}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <FlatModalButton
                        key="back"
                        label={gatchaOpened ? 'Stocker' : 'Annuler'}
                        icon={gatchaOpened ? <DatabaseFilled /> : <CloseOutlined />}
                        onClick={handleCancel}
                        loading={loading && (!gatchaOpened)}
                        disabled={transmuteLoading || partenaireLoading || oneMoreLoading}
                        iconPosition="left"
                        hideLabelOnMobile={gatchaOpened ? true : false}
                        style={{ marginBottom: '10px' }}
                    />,
                    gatchaOpened && (
                        <FlatModalButton
                            key="transmute"
                            label={`+${calculTransmuteADN(lastPokemonGacha)}`}
                            icon={<DNAIcon />}
                            onClick={handleTransmutation}
                            loading={loading && transmuteLoading}
                            disabled={partenaireLoading || oneMoreLoading}
                            color={'#fd9779'}
                            iconPosition="right"
                            style={{ marginBottom: '10px' }}
                        />
                    ),
                    <FlatModalButton
                        key="submit"
                        label={gatchaOpened ? 'Partenaire' : 'Ouvrir'}
                        icon={gatchaOpened ? <HeartIcon /> : <UnlockFilled />}
                        onClick={handleCase}
                        loading={loading && (partenaireLoading || !gatchaOpened)}
                        disabled={transmuteLoading || oneMoreLoading}
                        iconPosition="left"
                        hideLabelOnMobile={gatchaOpened ? true : false}
                        style={{ marginBottom: '10px' }}
                    />,
                    gatchaOpened && selectedBall.number > 0 && (
                        <FlatModalButton
                            key="onemore"
                            label={'Encore !'}
                            icon={<RedoOutlined />}
                            onClick={handleOneMore}
                            loading={loading && oneMoreLoading}
                            disabled={transmuteLoading || partenaireLoading}
                            iconPosition="left"
                            hideLabelOnMobile={true}
                            style={{ marginBottom: '10px' }}
                        />
                    ),
                ]}
            >
                <div className="lootbox-card">
                    <p className="lootbox-content">
                        {gatchaOpened && <><img src={imgPkmn} style={{ width: '10rem' }} alt="new Pokemon" /><img
                            src={pokeballImage}
                            alt="pokemon in pokedex"
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                width: '25px',
                                transform: 'translate(50%, -50%)',
                                filter: pokemonInPokedex ? 'grayscale(0%)' : 'grayscale(100%)'
                            }}
                        /></>}
                        {!gatchaOpened && !loading && <img src={imgItem} style={{ width: '5rem' }} alt="object to use" />}
                        {!gatchaOpened && loading && <img className="ball-opening" src={imgItem} style={{ width: '5rem' }} alt="object to use" />}
                        {gatchaOpened && <>
                            <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                                <p style={{ textAlign: "center", fontWeight: 700 }}>
                                    {lastPokemonGacha.is_shiny && <ShinyIcon style={{ color: '#fde500', paddingRight: '0.5em' }} />}{lastPokemonGacha.pokemon_name} {determineGender(lastPokemonGacha.gender)}
                                </p>
                                <Tag style={{ maxHeight: '22px' }} color={lastPokemonColorRarityGacha}>
                                    {lastPokemonRarityGacha}
                                </Tag>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '5px' }}>
                                {Stars(lastPokemonGacha.rank)}
                            </div>
                        </>
                        }
                    </p>
                </div>
            </Modal>
            <Modal
                title={`Probabilités`}
                open={openOddsModal}
                onCancel={() => setOpenOddsModal(false)}
                footer={null}
            >
                <div style={{ display: 'flex', flexWrap: 'wrap', textAlign: 'center', justifyContent: 'center', maxHeight: '35vh', overflowY: 'auto' }}>
                    {probabilities.map((lootbox: any, index: any) => (
                        <div key={lootbox.box}>
                            {rarityTag(lootbox.box, lootbox.chance.toFixed(2), index)}
                            <div style={{ display: pokemonItemsCollapsed[index] ? 'none' : 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                                {lootbox.items.map((pokemon: any) => (
                                    <PokemonItem
                                        key={pokemon.item}
                                        img={createImage(pokemon)}
                                        name={pokemon.pokemon_name}
                                        gender={-1}
                                        onClick={() => {
                                            throw new Error("Function not implemented.");
                                        }}
                                        odds={pokemon.globalChance.toFixed(2)}
                                        style={{ flex: '0 0 calc(33.33% - 20px)', maxWidth: 'calc(33.33% - 20px)', margin: '5px auto auto auto' }}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </Modal>
        </>
    );
};

export default SubMenu;
